// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import DataTable from "../../examples/Tables/DataTable";
import React, {useEffect, useState} from "react";
import {borrarVenta, getVentas, getVentasById} from "./Actions/Reques";
import Modal from "./components/Modal";
import {MyToastError} from "../../helpers/MyToast";
import MDAvatar from "../../components/MDAvatar";

function Template() {

    const [data, setData] = useState(null)
    const [dias, setDias] = useState(1)
    const [idVentas, setIdVentas] = useState('')
    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const [load, setLoad] = useState(false)
    const [idxrow, setIdxrow] = useState(false)


    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };


    const cargarVentas = async () => {
        const res = await getVentas({dias});
        if (res) {
            setData(res);
        }
        setLoad(false)
    }

    useEffect(() => {

        if (data === null) {
            cargarVentas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onCargarDatos = () => {
        setLoad(true)
        cargarVentas()
    }

    const onChangeDate = (e) => {
        setDias(e.target.value)
    }

    const onChangeId = (e) => {
        setIdVentas(e.target.value)
    }

    const handleRowClick = (e) => {
        setIdxrow(e.idxrow)
        setIdVentas(e.idventas)
    };


    const cargarVentasId = async () => {
        return await getVentasById({idVentas})
    }


    const onClickDevolverPieza = () => {

        if (idVentas === ' ' || idVentas < 1 || idVentas == null) {
            MyToastError('ID venta es necesario')

        } else {

            cargarVentasId().then(result => {

                if (result[0] === undefined) {
                    MyToastError('Error: ID venta erroneo. Verifique su informacion')
                } else {

                    let ventaInfo= (JSON.stringify(result[0]).slice(1, -1).replaceAll('"','').replaceAll(',','<br/>'))

                    setOpen(true)
                    setCategory('Popup')
                    setSection('confirm')
                    setText('<b>ESTAS A PUNTO DE DEVOLVER LA VENTA CON LA SIGUIENTE INFORMACION:</b><br/>'+ventaInfo)

                }

            })
        }
    }

    const devolverVenta = async()=>{
        await borrarVenta({idVentas}).then(()=>{
            onCargarDatos()
            setIdVentas(' ')
        })
    }


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item lg={12} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center"
                                                  alignItems="center">
                                                <Grid item lg={12} xs={12}>
                                                    <Grid container spacing={2} justifyContent="center"
                                                          alignItems="center">
                                                        <Grid item lg={2} xs={12}>
                                                            <MDInput label="ID Venta..."
                                                                     type={'Number'}
                                                                     value={idVentas}
                                                                     onChange={onChangeId}
                                                                     fullWidth/>
                                                        </Grid>
                                                        <Grid item lg={3} xs={12}>
                                                            <MDButton variant="gradient" color="warning"
                                                                      startIcon={<Icon
                                                                          fontSize="small">keyboard_return</Icon>}
                                                                      onClick={onClickDevolverPieza}
                                                                      fullWidth>
                                                                Devolver Pieza
                                                            </MDButton>
                                                        </Grid>
                                                        <Grid item lg={2} xs={12}>
                                                            <MDInput label="Dias..."
                                                                     type={'Number'}
                                                                     value={dias}
                                                                     onChange={onChangeDate}
                                                                     fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item lg={3} xs={12}>
                                                            <MDButton variant="gradient" color="success"
                                                                      startIcon={<Icon fontSize="small">search</Icon>}
                                                                      fullWidth
                                                                      onClick={onCargarDatos}
                                                            >
                                                                Mostrar Datos
                                                            </MDButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item lg={12} xs={12}>
                                <Card title={"Mostrar Mercado Pago"}>
                                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                                        {!load?(<></>):(<Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>)}
                                        <Grid item lg={12} xs={12}>
                                            <DataTable
                                                table={{
                                                    columns: [
                                                        {Header: "ID", accessor: "idventas"},
                                                        {Header: "Fecha", accessor: "fecha"},
                                                        {Header: "Linea", accessor: "linea"},
                                                        {Header: "Pieza", accessor: "pieza"},
                                                        {Header: "Precio", accessor: "precio"},
                                                    ],
                                                    rows: data ? data : []
                                                }}
                                                canSearch={true}
                                                onRowClick={handleRowClick}
                                                seleccion={[idxrow]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={devolverVenta}/>

            </MDBox>
        </DashboardLayout>
    );
}

export default Template;
