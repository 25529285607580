import { MyToastRequest} from '../../../helpers/MyToast'

export const getMarcas = async () => {

    const toastInfo = {
        endpoint: 'marcas/mostrarMarcas',
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const getSubMarcas = async (data) => {


    const toastInfo = {
        endpoint: 'submarcas/mostrarSubmarcas',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const getVehiculosBySubmarca = async (data) => {
    const toastInfo = {
        endpoint: 'vehiculos/mostrarVehiculoBySubmarca',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}


export const mostrarCotizaciones = async (data) => {

    const toastInfo = {
        endpoint: 'cotizaciones/mostrarCotizaciones',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const agregarCotizacion = async (data) => {
    const toastInfo = {
        endpoint: 'cotizaciones/agregarCotizacion',
        data: data,
        method: 'Post',
        pendingMessage: 'Agregando Cotizacion',
        successMessage: 'Cotizacion Agregada!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}


