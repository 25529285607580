import React, {useEffect, useState} from "react";
import MDBox from "../../../../../../components/MDBox";
import MDCard from "../../../../../../components/MDCard";
import MDModal from "../../../../../../components/MDModal";
import Grid from "@mui/material/Grid";
import DataTable from "../../../../../../examples/Tables/DataTable";
import {getMarcas} from "../../../../Actions/Request";
import MDAvatar from "../../../../../../components/MDAvatar";


function Ver(props) {

    const [marcas, setMarcas] = useState(null)


    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }

    useEffect(() => {

        if (marcas === null) {
            cargarMarcas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                {marcas?(<></>):(<Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>)}
                                <Grid item lg={12} xs={12}>

                                    <DataTable
                                        table={{
                                            columns: [
                                                {Header: "ID", accessor: "idMarcas", width: "10%"},
                                                {Header: "Marca", accessor: "marcas", width: "90%"}
                                            ],
                                            rows: marcas ? marcas : [],
                                        }}
                                        canSearch={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
            </MDBox>
        </MDModal>


    )
}

export default Ver;