import {MyToastRequest} from '../../../helpers/MyToast'


export const getVentas = async (dias) => {

    const toastInfo = {
        endpoint: 'ventas/mostrarDevoluciones',
        data: dias,
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const getVentasById = async (idVentas) => {

    const toastInfo = {
        endpoint: 'ventas/getVentasById',
        data: idVentas,
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const borrarVenta = async (idVentas) => {

    const toastInfo = {
        endpoint: 'ventas/borrarVenta',
        data: idVentas,
        method: 'POST',
        pendingMessage: 'Devolviendo Venta',
        successMessage: 'Venta Devuelta!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

