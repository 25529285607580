import {MyToast} from "./actions/MyToast";

import React, {useEffect, useState} from "react";
// react-router-dom components
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function Basic(props) {

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [peticion, setPeticion] = useState(false);
    const [result, setResult] = useState();

    const handleChangueUser = (e) => {
        setUser(e.target.value)
    }
    const handleChanguePassword = (e) => {
        setPassword(e.target.value)
    }


    const toastpromesa = {
        endpoint: 'users/login',
        data: {user: user, password: password},
        method: 'POST',
        pendingMessage: 'Validando Credenciales, Espere Un Momento',
        successMessage: 'Bienvenido!',
    }
    const submitLogin = async () => {
        await setPeticion(true);

    }

    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitLogin()
        }
    };


    useEffect(() => {

        if (result) {
            if (result.token) {
                props.setSession(result.token);

                //window.location="./dashboard";
            } else {
                localStorage.removeItem('token');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result])

    return (
        <BasicLayout image={bgImage}>
            <Card >
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Sign in
                    </MDTypography>

                </MDBox>
                <MDBox pt={4} pb={3} px={3} onKeyPress={handleEnterKeyPress}>
                    <MDBox component="form" role="form">
                        <MDBox mb={2}>
                            <MDInput type="text" label="Usuario" fullWidth value={user} onChange={handleChangueUser}/>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password" label="Password" fullWidth value={password}
                                     onChange={handleChanguePassword} />
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="info" fullWidth onClick={submitLogin}>
                                sign in
                            </MDButton>
                        </MDBox>

                    </MDBox>
                </MDBox>
            </Card>

            <MyToast toastinfo={toastpromesa} enviar={peticion} setPeticion={setPeticion} setResult={setResult}/>
        </BasicLayout>
    );
}

export default Basic;
