import {MyToastRequest} from '../../../helpers/MyToast'

export const getMarcas = async () => {

    const toastInfo = {
        endpoint: 'marcas/mostrarMarcas',
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const getSubMarcas = async (data) => {
    const toastInfo = {
        endpoint: 'submarcas/mostrarSubmarcas',
        data: data,
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const buscarPieza = async (data) => {
    const toastInfo = {
        endpoint: 'ventas/buscarPieza',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}


