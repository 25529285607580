import React, {useState} from "react";

import MDBox from "../../../../../../components/MDBox";
import MDModal from "../../../../../../components/MDModal";
import MDCard from "../../../../../../components/MDCard";
import MDInput from "../../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import {MyToastError} from "../../../../../../helpers/MyToast";
import Modal from "../../../Modal_Confirm";


function Abono(props) {




    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };
    const onClickPop= () => {

        if ('color' === '') {
            MyToastError('Ingrese Color')
            return
        }

        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE <b>AGREGAR</b EL NUEVO COLOR:<br>
                                                        ${'color'}<br>                                                   
`)

    }









    return (<MDModal open={props.open} close={props.close}>
            <MDBox>

                    <MDCard title={props.title}>
                        <Grid>

                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={6} xs={12}>
                                        <MDInput label="Name" fullWidth/>
                                    </Grid>
                                    <Grid item xl={6} xs={12}>
                                        <MDInput type="date" label="Date" value="2018-11-23" fullWidth/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={4} xs={12}>
                                        <MDInput
                                            id="demo-simple-select"
                                            // value={subMarca}
                                            label="Marca"
                                            InputProps={{
                                                classes: {root: "select-input-styles"},
                                            }}
                                            select
                                            //onChange={handleChangeVehiculo}
                                            fullWidth
                                        >
                                            <MenuItem value={10}>No</MenuItem>
                                            <MenuItem value={11}>C</MenuItem>
                                            <MenuItem value={12}>Marcas</MenuItem>
                                            <MenuItem value={14}>XD</MenuItem>
                                        </MDInput>
                                    </Grid>

                                    <Grid item xl={4} xs={12}>
                                        <MDInput
                                            id="demo-simple-select"
                                            // value={subMarca}
                                            label="SubMarca"
                                            InputProps={{
                                                classes: {root: "select-input-styles"},
                                            }}
                                            select
                                            //onChange={handleChangeVehiculo}
                                            fullWidth
                                        >
                                            <MenuItem value={10}>No</MenuItem>
                                            <MenuItem value={11}>C</MenuItem>
                                            <MenuItem value={12}>SubMarcas</MenuItem>
                                            <MenuItem value={14}>XD</MenuItem>
                                        </MDInput>
                                    </Grid>

                                    <Grid item xl={4} xs={12}>
                                        <MDInput
                                            id="demo-simple-select"
                                            // value={subMarca}
                                            label="Auto"
                                            InputProps={{
                                                classes: {root: "select-input-styles"},
                                            }}
                                            select
                                            //onChange={handleChangeVehiculo}
                                            fullWidth
                                        >
                                            <MenuItem value={10}>No</MenuItem>
                                            <MenuItem value={11}>C</MenuItem>
                                            <MenuItem value={12}>Autos</MenuItem>
                                            <MenuItem value={14}>XD</MenuItem>
                                        </MDInput>
                                    </Grid>

                                </Grid>
                            </Grid>


                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={8} xs={12}>
                                        <MDInput label="Pieza" fullWidth/>
                                    </Grid>
                                    <Grid item xl={4} xs={12}>
                                        <MDInput type={"Number"} label="Costo" fullWidth/>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={6} xs={12}>
                                        <MDButton variant="gradient" color="success"
                                                  startIcon={<Icon fontSize="small">send</Icon>} onClick={onClickPop}>
                                            Registrar
                                        </MDButton>
                                    </Grid>
                                    <Grid item xl={6} xs={12}>
                                        <MDButton variant="gradient" color="error"
                                                  startIcon={<Icon fontSize="small">close</Icon>} onClick={props.close}>
                                            Cancelar
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MDCard>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} />
            </MDBox>
        </MDModal>


    )
}

export default Abono;