import  React from "react";

import VerNoVentas from "./Popup/NoVentas";
import VerProductosImportacion from "./Popup/ProductosImportacion";
import VerProductos from "./Popup/Productos";
import VerVentasImportacion from "./Popup/VentasImportacion"



function ModalVProducto(props) {


    if (props.category === 'Popup') {

        switch (props.section) {
            case 'NoVentas':
                return (<VerNoVentas open={props.open} close={props.close} title={"0 Ventas"}/>)
            case 'ProductosImportacion':
                return (<VerProductosImportacion open={props.open} close={props.close} title={"Productos de Importación"}/>)
            case 'Productos':
                return (<VerProductos open={props.open} close={props.close} title={"Productos"} data={props.data} setData={props.setData} refresh={props.refresh}/>)
            case 'VentasImportacion':
                return (<VerVentasImportacion open={props.open} close={props.close} title={"Ventas de Importacion"}/>)
            default:
                alert('Error en ruta para modal...')
                break;
        }
    }
}

export default ModalVProducto