import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {axiosConToken} from './axiosBackend';



export const MyToastRequest = async (toastInfo) => {

    const idToast = toast.loading(toastInfo.pendingMessage, {
        position: "top-center",
        theme: 'dark',
    })

    try {
        const resp = await axiosConToken(toastInfo.endpoint, toastInfo.data, toastInfo.method)


        if (resp.status === 200) {
            toast.update(idToast, {
                render: toastInfo.successMessage,
                type: "Success",
                isLoading: false,
                autoClose: 3000
            })
            return resp.data;

        }
        else {
            toast.update(idToast, {
                render:  resp.request.response,
                type: "Error",
                isLoading: false,
                autoClose: 3000
            })
            return resp

        }



    } catch (error) {
        toast.update(idToast, {render: error.request.response, type: "Error", isLoading: false, autoClose: 3000})
        return error
    }

}


export const MyToastError =  (message) => {

    toast.error(message, {
        position: "bottom-right",
        theme: 'dark',
    })
}

export const MyToastSuccess =  (message) => {
    toast.success(message, {
        position: "bottom-right",
        theme: 'dark',
    })
}