import React, {useEffect, useState} from "react";


import MDBox from "../../../../../../components/MDBox";
import MDCard from "../../../../../../components/MDCard";
import MDModal from "../../../../../../components/MDModal";
import Grid from "@mui/material/Grid";
import DataTable from "../../../../../../examples/Tables/DataTable";
import {actualizaGrid} from "../../../../actions/Request";
import MDAvatar from "../../../../../../components/MDAvatar";


function NoVentas(props) {


    const [data, setData] = useState(null)


    const getData = async () => {

        const filter = `
        AND (m1.CantidadVendida) is null
        and (m2.CantidadVendida) is null
        and (m3.CantidadVendida) is null
        and (stock > 0
         or MFull > 0
         or camino > 0)
        `
        const res = await actualizaGrid({filter});
        if (res) {
            setData(res);
        }

    }
    useEffect(() => {
        if (data == null) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                {data ? (<></>) : (
                                    <Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>


                                )}
                                <Grid item lg={12} xs={12}>

                                    <DataTable
                                        table={{
                                            columns: [
                                                {Header: "idProducto", accessor: "idProducto"},
                                                {Header: "Producto", accessor: "Producto"},
                                                {Header: "I. Fisico", accessor: "IFisico"},
                                                {Header: "I. Full", accessor: "IFull"},
                                                {Header: "En camino", accessor: "enCamino"},
                                                {Header: "90_60", accessor: "90_60"},
                                                {Header: "60_30", accessor: "60_30"},
                                                {Header: "30_00", accessor: "30_00"},
                                            ],
                                            rows: data ? data : [],
                                        }}
                                        canSearch={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
            </MDBox>
        </MDModal>


    )
}

export default NoVentas;