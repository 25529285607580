import React, {useEffect, useState} from "react";

import MDBox from "../../../../../../components/MDBox";
import MDModal from "../../../../../../components/MDModal";
import MDCard from "../../../../../../components/MDCard";
import MDInput from "../../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import {MyToastError} from "../../../../../../helpers/MyToast";
import {editarProducto, registrarProducto} from "../../../../actions/Request";


function Productos(props) {

    const [formData, setFormData] = useState({
        Producto: "",
        stock: 0,
        MFull: 0,
        idProducto:0,
        aux:0
    })
    const [cantidadAux, setCantidadAux] = useState(0)

    useEffect(() => {
        if(props.data){
            const ob = {...formData};
            ob['Producto'] =(props.data.Producto);
            ob['stock'] =(props.data.IFisico);
            ob['MFull'] =(props.data.IFull);
            ob['idProducto'] =(props.data.Id);
            setFormData(ob)
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const validateFields = () => {
        if (!formData.Producto || formData.Producto ==='') {
            MyToastError('Por favor escriba un producto')
            return false
        }
        if (formData.stock < 0 ) {
            MyToastError('Por favor indique stock fisico ')
            return false
        }
        if (formData.MFull < 0 )  {
            MyToastError('Por favor indique el stock full')
            return false
        }
        if (formData.stock === 0 && formData.MFull===0 )  {
            MyToastError('Agregue stock fisico o full')
            return false
        }

        return true
    };

    const onChangeProducto =(e)=>{
        const ob = {...formData};
        ob[e.target.name] =(e.target.value);
        setFormData(ob)
    }
    const onChangestock =(e)=>{
        const ob = {...formData};
        ob[e.target.name] =(e.target.value);
        setFormData(ob)
    }
    const onChangeMFull =(e)=>{
        const ob = {...formData};
        ob[e.target.name] =(e.target.value);
        setFormData(ob)
    }

    const onClickGuardar = () =>{

        if (validateFields()){

            if( props.data){
                editarProducto(formData).then(()=>{
                    setFormData({
                        Producto: "",
                        stock: 0,
                        MFull: 0,
                        idProducto:0,
                        aux:0
                    })
                    props.refresh()
                    onClickCancelar()
                })



            }else{
                registrarProducto(formData).then(()=>{
                    setFormData({
                        Producto: "",
                        stock: 0,
                        MFull: 0,
                        idProducto:0,
                        aux:0
                    })
                    props.refresh()
                    onClickCancelar()

                })


            }









        }

    }

    const onClickCancelar= () =>{
        props.close()
        props.setData(null)

    }




    useEffect(()=>{
    },[formData])

    const onChangeCantidadAux =(e)=>{
        setCantidadAux(e.target.value)
    }


    const onClickAgregar= ()=>{
       const ob = {...formData};
        ob['stock'] =(formData.stock + parseInt(cantidadAux));
        ob['aux'] =(formData.aux + parseInt(cantidadAux));
        setFormData(ob)
        setCantidadAux(0)
    }

    const onClickMover= ()=>{
        const ob = {...formData};
        ob['stock'] =(formData.stock - parseInt(cantidadAux));
        ob['MFull'] =(formData.MFull + parseInt(cantidadAux));
        setFormData(ob)
        setCantidadAux(0)
    }



    return (

        <MDModal open={props.open} close={props.close}>
            <MDBox>

                <MDCard title={props.data ?('Actualizar '+props.title):props.title  }>
                    <Grid>


                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={12} xs={12}>
                                    <MDInput label="Producto..." fullWidth name={'Producto'} value={formData.Producto} onChange={onChangeProducto}/>
                                </Grid>
                                <Grid item xl={props.data ? 3 : 6} xs={6}>
                                    <MDInput label="Stock Fisico..." fullWidth name={'stock'}  type="number" value={formData.stock} onChange={onChangestock}/>
                                </Grid>


                                {props.data ?
                                    (<>
                                        <Grid item xl={2} xs={6}>
                                            <MDButton variant="gradient" color="info"
                                                      startIcon={<Icon fontSize="small">add</Icon>} onClick={onClickAgregar} fullWidth>
                                                Agregar
                                            </MDButton>
                                        </Grid>
                                        <Grid item xl={2} xs={6}>
                                            <MDInput label="Cantidad..." type="number" value={cantidadAux} onChange={onChangeCantidadAux} fullWidth/>
                                        </Grid>
                                        <Grid item xl={2} xs={6}>
                                            <MDButton variant="gradient" color="warning"
                                                      startIcon={<Icon fontSize="small">trending_flat</Icon>}
                                                      onClick={onClickMover}
                                                      fullWidth>
                                                Mover
                                            </MDButton>
                                        </Grid>
                                    </>)
                                    :
                                    ('')}


                                <Grid item xl={props.data ? 3 : 6} xs={6}>
                                    <MDInput label="Stock Full..." name={'MFull'}  type="number" value={formData.MFull} onChange={onChangeMFull} fullWidth/>
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={6}>
                                    <MDButton variant="gradient" color="success"
                                              startIcon={<Icon fontSize="small">send</Icon>}
                                    onClick={onClickGuardar}
                                    >
                                        Guardar
                                    </MDButton>
                                </Grid>
                                <Grid item xl={6} xs={6}>
                                    <MDButton variant="gradient" color="error"
                                              startIcon={<Icon fontSize="small">close</Icon>} onClick={onClickCancelar}>
                                        Cancelar
                                    </MDButton>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                </MDCard>


            </MDBox>
        </MDModal>


    )
}

export default Productos;