import React from "react";


import MDBox from "../../../../../components/MDBox";
import MDCard from "../../../../../components/MDCard";
import MDModal from "../../../../../components/MDModal";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../components/MDButton";
import Icon from "@mui/material/Icon";



function Confirm(props) {

    const onClickDevolver=()=>{
        props.execution()
        props.close()
    }


    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item lg={12} xs={12}>
                                    <div dangerouslySetInnerHTML={{ __html: props.text }} />
                                </Grid>
                            </Grid>
                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={6} xs={12}>
                                        <MDButton variant="gradient" color="success"
                                                  startIcon={<Icon fontSize="small">done</Icon>}
                                        onClick={onClickDevolver}>
                                            Continuar
                                        </MDButton>
                                    </Grid>
                                    <Grid item xl={6} xs={12}>
                                        <MDButton variant="gradient" color="error"
                                                  startIcon={<Icon fontSize="small">undo</Icon>} onClick={props.close}>
                                            Cancelar
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
            </MDBox>
        </MDModal>


    )
}

export default Confirm;