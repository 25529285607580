import React from "react";



import Grid from "@mui/material/Grid";

import Icon from "@mui/material/Icon";
import MDModal from "../../../../../../components/MDModal";
import MDButton from "../../../../../../components/MDButton";
import MDCard from "../../../../../../components/MDCard";
import MDBox from "../../../../../../components/MDBox";



function Confirm(props) {

    const onClick=(tipo)=>{
        props.execution(tipo)
        props.close()
    }


    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item lg={12} xs={12}>
                                    <div dangerouslySetInnerHTML={{ __html: props.text }} />
                                </Grid>
                            </Grid>
                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={4} xs={12}>
                                        <MDButton variant="gradient" color="warning"
                                                  startIcon={<Icon fontSize="small">check</Icon>}
                                        onClick={()=>onClick('Fisica')}>
                                            DESACTIVAR NOTIFICACION FISICA
                                        </MDButton>
                                    </Grid>
                                    <Grid item xl={4} xs={12}>
                                        <MDButton variant="gradient" color="success"
                                                  startIcon={<Icon fontSize="small">check</Icon>}
                                                  onClick={()=>onClick('Full')}>
                                            DESACTIVAR NOTIFICACION FULL
                                        </MDButton>
                                    </Grid>
                                    <Grid item xl={4} xs={12}>
                                        <MDButton variant="gradient" color="error"
                                                  startIcon={<Icon fontSize="small">undo</Icon>} onClick={props.close}>
                                            Cancelar
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
            </MDBox>
        </MDModal>


    )
}

export default Confirm;