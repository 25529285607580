import React, {useState} from "react";

import MDBox from "../../../../../../components/MDBox";
import MDModal from "../../../../../../components/MDModal";
import MDCard from "../../../../../../components/MDCard";
import MDInput from "../../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import {buscarColor, modificarColor} from "../../../../Actions/Request";
import {MyToastError} from "../../../../../../helpers/MyToast";
import Modal from "../../../Modal_Confirm";


function ModificarColor(props) {


    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText] = useState('')
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };
    const onClickPop = () => {

        if (color === '') {
            MyToastError('Ingrese Color')
            return
        }


        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE <b>Moificar</b><br>EL COLOR CON <b>ID</b>:<br>${idColor}<br> CON EL <b>VALOR</b>:<br>${color}<br>                                                   
`)
        setOpen(true)
    }


    const [idColor, setIdColor] = useState('')
    const [color, setColor] = useState('')


    const onChangeColor = (e) => {
        setColor(e.target.value)
    }


    const onChangeIdColor = (e) => {
        setIdColor(e.target.value)
    }

    const buscarColorf = async () => {
        const res = await buscarColor({idColor: idColor})
        if (res) {
            setColor(res[0].color);
        }
    }

    const onClickBuscar = () => {
        if (idColor === null) {
            MyToastError('Ingrese IDColor')
            return
        }
        buscarColorf()
    }


    const modificarColorf = async () => {
        await modificarColor({idColor: idColor, color: color})

    }

    const onClickSubmit = () => {
        if (idColor === null) {
            MyToastError('Ingrese IDColor')
            return
        }

        if (color === '') {
            MyToastError('Ingrese Color')
            return
        }


        modificarColorf().then(() => {
            props.close()
        })
    }


    return (

        <MDModal open={props.open} close={props.close}>
            <MDBox>

                <MDCard title={props.title}>
                    <Grid>
                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={6}>
                                    <MDInput label="Id Color..."
                                             value={idColor}
                                             type={'Number'}
                                             onChange={onChangeIdColor}
                                    />

                                </Grid>
                                <Grid item xl={6} xs={6}>
                                    <MDButton variant="gradient" color="light"
                                              startIcon={<Icon fontSize="small">search</Icon>} fullWidth
                                              onClick={onClickBuscar}
                                    >
                                        Buscar
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={12} xs={12}>
                                    <MDInput label="Color..." value={color} onChange={onChangeColor}/>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="success"
                                              startIcon={<Icon fontSize="small">send</Icon>}
                                              onClick={onClickPop}
                                    >
                                        Modificar
                                    </MDButton>
                                </Grid>
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="error"
                                              startIcon={<Icon fontSize="small">close</Icon>} onClick={props.close}>
                                        Cancelar
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>

                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={onClickSubmit}/>
            </MDBox>
        </MDModal>


    )
}

export default ModificarColor;