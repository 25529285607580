import {MyToastError, MyToastRequest} from '../../../helpers/MyToast'

export const getMarcas = async () => {

    const toastInfo = {
        endpoint: 'marcas/mostrarMarcas',
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const getSubMarcas = async (data) => {


    const toastInfo = {
        endpoint: 'submarcas/mostrarSubmarcas',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const getVehiculosBySubmarca = async (data) => {
    const toastInfo = {
        endpoint: 'vehiculos/mostrarVehiculoBySubmarca',
        data: data,
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const agregarMarca = async (data) => {

    const toastInfo = {
        endpoint: 'marcas/agregarMarca',
        data: data,
        method: 'POST',
        pendingMessage: 'Agregando Marca',
        successMessage: 'Marca Agregada!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const buscarMarca = async (data) => {

    const toastInfo = {
        endpoint: 'marcas/buscarMarca',
        data: data,
        method: 'GET',

    }

    const resp = await MyToastRequest(toastInfo)
        if(!resp.result[0]){
            MyToastError('ID NO ENCONTRADO')
            return
        }

    return resp.result[0]?resp.result[0].marcas:resp.result;
}


export const modificarMarca = async (data) => {

    const toastInfo = {
        endpoint: 'marcas/modificarMarca',
        data: data,
        method: 'POST',
        pendingMessage: 'Modificando Marca',
        successMessage: 'Marca Modificada!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const agregarSubMarca = async (data) => {
    const toastInfo = {
        endpoint: 'submarcas/agregarSubmarca',
        data: data,
        method: 'POST',
        pendingMessage: 'Agregando SubMarca',
        successMessage: 'SubMarca Agregada!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}
export const modificarSubmarca = async (data) => {

    const toastInfo = {
        endpoint: 'submarcas/modificarSubmarca',
        data: data,
        method: 'POST',
        pendingMessage: 'Modificando SubMarca',
        successMessage: 'SubMarca Modificada!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const mostrarColores = async () => {

    const toastInfo = {
        endpoint: 'color/mostrarColores',
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const agregarColor = async (data) => {

    const toastInfo = {
        endpoint: 'color/agregarColor',
        data: data,
        method: 'POST',
        pendingMessage: 'Agregando Color',
        successMessage: 'Color Agregado!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}



export const buscarColor = async (data) => {

    const toastInfo = {
        endpoint: 'color/buscarColor',
        data: data,
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    if(!resp.result[0]){
        MyToastError('ID NO ENCONTRADO')
        return
    }
    return resp.result;
}

export const modificarColor = async (data) => {

    const toastInfo = {
        endpoint: 'color/modificarColor',
        data: data,
        method: 'POST',
        pendingMessage: 'Cargando Color',
        successMessage: 'Color Cargado!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const mostrarVehiculos = async () => {

    const toastInfo = {
        endpoint: 'vehiculos/mostrarVehiculos',
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const agregarVehiculo = async (data) => {

    const toastInfo = {
        endpoint: 'vehiculos/agregarVehiculo',
        data: data,
        method: 'POST',
        pendingMessage: 'Agregando Vehiculo',
        successMessage: 'Vehiculo Agregado!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const buscarVehiculo = async (data) => {

    const toastInfo = {
        endpoint: 'vehiculos/buscarVehiculo',
        data: data,
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    if(!resp.result[0]){
        MyToastError('ID NO ENCONTRADO')
        return
    }
    return resp.result;
}

export const modificarVehiculo = async (data) => {

    const toastInfo = {
        endpoint: 'vehiculos/modificarVehiculo',
        data: data,
        method: 'POST',
        pendingMessage: 'Modificando Vehiculo',
        successMessage: 'Vehiculo Modificado!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}


export const getImpuesto = async () => {

    const toastInfo = {
        endpoint: 'impuestos/getImpuesto',
        method: 'GET',

    }


    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}


export const modificarImpuesto = async (data) => {

    const toastInfo = {
        endpoint: 'impuestos/modificarImpuesto',
        data: data,
        method: 'POST',
        pendingMessage: 'Modificando Impuesto',
        successMessage: 'Impuesto Modificado!',
    }


    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}