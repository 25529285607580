import React, {useEffect, useState} from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {getalertas, modificarActivo} from "./Actions/request";
import DataTable from "../../examples/Tables/DataTable";
import Modal from "./components/Modal_Confirm";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";


function Alertas() {


    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText] = useState('')
    const [id, setId] = useState(null)
    const [check, setCheck] = useState(false)

    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };
    const onClickPop = (e) => {
        setId(e.id)
        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`¿DESEAS MARCAR COMO RESUELTO EL ERROR CON <BR> ID: <b>${e.idVenta}</b>?`)

    }

    const onClickSubmit = () => {
        modificarActivo({id}).then(() => {
            setId(null)
            cargarAlertasf()
        })
    }


    const [alertas, setAlertas] = useState(null)

    const cargarAlertasf = async () => {
        const res = await getalertas();
        if (res) {
            setAlertas(res);
        }
    }


    const onCheck = (e) => {
        setCheck(e.target.checked)

    }


    useEffect(() => {

        if (alertas === null) {
            cargarAlertasf()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xl={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item lg={2} xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox/>}
                                                label="Historial"
                                                onClick={onCheck}
                                            />
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <DataTable
                                                    table={{
                                                        columns: [
                                                            {Header: "ID", accessor: "id", width: "10%"},
                                                            {Header: "ID Venta", accessor: "idVenta", width: "20%"},
                                                            {Header: "Descripcion", accessor: "error", width: "50%"},
                                                            {Header: "Fecha", accessor: "fecha", width: "20%"},
                                                            {Header: "Resuelto", accessor: "resuelto", width: "10%"}
                                                        ],
                                                        rows: alertas ? (check ? alertas : alertas.filter(record => record.activo.toString().includes('1'))) : ([])
                                                    }}
                                                    canSearch={true}
                                                    noEndBorder={true}
                                                    onRowClick={onClickPop}

                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={onClickSubmit}/>
            </MDBox>
        </DashboardLayout>);
}

export default Alertas;
