import React, {useEffect, useState} from "react";

import MDBox from "../../../../../../components/MDBox";
import MDModal from "../../../../../../components/MDModal";
import MDCard from "../../../../../../components/MDCard";
import MDInput from "../../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import {agregarSubMarca, getMarcas} from "../../../../Actions/Request";
import {MyToastError} from "../../../../../../helpers/MyToast";
import Modal from "../../../Modal_Confirm";


function AgregarSubMarca(props) {


    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };
    const onClickPop= () => {

        if (formData.idMarca === null) {
            MyToastError('Ingrese Marca')
            return
        }
        if (formData.subMarca === '') {
            MyToastError('Ingrese SubMarca')
            return
        }

        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE <b>AGREGAR</b> UNA NUEVA SUBMARCA:<br>
                                                        ${formData.subMarca}<br>                                                   
`)

    }
////////////////////













    const [marcas, setMarcas] = useState(null)
    const [marca, setMarca] = useState(0)


    const [formData, setFormData] = useState({
        idMarca: null,
        subMarca: '',
    });

    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }

    const onChangeMarca = (e) => {
        setMarca(e.target.dataset.value)
        const ob = {...formData}
        ob['idMarca'] = e.target.dataset.value
        setFormData(ob);
    }


    const onChangeSubMarca = (e) => {
        const ob = {...formData}
        ob['subMarca'] = e.target.value
        setFormData(ob);
    }


    const agregarSubmarcaf = async () => {

    await agregarSubMarca(formData).then(()=>{
        props.close()
    })

    }

    const onClickSubmit = () => {

        agregarSubmarcaf()
    }




    useEffect(() => {

        if (marcas === null) {
            cargarMarcas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>

                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDInput
                                             id="demo-simple-select"
                                             label="Marca"
                                             InputProps={{
                                                 classes: {root: "select-input-styles"},
                                             }}
                                             select

                                             value={marca}
                                             fullWidth

                                    > <MenuItem value={'0'} onClick={onChangeMarca}>Seleccion...</MenuItem>
                                        {
                                            marcas ? (marcas.map((itm, index) =>
                                                <MenuItem value={itm.idMarcas}
                                                          onClick={onChangeMarca}
                                                          key={index+'marca'}

                                                >{itm.marcas}</MenuItem>
                                            )) :
                                            (<MenuItem></MenuItem>)
                                        }


                                    </MDInput>
                                </Grid>
                                <Grid item xl={6} xs={12}>
                                    <MDInput label="SubMarca..." fullWidth  onChange={onChangeSubMarca} value={formData.subMarca}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="success"
                                              startIcon={<Icon fontSize="small">send</Icon>}
                                              onClick={onClickPop}
                                    >
                                        Agregar
                                    </MDButton>
                                </Grid>
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="error"
                                              startIcon={<Icon fontSize="small">close</Icon>} onClick={props.close}>
                                        Cancelar
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={onClickSubmit}/>
            </MDBox>
        </MDModal>


    )
}

export default AgregarSubMarca;