import React, {useState} from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDCard from "../../components/MDCard";
import MDButton from "../../components/MDButton";
import Modals from "./components/Modal"


function Catalogo() {


    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState('')
    const [section, setSection] = useState('')
    const handleOpen = (c, s) => {
        setCategory(c)
        setSection(s)
        setOpen(true)
    };
    const handleClose = () => {
        setCategory('')
        setSection('')
        setOpen(false)
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={4} xs={12}>
                                        <MDCard title={'Marcas'}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="warning"
                                                              startIcon={<Icon fontSize="small">search</Icon>}
                                                              onClick={() => handleOpen('Marcas', 'Ver')}
                                                    >
                                                        Ver
                                                    </MDButton>
                                                </Grid>
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="info"
                                                              startIcon={<Icon fontSize="small">add</Icon>}
                                                              onClick={() => handleOpen('Marcas', 'Agregar')}
                                                    >
                                                        Agregar
                                                    </MDButton>
                                                </Grid>
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="success"
                                                              startIcon={<Icon fontSize="small">edit_note</Icon>}
                                                              onClick={() => handleOpen('Marcas', 'Modificar')}
                                                    >
                                                        Modificar
                                                    </MDButton>
                                                </Grid>
                                            </Grid>
                                        </MDCard>

                                    </Grid>
                                    <Grid item xl={4} xs={12}>
                                        <MDCard title={'SubMarcas'}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="warning"
                                                              startIcon={<Icon fontSize="small">search</Icon>}
                                                              onClick={() => handleOpen('SubMarcas', 'Ver')}
                                                    >
                                                        Ver
                                                    </MDButton>
                                                </Grid>
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="info"
                                                              startIcon={<Icon fontSize="small">add</Icon>}
                                                              onClick={() => handleOpen('SubMarcas', 'Agregar')}
                                                    >
                                                        Agregar
                                                    </MDButton>
                                                </Grid>
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="success"
                                                              startIcon={<Icon fontSize="small">edit_note</Icon>}
                                                              onClick={() => handleOpen('SubMarcas', 'Modificar')}
                                                    >
                                                        Modificar
                                                    </MDButton>
                                                </Grid>
                                            </Grid>
                                        </MDCard>
                                    </Grid>
                                    <Grid item xl={4} xs={12}>
                                        <MDCard title={'Colores'}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="warning"
                                                              startIcon={<Icon fontSize="small">search</Icon>}
                                                              onClick={() => handleOpen('Colores', 'Ver')}
                                                    >
                                                        Ver
                                                    </MDButton>
                                                </Grid>
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="info"
                                                              startIcon={<Icon fontSize="small">add</Icon>}
                                                              onClick={() => handleOpen('Colores', 'Agregar')}
                                                    >
                                                        Agregar
                                                    </MDButton>
                                                </Grid>
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="success"
                                                              startIcon={<Icon fontSize="small">edit_note</Icon>}
                                                              onClick={() => handleOpen('Colores', 'Modificar')}
                                                    >
                                                        Modificar
                                                    </MDButton>
                                                </Grid>
                                            </Grid>
                                        </MDCard>

                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>





                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xl={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={4} xs={12}>
                                        <MDCard title={'Autos'}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="warning"
                                                              startIcon={<Icon fontSize="small">search</Icon>}
                                                              onClick={() => handleOpen('Autos', 'Ver')}
                                                    >
                                                        Ver
                                                    </MDButton>
                                                </Grid>
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="info"
                                                              startIcon={<Icon fontSize="small">add</Icon>}
                                                              onClick={() => handleOpen('Autos', 'Agregar')}
                                                    >
                                                        Agregar
                                                    </MDButton>
                                                </Grid>
                                                <Grid item xl={12} xs={12}>
                                                    <MDButton variant="gradient" color="success"
                                                              startIcon={<Icon fontSize="small">edit_note</Icon>}
                                                              onClick={() => handleOpen('Autos', 'Modificar')}
                                                    >
                                                        Modificar
                                                    </MDButton>
                                                </Grid>
                                            </Grid>
                                        </MDCard>
                                    </Grid>
                                    { /*<Grid item xl={4} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xl={12} xs={12}>
                                                <MDCard title={'Abonos'}>
                                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                                        <Grid item xl={12} xs={12}>
                                                            <MDButton variant="gradient" color="info"
                                                                      startIcon={<Icon fontSize="small">add</Icon>}
                                                                      onClick={() => handleOpen('Extra', 'Abono')}
                                                            >
                                                                Agregar
                                                            </MDButton>
                                                        </Grid>
                                                    </Grid>
                                                </MDCard>
                                            </Grid>
                                        </Grid>
                                    </Grid>*/}
                                    <Grid item xl={4} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xl={12} xs={12}>
                                                <MDCard title={'Impuestos'}>
                                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                                        <Grid item xl={12} xs={12}>
                                                            <MDButton variant="gradient" color="success"
                                                                      startIcon={<Icon
                                                                          fontSize="small">edit_note</Icon>}
                                                                      onClick={() => handleOpen('Extra', 'Impuesto')}
                                                            >
                                                                Modificar
                                                            </MDButton>
                                                        </Grid>
                                                    </Grid>
                                                </MDCard>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Modals open={open} close={handleClose} category={category} section={section}/>
                </Grid>
            </MDBox>
        </DashboardLayout>)
}

export default Catalogo;