import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../MDTypography";





function MDCard(props){


    return(
        <Card style={{borderRadius: 12, minWidth: 256, maxWidth: '95vw', textAlign: 'center', maxHeight: '80vh', overflowY: 'auto'}} >

            <Grid style={{borderBottom: '1px solid #ccc'}} >
                <Grid item xl={12} xs={12}>
                    <Grid container padding={2} spacing={2} justifyContent="center" >
                        <Grid item xl={12} xs={12} >
                            <MDTypography variant="h3">
                                {props.title}
                            </MDTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>






            {props.children}
        </Card>

    )
}

export default MDCard;