import  React from "react";


import VerProductos from "./Popup/Productos";



function ModalStock(props) {


    if (props.category === 'Popup') {

        switch (props.section) {
            case 'Productos':
                return (<VerProductos open={props.open} close={props.close} title={"Productos"} data={props.data} setData={props.setData} refresh={props.refresh}/>)
            default:
                alert('Error en ruta para modal...')
                break;
        }
    }
}

export default ModalStock