// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React, {useEffect, useState} from "react";
import DataTable from "../../examples/Tables/DataTable";
import CheckIcon from "@mui/icons-material/Check";
import Modal from "../ventaproducto/components/Modal";
import Modal2 from "../ventaproducto/components/Modal_Confirm";

//Import Actions
import {actualizaGrid, editarEnCamino, editarSemana, getSemana, registrarVenta} from "./actions/Request"
import {MyToastError} from "../../helpers/MyToast";
import MDAvatar from "../../components/MDAvatar";


function VentaProducto() {


    const [open2, setOpen2] = useState(false);
    const [section2, setSection2] = useState('')
    const [category2, setCategory2] = useState('')
    const [text, setText]=useState('')
    const [type, setType]=useState('')
    const [enCamin, setEnCamin] = useState(0)
    const [page, setPage] = useState(null)
    const [pageTo, setPageTo] = useState(null)






    const execution = () => {

        if(type!=='encam'){

            setData(null)
            setDataFiltered([])
        }



        setEnCamin(0)
        setFormData({
            Cantidad: 1,
            Producto: " ",
            PrecioUnitario: 1,
            PrecioTotal: 0,
            idProducto: null,
            enCamino: 0

        })
        if(type==='encam'){

            onClickEnCamino()
        }
        if(type==='full'){
            onClickVFull()
        }
        if(type==='fisica'){
            onClickVFisica()
        }
        if(type==='is'){
            onClickModificar()
        }
    }

    const handleClose2 = () => {
        setSection2('')
        setCategory2('')
        setOpen2(false)

    };
    const onClickPop= (fis) => {
        if (fis==='is'){
            if(semana<1){
                MyToastError('Por favor ingrese un numero valido para I.M.')
                return
            }else{
                setText(`MODIFICAR EL INVENTARIO MENSUAL A:<br>
                                                     <h2>${semana}</h2>`
                )
            }
            
        }else if (fis==='encam'){
            if (!formData.idProducto || enCamin<0) {
                MyToastError('Por favor seleccione un producto o un valor correcto para En Camino')
                return
            }else{
                setText(`MODIFICAR CANTIDAD DE ARTICULOS EN CAMINO A: <br>
                                                    <h3>${formData.Producto}</h3><br>
                                                    <b>CANTIDAD:</b>${enCamin}<br>`
                )
            }
        }else{
            if (validateFields()) {
                setText(`AGREGAR UNA VENTA<br>
                ${fis==='fisica'?'<h4 style="background-color:orange; color:white;">FISICA</h4>':'<h4 style="background-color:green; color:white;">FULL</h4>'}<br>
                                                    <h3>${formData.Producto}</h3><br>
                                                    <b>CANTIDAD: </b> ${formData.Cantidad}<br>
                                                    <b>PRECIO TOTAL: </b> ${formData.PrecioTotal}<br>`)
            }else{
                MyToastError('Por favor complete todos los campos')
                return
            }
        }
        setType(fis)
        setOpen2(true)
        setCategory2('Popup')
        setSection2('confirm')
    }





















    const [checkidProducto, setCheckidProducto] = useState(true)
    const [checkProducto, setCheckProducto] = useState(true)
    const [checkIFisico, setCheckIFisico] = useState(true)
    const [checkIFull, setCheckIFull] = useState(true)
    const [checkES, setCheckES] = useState(true)
    const [checkComprar, setCheckComprar] = useState(true)
    const [checkEncamino, setCheckEncamino] = useState(true)
    const [checkPedir, setCheckPedir] = useState(true)
    const [check90_60, setCheck90_60] = useState(true)
    const [check60_30, setCheck60_30] = useState(true)
    const [check30_00, setCheck30_00] = useState(true)
    const [checkPorcentajedeVenta, setCheckPorcentajedeVenta] = useState(true)
    const [checkCantidadVendida, setCheckCantidadVendida] = useState(true)
    const [strtojson, setStrtojson] = useState([])
    const [data, setData] = useState(null)
    const [dataFiltered, setDataFiltered] = useState(null)
    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState('')
    const [section, setSection] = useState('')
    const [idxrow, setIdxrow] = useState(false)

    const [filter, setFilter] = useState({
        sku: '',
        producto: ''
    })
    const [formData, setFormData] = useState({
        Cantidad: 1,
        Producto: " ",
        PrecioUnitario: 1,
        PrecioTotal: 0,
        idProducto: null,
        enCamino: 0

    })
    const [semana, setSemana] = useState(0)

    const getData = async () => {
        const res = await actualizaGrid();
        if (res) {
            setData(res);
            setDataFiltered(res)
        }
    }

    const getInv = async () => {
        const res = await getSemana();
        if (res) {
            setSemana(parseFloat(res[0].semana).toFixed(2));
        }
    }

    useEffect(() => {
        if (data == null) {
            getData()
        }
        if (semana === 0) {
            getInv()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        let x = null
        if (data) {
            x = data

            if (filter.sku !== '') {
                x = data.filter(record => record.idProducto.toString().includes(filter.sku))
            }

            if (filter.Producto !== '') {
                x = x.filter(item => item.Producto.toLowerCase().includes(filter.producto.toLowerCase()))

            }
            setDataFiltered(x)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])


    useEffect(() => {
        let aux = []
        if (checkidProducto) {
            aux.push('{"Header": "idProducto", "accessor": "idProducto"}')
        }
        if (checkProducto) {
            aux.push('{"Header": "Producto", "accessor": "Producto"}')
        }
        if (checkIFisico) {
            aux.push('{"Header": "I. Fisico", "accessor": "IFisico"}')
        }
        if (checkIFull) {
            aux.push('{"Header": "I. Full", "accessor": "IFull"}')
        }
        if (checkES) {
            aux.push('{"Header": "E.S.", "accessor": "ES"}')
        }
        if (checkComprar) {
            aux.push('{"Header": "Comprar", "accessor": "Comprar"}')
        }
        if (checkEncamino) {
            aux.push('{"Header": "En camino", "accessor": "enCamino"}')
        }
        if (checkPedir) {
            aux.push('{"Header": "Pedir", "accessor": "Pedir"}')
        }
        if (check90_60) {
            aux.push('{"Header": "90_60", "accessor": "90_60"}')
        }
        if (check60_30) {
            aux.push('{"Header": "60_30", "accessor": "60_30"}')
        }
        if (check30_00) {
            aux.push('{"Header": "30_00", "accessor": "30_00"}')
        }
        if (checkPorcentajedeVenta) {
            aux.push('{"Header": "Porcentaje de Venta", "accessor": "PorcentajeVenta"}')
        }
        if (checkCantidadVendida) {
            aux.push('{"Header": "Cantidad Vendida", "accessor": "cantidadVendida"}')
        }
        setStrtojson(JSON.parse(`[${aux.toString()}]`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkidProducto, checkProducto, checkIFisico, checkIFull, checkES, checkComprar, checkEncamino, checkPedir, check90_60, check60_30, check30_00, checkPorcentajedeVenta, checkCantidadVendida]);


    const handleCheck = (e) => {

        switch (e.target.value) {
            case 'idProducto':
                setCheckidProducto(e.target.checked)
                break;
            case 'Producto':
                setCheckProducto(e.target.checked)
                break;
            case 'I.Fisico':
                setCheckIFisico(e.target.checked)
                break;
            case 'I.Full':
                setCheckIFull(e.target.checked)
                break;
            case 'E.S.':
                setCheckES(e.target.checked)
                break;
            case 'Comprar':
                setCheckComprar(e.target.checked)
                break;
            case 'En camino':
                setCheckEncamino(e.target.checked)
                break;
            case 'Pedir':
                setCheckPedir(e.target.checked)
                break;
            case '90_60':
                setCheck90_60(e.target.checked)
                break;
            case '60_30':
                setCheck60_30(e.target.checked)
                break;
            case '30_00':
                setCheck30_00(e.target.checked)
                break;
            case 'Porcentaje de Venta':
                setCheckPorcentajedeVenta(e.target.checked)
                break;
            case 'Cantidad Vendida':
                setCheckCantidadVendida(e.target.checked)
                break;

            default:
                break


        }

    }



    const handleOpen = (c, s) => {
        setCategory(c)
        setSection(s)
        setOpen(true)
    };
    const handleClose = () => {
        setCategory('')
        setSection('')
        setOpen(false)
    };

    const handleRowClick = (e) => {

        setPage(e.page)
        setPageTo(null)


        setIdxrow(e.idxrow+((e.page)*e.entries))
        setEnCamin(e.enCamino)
        const ob = {...formData};
        ob['Producto'] = (e.Producto);
        ob['idProducto'] = (e.idProducto);
        setFormData(ob)
    };


    const onChangeCantidad = (e) => {
        const ob = {...formData};
        ob[e.target.name] = parseInt(e.target.value);
        setFormData(ob)
    };

    const onChangePrecioUnitario = (e) => {
        const ob = {...formData};
        ob[e.target.name] = (e.target.value);
        setFormData(ob)
    };

    const onChangePrecioTotal = (e) => {
        const ob = {...formData};
        ob[e.target.name] = (e.target.value);
        setFormData(ob)
    };

    const onChangeEnCamino= (e) => {
        setEnCamin(e.target.value);
    };


    const onChangeIS = (e) => {
        setSemana(e.target.value)
    };


    const validateFields = () => {
        if (!formData.idProducto) {
            MyToastError('Por favor seleccione un producto')
            return false
        }
        if (formData.Cantidad < 1 || !isFinite(formData.Cantidad)) {
            MyToastError('Por favor indique cantidad ')
            return false
        }
        if (formData.PrecioUnitario < 1 || !isFinite(formData.PrecioUnitario)) {
            MyToastError('Por favor indique el precio unitario')
            return false
        }
        if (formData.PrecioTotal < 1 || !isFinite(formData.PrecioTotal)) {
            MyToastError('Por favor indique el precio total')
            return false
        }
        return true
    };


    const onClickModificar = () => {

        if (semana > 0) {
            editarSemana({semana}).then(() => {
                getInv()
                getData()
            })
        } else {

            MyToastError('Ingrese un valor valido')
        }
    }

    const onClickEnCamino = () => {

        if (enCamin >= 0) {

            const index = data.findIndex(obj => obj.idProducto === formData.idProducto);
            const indexFiltered = dataFiltered.findIndex(obj => obj.idProducto === formData.idProducto);

            const updatedData = data.map((item, idx) =>
                idx === index ? { ...item, enCamino: enCamin } : item
            );
            setData(updatedData)

            const updatedDataFiltered = dataFiltered.map((item, idx) =>
                idx === indexFiltered ? { ...item, enCamino: enCamin } : item
            );
            setDataFiltered(updatedDataFiltered)

            editarEnCamino({idProducto:formData.idProducto,enCamino:enCamin}).then(() => {
                //getData()


                setPageTo(page)
                setPage(null)








            })
        } else {
            MyToastError('Ingrese un valor valido')
        }
    }

    const onClickVFisica = () => {
        if (validateFields()) {
            registrarVenta(formData, 1).then(() => {
                setFormData({
                    Cantidad: 1,
                    Producto: " ",
                    PrecioUnitario: 1,
                    PrecioTotal: 0,
                    idProducto: null
                })
                getData()
            })
        }
    }
    const onClickVFull = () => {
        const ob = {...formData};
        ob['idVenta'] = 2;
        setFormData(ob)

        if (validateFields()) {
            registrarVenta(formData, 2).then(() => {
                setFormData({
                    Cantidad: 1,
                    Producto: " ",
                    PrecioUnitario: 1,
                    PrecioTotal: 0,
                    idProducto: null
                })
                getData()
            })
        }
    }


    const handleFilter = (e) => {
        const ob = {...filter};
        ob[e.target.id] = e.target.value;
        setFilter(ob)
    }


    useEffect(() => {
        const ob = {...formData};
        if (formData.Cantidad === 1) {
            ob['PrecioTotal'] = (formData.Cantidad * formData.PrecioUnitario);
        } else {
            ob['PrecioUnitario'] = (formData.PrecioTotal / formData.Cantidad)
        }
        setFormData(ob)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.Cantidad, formData.PrecioTotal, formData.PrecioUnitario]);


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item lg={9}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item lg={2} xs={12}>
                                            <MDInput label="Cantidad..." fullWidth name={'Cantidad'} type="number"
                                                     value={formData.Cantidad} onChange={onChangeCantidad}/>
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                            <MDInput label="Producto..." fullWidth name={'Producto'} disabled={true}
                                                     value={formData.Producto}/>
                                        </Grid>
                                        <Grid item lg={2} xs={6}>
                                            <MDInput label="Precio Unitario..." fullWidth name={'PrecioUnitario'}
                                                     type="number"
                                                     disabled={formData.Cantidad !== 1}
                                                     value={formData.PrecioUnitario} onChange={onChangePrecioUnitario}/>
                                        </Grid>
                                        <Grid item lg={2} xs={6}>
                                            <MDInput label="Precio Total..." fullWidth name={'PrecioTotal'}
                                                     type="number"
                                                     disabled={formData.Cantidad === 1}
                                                     value={formData.PrecioTotal} onChange={onChangePrecioTotal}/>
                                        </Grid>
                                        <Grid item lg={2} xs={6}>
                                            <MDInput label="En Camino..." fullWidth name={'enCamin'}
                                                     type="number"
                                                     value={enCamin} onChange={onChangeEnCamino}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">

                                        <Grid item lg={5} xs={12}>
                                            <MDButton variant="gradient" color="warning"
                                                      startIcon={<CheckIcon/>}
                                                      onClick={()=>{onClickPop('fisica')}}
                                                      fullWidth>
                                                Venta Fisica
                                            </MDButton>
                                        </Grid>
                                        <Grid item lg={5} xs={12}>
                                            <MDButton variant="gradient" color="success"
                                                      startIcon={<CheckIcon/>}
                                                      onClick={()=>{onClickPop('full')}}
                                                      fullWidth>
                                                Venta Full
                                            </MDButton>
                                        </Grid>
                                        <Grid item lg={2} xs={12}>
                                            <MDButton variant="gradient" color="info"
                                                      startIcon={<CheckIcon/>}
                                                      onClick={()=>{onClickPop('encam')}}
                                                      fullWidth>
                                                En Camino
                                            </MDButton>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>


                            <Grid item lg={3}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item lg={7} xs={12}>
                                            <MDButton variant="gradient" color="info"
                                                      startIcon={<Icon fontSize="small">search</Icon>}
                                                      fullWidth
                                                      onClick={() => handleOpen('Popup', 'VentasImportacion')}>
                                                Ventas/Devoluciones
                                            </MDButton>
                                        </Grid>
                                        <Grid item lg={5} xs={12}>
                                            <MDButton variant="gradient" color="info"
                                                      startIcon={<Icon fontSize="small">search</Icon>}
                                                      fullWidth
                                                      onClick={() => handleOpen('Popup', 'NoVentas')}>
                                                0 Ventas
                                            </MDButton>
                                        </Grid>
                                    </Grid>
                                    {/*<Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">*/}
                                    {/*    <Grid item lg={12} xs={12}>*/}
                                    {/*        <MDButton variant="gradient" color="warning"*/}
                                    {/*                  startIcon={<Icon fontSize="small">edit_note</Icon>}*/}
                                    {/*                  fullWidth*/}
                                    {/*                  onClick={() => handleOpen('Popup', 'ProductosImportacion')}>*/}
                                    {/*            Agregar Stock/Modificar Producto*/}
                                    {/*        </MDButton>*/}
                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}
                                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item lg={6} xs={6}>
                                            <MDInput label="IM..." fullWidth type={'Number'} value={semana}
                                                     onChange={onChangeIS}/>
                                        </Grid>
                                        <Grid item lg={6} xs={6}>
                                            <MDButton variant="gradient" color="success"
                                                      startIcon={<CheckIcon/>}
                                                      onClick={()=>{onClickPop('is')}  }
                                                      fullWidth>
                                                Modificar

                                            </MDButton>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                        <Grid item lg={12} xs={12}>
                            <Card>
                                <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center" >
                                    {data ? (<></>) : (
                                        <Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>


                                    )}

                                    {data ? (<Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item lg={6} xs={6}>
                                            <MDInput label="SKU..." fullWidth name={'Producto'}
                                                     id={'sku'}
                                                     onChange={handleFilter}
                                            />

                                        </Grid>

                                        <Grid item lg={6} xs={6}>
                                            <MDInput label="Producto..." fullWidth name={'Producto'}
                                                     id={'producto'}
                                                     onChange={handleFilter}
                                            />
                                        </Grid>

                                    </Grid>) : (<></>)}


                                    <Grid item lg={12} xs={12} style={{overflow:'scroll', maxHeight:'500PX'}}>


                                        <DataTable
                                            table={{
                                                columns: strtojson,
                                                rows: dataFiltered ? dataFiltered : []
                                            }}
                                            canSearch={false}
                                            onRowClick={handleRowClick}
                                            seleccion={[idxrow]}
                                            goToPage={pageTo}
                                          
                                            entriesPerPage={{ defaultValue: 50, entries: [5, 10, 20, 30, 40, 50] }}


                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                        <Grid item lg={12} xs={12}>
                            <Card>
                                <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkidProducto} onClick={handleCheck}/>}
                                            label="idProducto"
                                            value="idProducto"

                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkProducto} onClick={handleCheck}/>}
                                            label="Producto"
                                            value="Producto"

                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkIFisico} onClick={handleCheck}/>}
                                            label="I.Fisico"
                                            value="I.Fisico"

                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkIFull} onClick={handleCheck}/>}
                                            label="I.Full"
                                            value="I.Full"

                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel control={<Checkbox checked={checkES} onClick={handleCheck}/>}
                                                          label="E.S."
                                                          value="E.S."

                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkComprar} onClick={handleCheck}/>}
                                            label="Comprar"
                                            value="Comprar"
                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkEncamino} onClick={handleCheck}/>}
                                            label="En camino"
                                            value="En camino"

                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkPedir} onClick={handleCheck}/>}
                                            label="Pedir"
                                            value="Pedir"
                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={check90_60} onClick={handleCheck}/>}
                                            label="90_60"
                                            value="90_60"
                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={check60_30} onClick={handleCheck}/>}
                                            label="60_30"
                                            value="60_30"
                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={check30_00} onClick={handleCheck}/>}
                                            label="30_00"
                                            value="30_00"
                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkPorcentajedeVenta} onClick={handleCheck}/>}
                                            label="Porcentaje de Venta"
                                            value="Porcentaje de Venta"
                                        />
                                    </Grid>
                                    < Grid item lg="auto" xs={'auto'}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkCantidadVendida} onClick={handleCheck}/>}
                                            label="Cantidad Vendida"
                                            value="Cantidad Vendida"
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                <Modal  open={open}  close={handleClose}  category={category} section={section}/>
                <Modal2 open={open2} close={handleClose2} section={section2} category={category2} data={null}
                       text={text} execution={execution}/>
            </MDBox>

        </DashboardLayout>);
}

export default VentaProducto;
