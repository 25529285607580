import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL
const tokenError = (error) => {
    localStorage.clear();
    console.log('Request error: ' + error)
    alert('Error con la sesión o usuario no autorizado para esta acción.')
    window.location.href = '/'
}

const axiosConToken = async (endpoint, data, method = 'GET') => {

    const url = `${baseUrl}/${endpoint}`;
    const token = localStorage.getItem('token') || '';

    if (method === 'GET') {

        return await axios({
            url,
            headers: {
                'x-token': token
            },
            params:data,
        }).then(function (response) {

            return response;
        }).catch(function (err) {
            if (err.request.status === 401||err.request.status === 500){
                tokenError()
            }

            return err;
        });
    } else {
        return await axios({
            url,
            method,
            data,
            headers: {
                'x-token': token
            }
        }).then(function (response) {

            return response;
        }).catch(function (err) {
            if (err.request.status === 401||err.request.status === 500){
                tokenError()
            }
            return err;
        });
    }
};
const axiosSinToken = async (endpoint, data, method = 'GET') => {


    const url = `${baseUrl}/${endpoint}`;
    if (method === 'GET') {
        return await axios({
            url,
        }).then(function (response) {
            return response;
        }).catch(function (err) {
            return err.message;
        });
    } else {


        return await axios({
            url,
            method,
            data,
        }).then(function (response) {
            return response;
        }).catch(function (err) {
            return (err);
        });
    }
}


export {

    axiosConToken, axiosSinToken
}