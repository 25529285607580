// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import React, {useEffect, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from "@mui/material/MenuItem";
import DataTable from "../../examples/Tables/DataTable";
import Checkbox from "@mui/material/Checkbox";
import {buscarPieza, getMarcas, getSubMarcas} from "./Actions/Request";
import MDAvatar from "../../components/MDAvatar";

function Template() {

    const [subMarcas, setSubMarcas] = useState(null)
    const [marcas, setMarcas] = useState(null)
    const [subMarca, setSubMarca] = useState(0)
    const [marca, setMarca] = useState(0)
    const [piezas, setPiezas] = useState(null)
    const [piezasf, setPiezasf] = useState(null)
    const [check, setCheck] = useState(false)
    const [select, setSelect] = useState('Ventas')
    const [load, setLoad] = useState(false)


    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }
    const cargarSubMarcas = async (data) => {
        const res = await getSubMarcas(data);
        if (res) {
            setSubMarcas(res);
        }
    }
    const onChangeMarca = (e) => {
        setMarca(e.target.dataset.value)
        cargarSubMarcas({idMarca: e.target.dataset.value})
    }
    const getBuscarPieza = async (data) => {
        const res = await buscarPieza(data);
        if (res) {
            setPiezas(res);

            if (check) {
                setPiezasf(piezas)
            }
            else {
                if (select === 'Ventas') {
                    setPiezasf(res.filter(record => record.tipo === 'v'))
                }
                else {
                    setPiezasf(res.filter(record => record.tipo === 'c'))
                }
            }



        }
        setLoad(false)
    }


    const onChangeSubMarca = (e) => {
        setSubMarca(e.target.dataset.value)
        setLoad(true)
        getBuscarPieza({idSubMarca: e.target.dataset.value})
    }


    const onSelect = (e) => {
        setSelect(e.target.labels[0].id)
    }

    const onCheck = (e) => {
        setCheck(e.target.checked)
    }

    useEffect(() => {

        if (marcas === null) {
            cargarMarcas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (piezas){
            if (check) {
                setPiezasf(piezas)
            }
            else {
                if (select === 'Ventas') {
                    setPiezasf(piezas.filter(record => record.tipo === 'v'))
                }
                else {
                    setPiezasf(piezas.filter(record => record.tipo === 'c'))
                }
            }

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [check, select])


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item lg={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">

                                        <Grid item xl={3} xs={12}>
                                            <MDInput
                                                id="demo-simple-select"
                                                label="Marca"
                                                InputProps={{
                                                    classes: {root: "select-input-styles"},
                                                }}
                                                select
                                                value={marca}
                                                fullWidth
                                            >
                                                <MenuItem value={'0'} onClick={onChangeMarca}>Seleccion...</MenuItem>
                                                {
                                                    marcas ? (marcas.map((itm, index) =>
                                                            <MenuItem value={itm.idMarcas}
                                                                      id={index+'marcas'}
                                                                      key={index+'marcas'}
                                                                      onClick={onChangeMarca}>{itm.marcas}</MenuItem>))
                                                        :
                                                        (<MenuItem></MenuItem>)
                                                }

                                            </MDInput>
                                        </Grid>
                                        <Grid item xl={3} xs={12}>
                                            <MDInput
                                                id="demo-simple-select"
                                                // value={subMarca}
                                                label="SubMarca"
                                                InputProps={{
                                                    classes: {root: "select-input-styles"},
                                                }}
                                                select
                                                value={subMarca}
                                                fullWidth
                                            >
                                                <MenuItem value={'0'} onClick={onChangeSubMarca}>Seleccion...</MenuItem>
                                                {
                                                    subMarcas ? (subMarcas.map((itm, index) =>
                                                            <MenuItem value={itm.idSubMarca}
                                                                      id={index+'submarcas'}
                                                                      key={index+'marcas'}
                                                                      onClick={onChangeSubMarca}>{itm.SubMarca}</MenuItem>))
                                                        :
                                                        (<MenuItem></MenuItem>)
                                                }
                                            </MDInput>
                                        </Grid>
                                        <Grid item xl={6} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center"
                                                  alignItems="center">

                                                <Grid item xl={8} xs={8}>
                                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group" defaultValue='Ventas'
                                                                onChange={onSelect}>
                                                        <FormControlLabel value="Ventas" id="Ventas" control={<Radio/>}
                                                                          label="Ventas"/>
                                                        <FormControlLabel value="Cotizaciones" id="Cotizaciones"
                                                                          control={<Radio/>} label="Cotizaciones"/>
                                                    </RadioGroup>
                                                </Grid>
                                                <Grid item xl={4} xs={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox/>}
                                                        label="Todos"
                                                        onClick={onCheck}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                                        {!load?(<></>):(<Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>)}
                                        <Grid item lg={12} xs={12}>

                                            <DataTable
                                                table={{
                                                    columns: [
                                                        {Header: "ID", accessor: "ID", width: "10%"},
                                                        {Header: "Pieza", accessor: "Pieza", width: "30%"},
                                                        {Header: "Precio", accessor: "Precio", width: "10%"},
                                                        {Header: "Linea", accessor: "Linea", width: "20%"},
                                                        {Header: "Modelo", accessor: "Modelo", width: "10%"},
                                                        {Header: "Color", accessor: "Color", width: "10%"},
                                                        {Header: "Fecha", accessor: "Fecha", width: "10%"},
                                                        {Header: "tipo", accessor: "tipo", width: "10%"},
                                                    ],
                                                    rows: piezasf ? piezasf : [],
                                                }}
                                                canSearch={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Template;
