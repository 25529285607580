import React from "react";

import VerMarca from "./Marcas/Ver";
import AgregarMarca from "./Marcas/Agregar";
import ModificarMarca from "./Marcas/Modificar";

import VerSubMarca from "./SubMarcas/Ver";
import AgregarSubMarca from "./SubMarcas/Agregar";
import ModificarSubMarca from "./SubMarcas/Modificar";

import VerColores from "./Colores/Ver";
import AgregarColor from "./Colores/Agregar";
import ModificarColor from "./Colores/Modificar";

import VerAutos from "./Autos/Ver";
import AgregarAuto from "./Autos/Agregar";
import ModificarAuto from "./Autos/Modificar";

import Impuesto from"./Extras/Impuesto"
import Abono from "./Extras/Abono"



function Marcas(props) {

    if (props.category === 'Marcas') {

        switch (props.section) {
            case 'Ver':
                return (<VerMarca open={props.open} close={props.close} title={"Marcas Agregadas al Catalogo"}/>)


            case 'Agregar':
                return (<AgregarMarca open={props.open} close={props.close} title={"Agregar Marca"}/>)


            case 'Modificar':
                return (<ModificarMarca open={props.open} close={props.close} title={"Modificar Marca"}/>)


            default:
                alert('este mensaje no deberia salir que estan haciedo? xdxdxd')
                break;
        }
    }

    if (props.category === 'SubMarcas') {

        switch (props.section) {
            case 'Ver':
                return (<VerSubMarca open={props.open} close={props.close} title={"SubMarcas Agregadas al Catalogo"}/>)


            case 'Agregar':
                return (<AgregarSubMarca open={props.open} close={props.close} title={"Agregar SubMarca"}/>)


            case 'Modificar':
                return (<ModificarSubMarca open={props.open} close={props.close} title={"Modificar SubMarca"}/>)


            default:
                alert('este mensaje no deberia salir que estan haciedo? xdxdxd')
                break;
        }
    }

    if (props.category === 'Colores') {

        switch (props.section) {
            case 'Ver':
                return (<VerColores open={props.open} close={props.close} title={"Colores Agregados al Catalogo"}/>)


            case 'Agregar':
                return (<AgregarColor open={props.open} close={props.close} title={"Agregar Color"}/>)


            case 'Modificar':
                return (<ModificarColor open={props.open} close={props.close} title={"Modificar Color"}/>)


            default:
                alert('este mensaje no deberia salir que estan haciedo? xdxdxd')
                break;
        }
    }

    if (props.category === 'Autos') {

        switch (props.section) {
            case 'Ver':
                return (<VerAutos open={props.open} close={props.close} title={"Autos Agregados al Catalogo"}/>)


            case 'Agregar':
                return (<AgregarAuto open={props.open} close={props.close} title={"Agregar Auto"}/>)


            case 'Modificar':
                return (<ModificarAuto open={props.open} close={props.close} title={"Modificar Auto"}/>)


            default:
                alert('este mensaje no deberia salir que estan haciedo? xdxdxd')
                break;
        }
    }

    if (props.category === 'Extra') {

        switch (props.section) {
            case 'Impuesto':
                return (<Impuesto open={props.open} close={props.close} title={"Modificar Impuesto"}/>)


            case 'Abono':
                return (<Abono open={props.open} close={props.close} title={"Agregar Abono"}/>)



            default:
                alert('este mensaje no deberia salir que estan haciedo? xdxdxd')
                break;
        }
    }


}

export default Marcas