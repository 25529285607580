import React from 'react'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {axiosSinToken} from '../../../../helpers/axiosBackend';

export const MyToast = ({toastinfo, enviar, setResult, setPeticion}) => {


    const toastOpions = {
        position: "bottom-right",
        theme: 'dark',
    }

    //Realizar Solicitudes al servidor de Nodejs mediante toast
    const solicitud = async () => {
        const idToast = toast.loading(toastinfo.pendingMessage, toastOpions)

        try {
            const resp = await axiosSinToken(toastinfo.endpoint, toastinfo.data, toastinfo.method)

            if (resp.status === 200) {
                toast.update(idToast, {
                    render: toastinfo.successMessage,
                    type: "Success",
                    isLoading: false,
                    autoClose: 3000
                })
                setResult(resp.data);
            } else {
                toast.update(idToast, {
                    render: resp.response.data.message,
                    type: "Error",
                    isLoading: false,
                    autoClose: 3000
                })

            }

        } catch (error) {
            toast.update(idToast, {render: error.message, type: "Error", isLoading: false, autoClose: 3000})
        }


    }
    if (enviar) {
        solicitud();
        setPeticion(false)
    }


    return (
        <>
            <ToastContainer autoClose={5000}/>
        </>
    );
}
