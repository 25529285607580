import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {useMediaQuery} from "@mui/material";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '85vw'
};

function MDModal(props) {

    const isXsScreen = useMediaQuery('(max-width:600px)');

    if(isXsScreen) {
        return (
            <Modal open={props.open} onClose={props.close} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <div style={{width: 'max-content', maxWidth: '85vw'}}>
                            {props.children}
                        </div>
                    </Box>


            </Modal>

        )
    }

    else{
        return (
            <Modal open={props.open} onClose={props.close} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        {props.children}
                    </Box>
            </Modal>

        )
    }


}

export default MDModal