import MDBox from "../../../../components/MDBox";
import MDCard from "../../../../components/MDCard";
import MDModal from "../../../../components/MDModal";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import {
    actualizarPass
} from "../../../../examples/Navbars/DashboardNavbar/actions/Request";
import {MyToastError} from "../../../../helpers/MyToast";


function ModalPassword(props) {

    const [match, setMatch] = useState(false)
    const [confirm, setConfirm] = useState('')

    const handleChangePass = (e) => {
        let ob = {...props.formDataPass}
        ob['password'] = e.target.value
        props.setFormDataPass(ob)
        if(e.target.value===confirm && e.target.value !== ''){
            setMatch(true)
        }else{
            setMatch(false)
        }
    }
    const handleChangePassConfirm = (e) => {
        setConfirm(e.target.value)
        if(e.target.value===props.formDataPass.password && e.target.value !== ''){
            setMatch(true)
        }else{
            setMatch(false)
        }
    }


    const actualizarPassf = async () => {
        await actualizarPass(props.formDataPass);
    }


    const handleClickActualizar = () => {
        if(!match){
            MyToastError('Contraseña no coincide.')
            return
        }
        actualizarPassf().then(()=>{
            localStorage.clear()
            props.close()
            window.location.href = '/'
        })
    }


    return (
        <MDModal open={props.openPass}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item lg={12} xs={12}>
                                        <MDInput label="Nueva Contraseña..." fullWidth name={'pass1'} value={props.formDataPass.password}
                                                 id={'pass1'}
                                                 type={'password'}
                                                 autoComplete="new-password"
                                            onChange={handleChangePass}
                                        />

                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <MDInput label="Confirma Nueva Contraseña..." fullWidth name={'pass2'}
                                                 value={confirm}
                                                 id={'pass2'}
                                                 type={'password'}
                                                 autoComplete="new-password"
                                            onChange={handleChangePassConfirm}
                                        />
                                    </Grid>

                                </Grid>

                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item lg={4} xs={6}>
                                        <MDButton variant="gradient" color="success"
                                                  startIcon={<Icon fontSize="small">send</Icon>}
                                                  onClick={handleClickActualizar}
                                                  fullWidth>
                                            AGREGAR
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
            </MDBox>
        </MDModal>


    )
}

export default ModalPassword;