import {MyToastRequest} from '../../../helpers/MyToast'




export const actualizaGrid = async (filter) => {

    const toastInfo = {
        endpoint: 'ventaproducto/actualizagrid',
        data: filter,
        method: 'GET',

    }
    const resp =await MyToastRequest(toastInfo)
    return resp.result;


}

export const actualizaGridVentas = async (filter) => {


    const toastInfo = {
        endpoint: 'ventaproducto/actualizagridventas',
        data: filter,
        method: 'GET',

    }
    const resp =await MyToastRequest(toastInfo)
    return resp.result;

}

export const productos = async (filter) => {


    const toastInfo = {
        endpoint: 'ventaproducto/productos',
        data: filter,
        method: 'GET',

    }
    const resp =await MyToastRequest(toastInfo)
    return resp.result;
}

export const registrarVenta = async (data, type) => {

    data.idVenta = type
    const toastInfo = {
        endpoint: 'ventaproducto/registrarVenta',
        data: data,
        method: 'POST',
        pendingMessage: data.idVenta===1?'Guardando Venta Fisica':'Guardando Venta Full',
        successMessage: data.idVenta===1?'Venta Fisica Guardada!':'Venta Full Guardada!',
    }

    await MyToastRequest(toastInfo)
}


export const registrarProducto= async (data) => {
    const toastInfo = {
        endpoint: 'producto/agregarProducto',
        data: data,
        method: 'POST',
        pendingMessage: 'Guardando Producto',
        successMessage: 'Producto Guardado!',
    }
    await MyToastRequest(toastInfo)
}

export const editarProducto= async (data) => {
    const toastInfo = {
        endpoint: 'producto/editarProducto',
        data: data,
        method: 'POST',
        pendingMessage: 'Actualizando Producto',
        successMessage: 'Producto Actualizado!',
    }
   await MyToastRequest(toastInfo)
}

export const getSemana = async () => {


    const toastInfo = {
        endpoint: 'inventario/getInventario',
        method: 'GET',

    }
    const resp =await MyToastRequest(toastInfo)
    return resp.result;

}

export const editarSemana= async (data) => {
    const toastInfo = {
        endpoint: 'inventario/actualizarInventario',
        data: data,
        method: 'POST',
        pendingMessage: 'Actualizando I. Mensual',
        successMessage: 'I. Mensual Actualizado!',
    }
    await MyToastRequest(toastInfo)
}

export const editarEnCamino= async (data) => {
    const toastInfo = {
        endpoint: 'producto/editarEnCamino',
        data: data,
        method: 'POST',
        pendingMessage: 'Actualizando En Camino...',
        successMessage: 'Productos en Camino Actualizados!',
    }
    await MyToastRequest(toastInfo)
}

export const ventasImportacion = async (filter) => {
    const toastInfo = {
        endpoint: 'ventaproducto/ventasImportacion',
        data: filter,
        method: 'GET',

    }

    const resp =await MyToastRequest(toastInfo)
    return resp.result;
}

