import React, {useEffect, useState} from "react";


import MDBox from "../../../../../../components/MDBox";
import MDCard from "../../../../../../components/MDCard";
import MDModal from "../../../../../../components/MDModal";
import Grid from "@mui/material/Grid";
import DataTable from "../../../../../../examples/Tables/DataTable";
import {ventasImportacion} from "../../../../actions/Request";
import MDInput from "../../../../../../components/MDInput";
import Modal from "./components/Modal_Confirm";
import {eliminarVenta} from "./actions/request";


function VentasImportacion(props) {


    const [data, setData] = useState(null)
    const [idVentaML, setIdVentaML] = useState(null)
    const [fullUpdate, setFullUpdate] = useState(null)
    const [buscar, setBuscar] = useState('')
    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText] = useState('')


    const handleChangeBuscar = (e) => {
        setBuscar(e.target.value)
    };
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };


    const getData = async () => {
        const res = await ventasImportacion();
        if (res){
            setData(res);
        }

    }
    useEffect(() => {
        if (data == null) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onHandleClick = (e) => {
        setIdVentaML(e.Id)
        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ID VENTA:<B>${e.Id}</B><br/> Al presionar <B>SI</B> se borrará la venta y se restaurará el stock. <br/> Al presionar <B>NO</B> solo se borrará la venta.`)
    };

    useEffect(() => {
        if(fullUpdate===true){

            onClickSubmit(true)
            setFullUpdate(null)
        }else if(fullUpdate===false) {
            onClickSubmit(false)
            setFullUpdate(null)
        }
//eslint-disable-next-line
    }, [fullUpdate]);

    const onClickSubmit = (value) => {

        eliminarVenta({fullupdate:value,idVentaProducto:idVentaML}).then(() => {
            setIdVentaML(null)
            setFullUpdate(null)
            getData()
        })


    }



    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={2} xs={12}>
                                    <MDInput label="Buscar.."
                                             value={buscar}
                                             onChange={handleChangeBuscar}
                                             fullWidth/>
                                </Grid>
                                <Grid item lg={12} xs={12}>

                                    <DataTable
                                        table={{
                                            columns: [
                                                {Header: "ID Producto", accessor: "idProducto", width:'10%'},
                                                {Header: "Producto", accessor: "Producto", width:'10%'},
                                                {Header: "Cantidad", accessor: "Cantidad", width:'10%'},
                                                {Header: "Precio Unitario", accessor: "Precio_Unitario", width:'10%'},
                                                {Header: "Precio Total", accessor: "Precio_Total", width:'10%'},
                                                {Header: "Fecha", accessor: "Fecha", width:'10%'},
                                                {Header: "Hora", accessor: "Hora", width:'10%'},
                                                {Header: "Tipo Venta", accessor: "Tipo_Venta", width:'10%'},
                                                {Header: "ID Venta ML", accessor: "idVentaML", width:'10%'},
                                                {Header: "ID Venta", accessor: "Id", width:'10%'},
                                            ],
                                            rows: data?(buscar !== ''? data.filter(record => record.Producto.toLowerCase().includes(buscar.toLowerCase())||record.idProducto.toString().toLowerCase().includes(buscar.toLowerCase())||record.idVentaML.toString().toLowerCase().includes(buscar.toLowerCase())||record.Id.toString().toLowerCase().includes(buscar.toLowerCase())):data):([]),

                                        }}
                                        onRowClick={onHandleClick}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text}  setFullUpdate={setFullUpdate}/>
            </MDBox>
        </MDModal>


    )
}

export default VentasImportacion;