import React, {useEffect, useState} from "react";


import MDBox from "../../../../../../components/MDBox";
import MDCard from "../../../../../../components/MDCard";
import MDModal from "../../../../../../components/MDModal";
import Grid from "@mui/material/Grid";
import DataTable from "../../../../../../examples/Tables/DataTable";
import { mostrarColores} from "../../../../Actions/Request";
import MDAvatar from "../../../../../../components/MDAvatar";


function VerColores(props) {

    const [colores, setColores] = useState(null)

    const cargarColores = async () => {
        const res = await mostrarColores();
        if (res) {
            setColores(res);
        }
    }


    useEffect(() => {

        if (colores === null) {
            cargarColores()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])












    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                {colores?(<></>):(<Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>)}
                                <Grid item lg={12} xs={12}>

                                    <DataTable
                                        table={{
                                            columns: [
                                                {Header: "ID", accessor: "idColor", width: "20%"},
                                                {Header: "Color", accessor: "color", width: "80%"},

                                            ],
                                            rows: colores ? colores : [],
                                        }}
                                        canSearch={true}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
            </MDBox>
        </MDModal>


    )
}

export default VerColores;