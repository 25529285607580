import {useState} from 'react';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import axios from 'axios';

export default function useToken() {

    const getToken = () => {
        // Consultando el token desde el localstorage
        const tokenString = localStorage.getItem('token');
        // Si el token existe
        if (tokenString) {
            // Decodificar su contenido público
            const res = jwt_decode(tokenString);
            // Asignar el token al objeto decodificado
            res.token = tokenString;
            // si el token tiene una fecha de expiración menor a la actual
            if (res.exp < moment().unix()) {
                // Limpiar el token del localStorage y regresar una sesión vacía
                localStorage.clear();
                return {
                    session: {}
                };
            } else {
                // Si el token está vigente, regresar el objeto de la sesión
                const session = {
                    session: res
                }
                // Asignar el token al header de todas las peticiones en axios siguientes
                axios.defaults.headers.common['Authorization'] = res.token;
                // Regresar la sesión
                return session;
            }
        } else {
            // Si el localStorage está vacío, regresar una sesión vacía
            const session = {
                session: {}
            }
            return session;
        }
    };

    const [token, setToken] = useState(getToken());
    const saveToken = (userToken) => {
        // Si el parametro de token llega vacio
        if (!userToken) {
            // Limpiar el localStorage y asignar un objeto vacío
            localStorage.clear();
            setToken({});
        } else {
            // Sino, guardar el token en el localStorage y asignar la sesión
            localStorage.setItem('token', userToken);
            const res = jwt_decode(userToken);
            res.token = userToken;
            localStorage.setItem('first_login',res.first_login)
            localStorage.setItem('user_id', res.id)
            localStorage.setItem('rol_id', res.rol_id)
            const session = {
                session: res
            }
            axios.defaults.headers.common['Authorization'] = res.token;
            setToken(session);
            window.location.href = '/'
        }
    };

    return {
        setToken: saveToken,
        token
    }
}






















































