import React, {useState} from "react";

import MDBox from "../../../../../../components/MDBox";
import MDModal from "../../../../../../components/MDModal";
import MDCard from "../../../../../../components/MDCard";
import MDInput from "../../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import {buscarMarca, modificarMarca} from "../../../../Actions/Request";
import {MyToastError} from "../../../../../../helpers/MyToast";
import Modal from "../../../Modal_Confirm";


function ModificarMarca(props) {


    const [id, setId] = useState('')
    const [marca, setMarca] = useState('')
    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };


    const onClickPop= () => {
        if (marca === null || marca ==='') {
            MyToastError('Ingrese Marca')
            return
        }
        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE <b>ACTUALIZAR</b> UNA MARCA CON EL SIGUIENTE NOMBRE:<br>
                                                        ${marca}`)
    }





    const onChangeId = (e) => {
        setId(e.target.value)
    }

    const onClickBuscar = async () => {

        if (id === '') {
            MyToastError('Ingrese ID')
            return
        }

        const res = await buscarMarca({idmarcas:id});
        if (res) {
            setMarca(res);
        }
    }

    const onChangeMarca= (e) => {
        setMarca(e.target.value)
    }

    const onClickModificar= async () => {

        if (id === '') {
            MyToastError('Ingrese ID')
            return
        }
        if (marca === '') {
            MyToastError('Ingrese Marca')
            return
        }

        await modificarMarca({idMarcas:id, Marca:marca});
        props.close()

    }

    return (

        <MDModal open={props.open} close={props.close}>
            <MDBox>

                    <MDCard title={props.title}>
                        <Grid >
                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={6} xs={6}>
                                        <MDInput label="Id Marca..." value={id} onChange={onChangeId}/>

                                    </Grid>
                                    <Grid item xl={6} xs={6}>
                                        <MDButton variant="gradient" color="light"
                                                  startIcon={<Icon fontSize="small">search</Icon>} fullWidth
                                                  onClick={onClickBuscar}
                                        >
                                            Buscar
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={12} xs={12}>
                                        <MDInput label="Marca..." value={marca} onChange={onChangeMarca}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xl={12} xs={12}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xl={6} xs={12}>
                                        <MDButton variant="gradient" color="success"
                                                  startIcon={<Icon fontSize="small">send</Icon>}
                                                  onClick={onClickPop}
                                        >
                                            Modificar
                                        </MDButton>
                                    </Grid>
                                    <Grid item xl={6} xs={12}>
                                        <MDButton variant="gradient" color="error"
                                                  startIcon={<Icon fontSize="small">close</Icon>} onClick={props.close}>
                                            Cancelar
                                        </MDButton>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </MDCard>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={onClickModificar}/>
            </MDBox>
        </MDModal>

    )
}

export default ModificarMarca;