import React from "react";
import Confirmacion from "./popup/Confirm";


function Modal(props) {

    if (props.category === 'Popup') {
        switch (props.section) {
            case 'confirm':
                return (<Confirmacion data={props.data} text={props.text} open={props.open} close={props.close}
                                      title={"¿ESTAS SEGURO?"} execution={props.execution}/>)
            default:
                alert('este mensaje no deberia salir que estan haciedo? xdxdxd')
                break;
        }
    }
}

export default Modal