import {MyToastRequest} from '../../../helpers/MyToast'

export const productos = async (filter) => {


    const toastInfo = {
        endpoint: 'ventaproducto/productos',
        data: filter,
        method: 'GET',

    }
    const resp =await MyToastRequest(toastInfo)
    return resp.result;
}

export const registrarProducto= async (data) => {
    const toastInfo = {
        endpoint: 'producto/agregarProducto',
        data: data,
        method: 'POST',
        pendingMessage: 'Guardando Producto',
        successMessage: 'Producto Guardado!',
    }
    await MyToastRequest(toastInfo)
}

export const editarProducto= async (data) => {
    const toastInfo = {
        endpoint: 'producto/editarProducto',
        data: data,
        method: 'POST',
        pendingMessage: 'Actualizando Producto',
        successMessage: 'Producto Actualizado!',
    }
    await MyToastRequest(toastInfo)
}

export const eliminarProducto= async (data) => {
    const toastInfo = {
        endpoint: 'producto/eliminarProducto',
        data: data,
        method: 'POST',
        pendingMessage: 'Eliminando Producto',
        successMessage: 'Producto Eliminado!',
    }
    await MyToastRequest(toastInfo)
}