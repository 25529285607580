import React, {useEffect, useState} from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import MDAvatar from "../../components/MDAvatar";
import DataTable from "../../examples/Tables/DataTable";
import {productos} from "./actions/Request";
import Modals from "./components/Modal";
import MDInput from "../../components/MDInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";




function Stock() {
    const [data, setData] = useState(null)
    const [dataf, setDataf] = useState(null)
    const [ids, setIds] = useState(null)
    const [buscar, setBuscar] = useState('')
    const [datab, setDatab] = useState(null)
    const [check, setCheck] = useState(false)
    const [pageTo, setPageTo] = useState(null)
    const [page, setPage] = useState(0)


    const getData = async () => {

        setCheck(false)
        const res = await productos();
        if (res){
            setData(res);
            setDataf(res)
            setDatab(res)
            setPageTo(page)
        }

    }

    useEffect(() => {
        if(data ==null){
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const onCheck = (e) => {
        setCheck(e.target.checked)
        if(e.target.checked){
            setBuscar('')
            setData(datab.filter(item =>
                item.IFisico <= item.cantidad_alerta
            ))
            setDataf(datab.filter(item =>
                item.IFisico <= item.cantidad_alerta
            ))
        }else{
            setBuscar('')
            setData(datab)
            setDataf(datab)
        }
    }







    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState('')
    const [section, setSection] = useState('')
    const [rowData, setRowData] = useState('')
    const handleOpen = (c, s) => {
        setCategory(c)
        setSection(s)
        setOpen(true)
        setRowData(null)
    };
    const handleClose = () => {
        setCategory('')
        setSection('')
        setOpen(false)
    };
    const handleChangeBuscar = (e) => {
        setBuscar(e.target.value)
        setDataf(data.filter(record => record.Producto.toLowerCase().includes(e.target.value.toLowerCase()) || record.Id.toString().includes(e.target.value)))
    };



    const handleRowClick = (e) => {
        setPage(e.page)
        handleOpen('Popup','Productos')
        setRowData(e)
    };

    useEffect(() => {

        let x=null
        if (dataf) {
             x= dataf.map((row, index) => ({
                Producto: row.Producto,
                IFisico: row.IFisico,
                IFull: row.IFull,
                cantidad_alerta: row.cantidad_alerta,
                Id: row.Id,
                rowidx:index
            }));




            let filteredData = x.filter(item =>
                item.IFisico <= item.cantidad_alerta
            );

            let idList = filteredData.map(item => item.rowidx);
            setIds(idList)
        }


    }, [dataf]);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xl={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item xl={12} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item xl={4} xs={12}>
                                                    <MDButton variant="gradient" color="info"
                                                              startIcon={<Icon fontSize="small">add</Icon>}
                                                              fullWidth
                                                              onClick={()=>handleOpen('Popup','Productos')}>
                                                        Nuevo Artículo
                                                    </MDButton>
                                                </Grid>

                                                <Grid item xl={4} xs={12}>
                                                <MDInput label="Buscar.."
                                                         value={buscar}
                                                         onChange={handleChangeBuscar}
                                                         fullWidth/>
                                                </Grid>
                                                < Grid item lg={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={onCheck} checked={check}/>}
                                                        label="Filtrar Alertas"
                                                        //value={check}

                                                    />
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                        <Grid item xl={12} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                {data ? (<></>) : (
                                                    <Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>


                                                )}
                                                <Grid item xl={12} xs={12}>
                                                    <DataTable
                                                        table={{
                                                            columns: [
                                                                {Header: "Producto", accessor: "Producto"},
                                                                {Header: "I Fisico", accessor: "IFisico"},
                                                                {Header: "I Full", accessor: "IFull"},
                                                                {Header: "Stock Alerta", accessor: "cantidad_alerta"},
                                                                {Header: "ID", accessor: "Id"},

                                                            ],
                                                            rows: dataf?dataf:[],


                                                        }}
                                                        onRowClick={handleRowClick}
                                                        seleccion={ids}
                                                        selectionColor={'#ff9248'}
                                                        isSorted={false}
                                                        goToPage={pageTo}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modals  open={open} close={handleClose} category={category} section={section} data={rowData} setData={setRowData} refresh={getData} setCheck={setCheck}/>
            </MDBox>
        </DashboardLayout>);
}

export default Stock;
