import React, {useEffect, useState} from "react";

import MDBox from "../../../../../../components/MDBox";
import MDModal from "../../../../../../components/MDModal";
import MDCard from "../../../../../../components/MDCard";
import MDInput from "../../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import {getImpuesto, modificarImpuesto} from "../../../../Actions/Request";
import {MyToastError} from "../../../../../../helpers/MyToast";
import Modal from "../../../Modal_Confirm";


function Impuesto(props) {












    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };
    const onClickPop= () => {

        if (impuesto === null || impuesto ==='0'|| impuesto ==='') {
            MyToastError('Ingrese Impuesto')
            return
        }

        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE <b>MODIFICAR</b> EL IMPUESTO <br>A:
                                                        <b>${impuesto}<br>  </b>                                                 
`)

    }





    const [impuesto, setImpuesto] = useState(0)


    const cargarImpuestof = async () => {
        const res = await getImpuesto();
        if (res) {

            setImpuesto(res[0].valor);
        }
    }

    const onChangeImpuesto= (e) => {
        setImpuesto(e.target.value)
    }






    const modificarImpuestof = async () => {

        await modificarImpuesto({valor:impuesto}).then(() => {
            //props.close()
        })

    }
    const onSubmit =  () => {
        modificarImpuestof()

    }



    useEffect(() => {

        if (impuesto === 0) {
             cargarImpuestof()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>

                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={12} xs={12}>
                                    <MDInput label="Impuesto..."
                                             type="number"
                                             value={impuesto}
                                             onChange={onChangeImpuesto}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="success"
                                              startIcon={<Icon fontSize="small">send</Icon>}
                                              onClick={onClickPop}
                                    >
                                        Modificar
                                    </MDButton>
                                </Grid>
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="error"
                                              startIcon={<Icon fontSize="small">close</Icon>} onClick={props.close}>
                                        Cancelar
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={onSubmit}/>
            </MDBox>
        </MDModal>


    )
}

export default Impuesto;