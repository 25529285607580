import React, {useEffect, useState} from "react";

import MDBox from "../../../../../../components/MDBox";
import MDModal from "../../../../../../components/MDModal";
import MDCard from "../../../../../../components/MDCard";
import MDInput from "../../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import {agregarVehiculo, getMarcas, getSubMarcas, mostrarColores} from "../../../../Actions/Request";
import {MyToastError} from "../../../../../../helpers/MyToast";
import Modal from "../../../Modal_Confirm"


function AgregarAuto(props) {

    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };
    const onClickPop= () => {

        if (formData.idMarca === null || formData.idMarca ==='') {
            MyToastError('Ingrese Marca')
            return
        }
        if (formData.idSubMarca === null || formData.idSubMarca ==='') {
            MyToastError('Ingrese SubMarca')
            return
        }
        if (formData.fecha === null || formData.fecha === '') {
            MyToastError('Ingrese Fecha')
            return
        }
        if (formData.linea === null || formData.linea === '') {
            MyToastError('Ingrese Linea')
            return
        }
        if (formData.modelo === null || formData.modelo ==='') {
            MyToastError('Ingrese Modelo')
            return
        }
        if (formData.idColor === null || formData.idColor ==='') {
            MyToastError('Ingrese Color')
            return
        }
        if (formData.precio === null || formData.precio === '') {
            MyToastError('Ingrese Precio')
            return
        }


        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE <b>AGREGAR</b> UN AUTO CON LA SIGUIENTE INFORMACION:<br>
                                                        <b>MARCA</b>:${formData.marca}<br>
                                                        <b>SUBMARCA</b>:${formData.idSubMarca}<br>
                                                        <b>LINEA</b>:${formData.linea}<br>
                                                        <b>MODELO</b>:${formData.modelo}<br>
                                                        <b>COLOR</b>:${formData.color}<br>
                                                        <b>PRECIO</b>:${formData.precio}`)

    }









    ////////////////////////////////////////












    const [subMarcas, setSubMarcas] = useState(null)
    const [marcas, setMarcas] = useState(null)
    const [colores, setColores] = useState(null)

    const [subMarca, setSubMarca] = useState(0)
    const [marca, setMarca] = useState(0)
    const [colore, setColore] = useState(0)

    function getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    const [formData, setFormData] = useState({
        idMarca: null,
        marca:'',
        idSubMarca: null,
        submarca:'',
        fecha:getCurrentDate(),
        linea:'',
        modelo:'',
        idColor:null,
        color:'',
        precio:''
    });

    const cargarColores = async () => {
        const res = await mostrarColores();
        if (res) {
            setColores(res);
        }
    }


    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }

    const cargarSubMarcas = async (data) => {
        const res = await getSubMarcas(data);
        if (res) {
            setSubMarcas(res);
        }
    }
    const onChangeMarca = (e) => {

        setMarca(e.target.dataset.value)

        cargarSubMarcas({idMarca:e.target.dataset.value})
        const ob = {...formData}
        ob['marca']=e.target.innerText
        ob['idMarca'] = e.target.dataset.value
        setFormData(ob);
    }
    const onChangeSubMarca = (e) => {
        setSubMarca(e.target.dataset.value)
        const ob = {...formData}
        ob['idSubMarca'] = e.target.dataset.value
        ob['submarca']=e.target.innerText
        setFormData(ob);
    }


    const onChangeFecha = (e) => {
        const ob = {...formData}
        ob['fecha'] = e.target.value
        setFormData(ob);
    }

    const onChangeLinea= (e) => {
        const ob = {...formData}
        ob['linea'] = e.target.value
        setFormData(ob);
    }
    const onChangeModelo = (e) => {
        const ob = {...formData}
        ob['modelo'] = e.target.value
        setFormData(ob);
    }
    const onChangeColor = (e) => {
        setColore(e.target.dataset.value)
        const ob = {...formData}
        ob['idColor'] = e.target.dataset.value
        ob['color']=e.target.innerText
        setFormData(ob);
    }
    const onChangePrecio = (e) => {
        const ob = {...formData}
        ob['precio'] = e.target.value
        setFormData(ob);
    }


    const agregarVehiculof = async (data) => {
         await agregarVehiculo(data);

    }



    const onClickSubmit = () => {

        agregarVehiculof(formData).then(()=>{
            props.close()
        })

    }





    useEffect(() => {

        if (marcas === null) {
            cargarMarcas()
        }
        if (colores === null) {
            cargarColores()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>

                <MDCard title={props.title}>
                    <Grid>

                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={4} xs={12}>
                                    <MDInput
                                             id="demo-simple-select"
                                             label="Marca"
                                             InputProps={{
                                                 classes: {root: "select-input-styles"},
                                             }}
                                             select
                                             value={marca}
                                             fullWidth
                                    >
                                        <MenuItem value={'0'} onClick={onChangeMarca}>Seleccion...</MenuItem>
                                        {
                                            marcas ? (marcas.map((itm, index) =>
                                                    <MenuItem value={itm.idMarcas}
                                                              id={index+'marcas'}
                                                              key={index+'marcas'}
                                                              onClick={onChangeMarca}>{itm.marcas}</MenuItem>))
                                                :
                                                (<MenuItem></MenuItem>)
                                        }
                                    </MDInput>
                                </Grid>

                                <Grid item xl={4} xs={12}>
                                    <MDInput
                                             id="demo-simple-select"
                                             label="SubMarca"
                                             InputProps={{
                                                 classes: {root: "select-input-styles"},
                                             }}
                                             select
                                             value={subMarca}
                                             fullWidth
                                    >
                                        <MenuItem value={'0'} onClick={onChangeSubMarca}>Seleccion...</MenuItem>
                                        {
                                            subMarcas ? (subMarcas.map((itm, index) =>
                                                    <MenuItem value={itm.idSubMarca}
                                                              id={index+'submarcas'}
                                                              key={index+'submarcas'}
                                                              onClick={onChangeSubMarca}>{itm.SubMarca}</MenuItem>))
                                                :
                                                (<MenuItem></MenuItem>)
                                        }
                                    </MDInput>
                                </Grid>

                                <Grid item xl={4} xs={12}>
                                    <MDInput type="date" label="Date" fullWidth
                                    value={formData.fecha}
                                             onChange={onChangeFecha}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDInput label="Linea..." fullWidth value={formData.linea} onChange={onChangeLinea}/>
                                </Grid>
                                <Grid item xl={6} xs={12}>
                                    <MDInput type="number" label="Modelo..." fullWidth
                                             value={formData.modelo} onChange={onChangeModelo}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDInput
                                             id="demo-simple-select"
                                        // value={subMarca}
                                             label="Color"
                                             InputProps={{
                                                 classes: {root: "select-input-styles"},
                                             }}
                                             select
                                             value={colore}
                                             fullWidth
                                    >
                                        <MenuItem value={'0'} onClick={onChangeColor}>Seleccion...</MenuItem>
                                        {
                                            colores ? (colores.map((itm, index) =>
                                                    <MenuItem value={itm.idColor}
                                                              id={index+'color'}
                                                              key={index+'color'}
                                                              onClick={onChangeColor}>{itm.color}</MenuItem>))
                                                :
                                                (<MenuItem></MenuItem>)
                                        }
                                    </MDInput>
                                </Grid>
                                <Grid item xl={6} xs={12}>
                                    <MDInput type="number" label="Precio..." fullWidth
                                             value={formData.precio} onChange={onChangePrecio}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="success"
                                              startIcon={<Icon fontSize="small">send</Icon>}
                                    onClick={onClickPop}>
                                        Agregar
                                    </MDButton>
                                </Grid>
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="error"
                                              startIcon={<Icon fontSize="small">close</Icon>} onClick={props.close}>
                                        Cancelar
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={onClickSubmit}/>
            </MDBox>
        </MDModal>


    )
}

export default AgregarAuto;