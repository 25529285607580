import React, {useEffect, useState} from "react";

import MDBox from "../../../../../../components/MDBox";
import MDModal from "../../../../../../components/MDModal";
import MDCard from "../../../../../../components/MDCard";
import MDInput from "../../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import {getMarcas, getSubMarcas, modificarSubmarca} from "../../../../Actions/Request";
import {MyToastError} from "../../../../../../helpers/MyToast";
import Modal from "../../../Modal_Confirm";


function ModificarSubMarca(props) {


    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const [old, setold]=useState('')
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };
    const onClickPop= () => {

        if (idSubMarca === '') {
            MyToastError('Ingrese SubMarca')
            return
        }
        if (subMarca === '' || subMarca===subMarcas.filter(record => record.idSubMarca === parseInt(idSubMarca))[0].SubMarca ) {
            MyToastError('Ingrese SubMarca EDITADA')
            return
        }

        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE <b>EDITAR</b><br> LA SUBMARCA<br> DE: <b>${old}</b><br> A:   
                                                        <b>${subMarca}</b><br>                                                   
`)

    }


















    const [subMarcas, setSubMarcas] = useState(null)
    const [marcas, setMarcas] = useState(null)

    const [subMarcaId, setSubMarcaId] = useState(0)
    const [marcaId, setMarcaId] = useState(0)

    const [subMarca, setSubMarca] = useState('')
    const [idSubMarca, setIdSubMarca] = useState('')

    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }
    const cargarSubMarcas = async (data) => {
        const res = await getSubMarcas(data);
        if (res) {

            setSubMarcas(res);
        }
    }

    const onChangeMarca = (e) => {
        setMarcaId(e.target.dataset.value)
        cargarSubMarcas({idMarca:e.target.dataset.value})
        setSubMarca('')
    }


    const onChangeSubMarca = (e) => {
        setSubMarcaId(e.target.dataset.value)
        setSubMarca(e.target.innerText)
        setIdSubMarca(e.target.dataset.value)
        setold(e.target.innerText)


    }

    const onChangeNuevaSubMarca = (e) => {
        setSubMarca(e.target.value)

    }

    const modificarSubmarcaf = async () => {

        await modificarSubmarca({idSubMarca:idSubMarca, subMarca:subMarca}).then(()=>{
            props.close()
        })

    }


    const onClickSubmit = () => {




        modificarSubmarcaf()
    }

    useEffect(() => {

        if (marcas === null) {
            cargarMarcas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    return (

        <MDModal open={props.open} close={props.close}>
            <MDBox>

                <MDCard title={props.title}>


                    <Grid>
                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDInput
                                        id="demo-simple-select"
                                        label="Marca"
                                        InputProps={{
                                            classes: {root: "select-input-styles"},
                                        }}
                                        select
                                        value={marcaId}
                                        fullWidth
                                    >
                                        <MenuItem value={'0'} onClick={onChangeMarca}>Seleccion...</MenuItem>
                                        {marcas ? (marcas.map((itm, index) =>
                                                <MenuItem value={itm.idMarcas}
                                                          onClick={onChangeMarca}
                                                          key={index+'marca'}
                                                >{itm.marcas}</MenuItem>
                                            )) :
                                            (<MenuItem></MenuItem>)}

                                    </MDInput>
                                </Grid>

                                <Grid item xl={6} xs={12}>
                                    <MDInput
                                        id="demo-simple-select"
                                        // value={subMarca}
                                        label="SubMarca"
                                        InputProps={{
                                            classes: {root: "select-input-styles"},
                                        }}
                                        select
                                        value={subMarcaId}
                                        fullWidth
                                    >
                                        <MenuItem value={'0'} onClick={onChangeSubMarca}>Seleccion...</MenuItem>
                                        {subMarcas ? (subMarcas.map((itm, index) =>
                                            <MenuItem value={itm.idSubMarca}
                                                      key={index+'submarca'}
                                                      onClick={onChangeSubMarca}
                                            >{itm.SubMarca}</MenuItem>)) : (<MenuItem></MenuItem>)}
                                    </MDInput>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={12} xs={12}>
                                    <MDInput label="Modificar SubMarca..." fullWidth value={subMarca} onChange={onChangeNuevaSubMarca}/>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={6}>
                                    <MDButton variant="gradient" color="success"
                                              startIcon={<Icon fontSize="small">send</Icon>}
                                              onClick={onClickPop}
                                    >
                                        Modificar
                                    </MDButton>
                                </Grid>
                                <Grid item xl={6} xs={6}>
                                    <MDButton variant="gradient" color="error"
                                              startIcon={<Icon fontSize="small">close</Icon>} onClick={props.close}>
                                        Cancelar
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>

                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={onClickSubmit}/>
            </MDBox>
        </MDModal>


    )
}

export default ModificarSubMarca;