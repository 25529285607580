import React, {useEffect, useState} from "react";


import MDBox from "../../../../../../components/MDBox";
import MDCard from "../../../../../../components/MDCard";
import MDModal from "../../../../../../components/MDModal";
import Grid from "@mui/material/Grid";
import DataTable from "../../../../../../examples/Tables/DataTable";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import Modals from "../../index";
import {productos} from "../../../../actions/Request";
import MDAvatar from "../../../../../../components/MDAvatar";


function ProductosImportacion(props) {

    const [data, setData] = useState(null)


    const getData = async () => {
        const res = await productos();
        if (res){
            setData(res);
        }

    }

    useEffect(() => {
        if(data ==null){
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);







    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState('')
    const [section, setSection] = useState('')
    const [rowData, setRowData] = useState('')
    const handleOpen = (c, s) => {
        setCategory(c)
        setSection(s)
        setOpen(true)
        setRowData(null)
    };
    const handleClose = () => {
        setCategory('')
        setSection('')
        setOpen(false)
    };



    const handleRowClick = (e) => {
        handleOpen('Popup','Productos')
        setRowData(e)
    };


    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>

                <MDCard title={props.title}>
                    <Grid>
                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={5} xs={12}>
                                    <MDButton variant="gradient" color="info"
                                              startIcon={<Icon fontSize="small">add</Icon>}
                                              fullWidth
                                              onClick={()=>handleOpen('Popup','Productos')}>
                                        Nuevo
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                {data ? (<></>) : (
                                    <Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>


                                )}
                                <Grid item xl={12} xs={12}>
                                    <DataTable
                                        table={{
                                            columns: [
                                                {Header: "Producto", accessor: "Producto"},
                                                {Header: "I Fisico", accessor: "IFisico"},
                                                {Header: "I Full", accessor: "IFull"},
                                                {Header: "ID", accessor: "Id"},
                                            ],
                                            rows: data?data:[],

                                        }}
                                        canSearch={true}
                                        onRowClick={handleRowClick}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                </MDCard>

                <Modals  open={open} close={handleClose} category={category} section={section} data={rowData} setData={setRowData} refresh={getData}/>
            </MDBox>
        </MDModal>


    )
}

export default ProductosImportacion;