import {MyToastRequest} from "../../../../helpers/MyToast";

export const mostrarUsers = async () => {

    const toastInfo = {
        endpoint: 'users/mostrarUsers',
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const agregarUsuario = async (data) => {

    const toastInfo = {
        endpoint: 'users/agregarUser',
        data: data,
        method: 'POST',
        pendingMessage: 'Agregando Usuario',
        successMessage: 'Usuario Agregado!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const borrarUsuario = async (data) => {

    const toastInfo = {
        endpoint: 'users/borrarUser',
        data: data,
        method: 'POST',
        pendingMessage: 'Eliminando Usuario',
        successMessage: 'Usuario Elimando!',
    }

    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const actualizarPass = async (data) => {

    const toastInfo = {
        endpoint: 'users/actualizarPass',
        data: data,
        method: 'POST',
        pendingMessage: 'Actualizando Contraseña',
        successMessage: 'Contraseña Actualizada!',
    }
    const resp = await MyToastRequest(toastInfo)

    return resp.result;
}

export const actualizarAdmin = async (data) => {

    const toastInfo = {
        endpoint: 'users/actualizarAdmin',
        data: data,
        method: 'POST',
        pendingMessage: 'Actualizando Administrador',
        successMessage: 'Administrador Actualizado!',
    }
    const resp = await MyToastRequest(toastInfo)

    return resp.result;
}

export const mostrarStockNotif = async () => {

    const toastInfo = {
        endpoint: 'producto/getStockNotifications',
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const desactivarStockNotif = async (data) => {

    const toastInfo = {
        endpoint: 'producto/desactivarNotificacion',
        data: data,
        method: 'POST',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}