import {MyToastRequest} from '../../../helpers/MyToast'
export const getalertas = async () => {

    const toastInfo = {
        endpoint: 'errores/getErrores',
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const modificarActivo = async (data) => {

    const toastInfo = {
        endpoint: 'errores/modificarActivo',
        data: data,
        method: 'POST',
        pendingMessage: 'ACTUALIZANDO ALERTA!',
        successMessage: 'ALERTA RESUELTA!',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}