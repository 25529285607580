import React, {useEffect, useState} from "react";
import MDBox from "../../../../../../components/MDBox";
import MDCard from "../../../../../../components/MDCard";
import MDModal from "../../../../../../components/MDModal";
import Grid from "@mui/material/Grid";
import DataTable from "../../../../../../examples/Tables/DataTable";
import MDInput from "../../../../../../components/MDInput";
import MenuItem from "@mui/material/MenuItem";
import {getMarcas, getSubMarcas, getVehiculosBySubmarca} from "../../../../Actions/Request";
import MDAvatar from "../../../../../../components/MDAvatar";

function VerSubMarca(props) {

    const [subMarcas, setSubMarcas] = useState(null)
    const [subMarca, setSubMarca] = useState(0)
    const [marcas, setMarcas] = useState(null)
    const [marca, setMarca] = useState(0)
    const [vehiculos, setVehiculos] = useState(null)
    const [load, setLoad] = useState(false)



    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }
    const cargarSubMarcas = async (data) => {
        const res = await getSubMarcas(data);
        if (res) {
            setSubMarcas(res);
        }
    }
    const onChangeMarca = (e) => {
        setMarca(e.target.dataset.value)
        cargarSubMarcas({idMarca:e.target.dataset.value})
    }
    const cargarVehiculos = async (data) => {
        const res = await getVehiculosBySubmarca(data);
        if (res) {
            setVehiculos(res);
        }
        setLoad(false)
    }




    const onChangeSubMarca = (e) => {
        setSubMarca(e.target.dataset.value)
        setLoad(true)
        cargarVehiculos({idSubMarca:e.target.dataset.value})
    }


    useEffect(() => {

        if (marcas === null) {
            cargarMarcas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (<MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>

                        <Grid container padding={2} spacing={2} justifyContent="center">
                            <Grid item lg={6} xs={6}>
                                <MDInput
                                    id="demo-simple-select"
                                    label="Marca"
                                    InputProps={{
                                        classes: {root: "select-input-styles"},
                                    }}
                                    select
                                    value={marca}
                                    fullWidth
                                >
                                    <MenuItem value={'0'} onClick={onChangeMarca}>Seleccion...</MenuItem>
                                    {
                                        marcas ? (marcas.map((itm, index) =>
                                        <MenuItem value={itm.idMarcas}  id={index+'marcas'} onClick={onChangeMarca}>{itm.marcas}</MenuItem>))
                                            :
                                        (<MenuItem></MenuItem>)
                                    }

                                </MDInput>

                            </Grid>

                            <Grid item lg={6} xs={6}>
                                <MDInput
                                    id="demo-simple-select"
                                    // value={subMarca}
                                    label="SubMarca"
                                    InputProps={{
                                        classes: {root: "select-input-styles"},
                                    }}
                                    select
                                    value={subMarca}
                                    fullWidth
                                >
                                    <MenuItem value={'0'} onClick={onChangeSubMarca}>Seleccion...</MenuItem>
                                    {
                                        subMarcas ? (subMarcas.map((itm, index) =>
                                        <MenuItem value={itm.idSubMarca} id={index+'submarcas'} onClick={onChangeSubMarca}>{itm.SubMarca}</MenuItem>))
                                        :
                                        (<MenuItem></MenuItem>)
                                    }
                                </MDInput>
                            </Grid>

                        </Grid>


                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                {!load?(<></>):(<Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>)}
                                <Grid item lg={12} xs={12}>
                                    <DataTable
                                        table={{
                                            columns: [
                                                {Header: "Linea", accessor: "Linea", width: "40%"},
                                                {Header: "Modelo", accessor: "Modelo", width: "40%"},
                                                {Header: "Color", accessor: "color", width: "20%"},

                                            ], rows: vehiculos ? vehiculos : [],
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
            </MDBox>
        </MDModal>


    )
}

export default VerSubMarca;