// Material Dashboard 2 React layouts
import Ventas from "layouts/ventas";
//import Vehiculos from "./layouts/abonos";
import VentaProducto from "./layouts/ventaproducto";
import Catalogo from "./layouts/catalogo";
import Comparativa from "./layouts/comparativas"
import Cotizaciones from "./layouts/cotizaciones"
import Mercadopago from "./layouts/busqueda";
import Mostrarventas from "./layouts/mostrarventas";
import Devoluciones from "./layouts/devoluciones";
//import Subir from "./layouts/uploadfile"
import Gastos from "./layouts/gastos"

// @mui icons
import Icon from "@mui/material/Icon";
import Alertas from "./layouts/alertas";
import Stock from "./layouts/stock";


const routes = localStorage.getItem('rol_id') === '1' ?
    [
        {
            type: "collapse",
            name: "Busqueda",
            key: "busqueda",
            icon: <Icon fontSize="small">local_grocery_store</Icon>,
            route: "/busqueda",
            component: <Mercadopago/>,
        },

        {
            type: "collapse",
            name: "Venta de Productos",
            key: "venta-de-productos",
            icon: <Icon fontSize="small">local_shipping</Icon>,
            route: "/venta-de-productos",
            component: <VentaProducto/>,
        },
        {
            type: "collapse",
            name: "Ventas de Yonke",
            key: "ventas",
            icon: <Icon fontSize="small">payments</Icon>,
            route: "/ventas",
            component: <Ventas/>,
        },
        {
            type: "collapse",
            name: "Mostrar Ventas",
            key: "mostrar-ventas",
            icon: <Icon fontSize="small">query_stats</Icon>,
            route: "/mostrar-ventas",
            component: <Mostrarventas/>,
        },
        {
            type: "collapse",
            name: "Devoluciones De Yonke",
            key: "devoluciones-de-yonke",
            icon: <Icon fontSize="small">keyboard_return</Icon>,
            route: "/devoluciones-de-yonke",
            component: <Devoluciones/>,
        },
        {
            type: "collapse",
            name: "Gastos",
            key: "gastos",
            icon: <Icon fontSize="small">attach_money</Icon>,
            route: "/gastos",
            component: <Gastos/>,
        },
        // {
        //   type: "collapse",
        //   name: "Abonos",
        //   key: "abonos",
        //   icon: <Icon fontSize="small">house</Icon>,
        //   route: "/abonos",
        //   component: <Vehiculos />,
        // },
        {
            type: "collapse",
            name: "Alertas",
            key: "alertas",
            icon: <Icon fontSize="small">warning</Icon>,
            route: "/alertas",
            component: <Alertas/>,
        },
        {
            type: "collapse",
            name: "Stock",
            key: "stock",
            icon: <Icon fontSize="small">inventory</Icon>,
            route: "/stock",
            component: <Stock/>,
        },
        {
            type: "collapse",
            name: "Cotizaciones",
            key: "cotizaciones",
            icon: <Icon fontSize="small">local_offer</Icon>,
            route: "/cotizaciones",
            component: <Cotizaciones/>,
        },
        {
            type: "collapse",
            name: "Comparativas",
            key: "comparativas",
            icon: <Icon fontSize="small">compare_arrows</Icon>,
            route: "/comparativas",
            component: <Comparativa/>,
        },
        {
            type: "collapse",
            name: "Catalogo",
            key: "catalogo",
            icon: <Icon fontSize="small">menu_book</Icon>,
            route: "/catalogo",
            component: <Catalogo/>,
        },
        // {
        //   type: "collapse",
        //   name: "Subir CSV",
        //   key: "subir-csv",
        //   icon: <Icon fontSize="small">cloud_upload</Icon>,
        //   route: "/subir-csv",
        //   component: <Subir/>,
        // },

    ] : [
        {
            type: "collapse",
            name: "Busqueda",
            key: "busqueda",
            icon: <Icon fontSize="small">local_grocery_store</Icon>,
            route: "/busqueda",
            component: <Mercadopago/>,
        },
        {
            type: "collapse",
            name: "Venta de Productos",
            key: "venta-de-productos",
            icon: <Icon fontSize="small">local_shipping</Icon>,
            route: "/venta-de-productos",
            component: <VentaProducto/>,
        },
        {
            type: "collapse",
            name: "Devoluciones De Yonke",
            key: "devoluciones-de-yonke",
            icon: <Icon fontSize="small">keyboard_return</Icon>,
            route: "/devoluciones-de-yonke",
            component: <Devoluciones/>,
        },
        {
            type: "collapse",
            name: "Alertas",
            key: "alertas",
            icon: <Icon fontSize="small">warning</Icon>,
            route: "/alertas",
            component: <Alertas/>,
        }
    ];

export default routes;
