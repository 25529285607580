import React, {useState} from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import DataTable from "../../examples/Tables/DataTable";
import {agregarGasto, eliminarGasto, mostrarGastos} from "./Actions/Request";
import {MyToastError} from "../../helpers/MyToast";
import Modal from "./components/Modal";
import MDAvatar from "../../components/MDAvatar";




function Gastos() {


    function getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const [gastos, setGastos]=useState('')
    const [gasto, setGasto]=useState('')
    const [load, setLoad] = useState(false)
    const [id, setId] = useState(null)
    const [tipo,setTipo] = useState(null)


    const [formData, setFormData] = useState({
        Fecha: getCurrentDate(),
        Tipo: '0',
        Total: '',
        Descripcion: ''
    });


    const [formData2, setFormData2] = useState({
        Inicio: getCurrentDate(),
        Fin: getCurrentDate()
    });


    const agregar = async()=>{
        agregarGastof()
    }

    const eliminar = async()=>{
        eliminarGastof()
    }

    const execute = ()=>{
        if(tipo==='agregar'){
            agregar()
        }else if(tipo==='eliminar'){
            eliminar()
        }else{
            MyToastError('Error en consulta. Por favor refresca...')
        }
    }

    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };

    const onClickTipo = (e) => {
        const ob = {...formData}
        if (e.target.dataset.value !== '0') {
            ob['Tipo'] = e.target.dataset.value
        }
        if (e.target.dataset.value === 'EMPLEADO') {
            ob['Descripcion'] = (e.target.dataset.value + ' ')
        }
        setFormData(ob);
    }

    const onChange = (e) => {

        const ob = {...formData}
        ob[e.target.id] = e.target.value
        setFormData(ob);

    }


    const agregarGastof = async () => {

        await agregarGasto(formData).then(() => {
            const ob = {...formData}
            ob['Tipo'] = '0'
            ob['Total'] = ''
            ob['Descripcion'] = ''
            setFormData(ob);
            mostrarGastosf()
        })

    }

    const eliminarGastof = async () => {

        await eliminarGasto({idGasto:id}).then(() => {
            setId(null)
            mostrarGastosf()
        })

    }

    const onChangeFecha = (e) => {
        const ob = {...formData2}
        ob[e.target.id] = e.target.value
        setFormData2(ob);
    }

    const onChangeFecha1 = (e) => {

        const ob = {...formData}
        ob[e.target.id] = e.target.value
        setFormData(ob);

    }

    const onSubmit = () => {
        setTipo('agregar')
        if (formData.Tipo === '0') {
            MyToastError('Ingrese Tipo')
            return
        }
        if (formData.Total === '' || formData.Total === '0') {
            MyToastError('Ingrese Total')
            return
        }
        if (formData.Descripcion === '') {
            MyToastError('Ingrese Descripcion')
            return
        }

        let ventaInfo=`<b>TIPO: </b>${formData.Tipo} <br/><b>DESCRIPCION: </b>${formData.Descripcion} <br/><b>TOTAL GASTO: </b>${formData.Total} <br/><b>FECHA: </b>${formData.Fecha}`
        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText('<b>ESTAS A PUNTO DE AGREGAR UN GASTO CON LA SIGUIENTE INFORMACION:</b> <br/>'+ventaInfo)

    }


    const mostrarGastosf = async () => {
        const res = await mostrarGastos(formData2);
        if (res) {
            setGastos(res);
            setGasto(res.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.costo);
            }, 0).toFixed(2))
        }
        setLoad(false)
    }
    const onSubmit2 = () => {
        setLoad(true)
        mostrarGastosf()

    }

    const onClickPop = (e) => {
        setId(e.idGasto)
        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setTipo('eliminar')
        setText(`¿DESEAS ELIMINAR EL GASTO CON <BR> 
        ID: <b>${e.idGasto}</b><BR>
        TIPO: <b>${e.tipo}</b><BR>
        DESCRIPCION: <b>${e.descripcion}</b><BR>
        GASTO: <b>${e.costo}</b><BR>
        FECHA: <b>${e.fecha}</b>
        ?`)

    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center">
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">


                                        <Grid item lg={2} xs={12}>
                                            <MDInput type="date" label="Fecha" id='Fecha' value={formData.Fecha} fullWidth onChange={onChangeFecha1}/>
                                        </Grid>

                                        <Grid item lg={2} xs={12}>
                                            <MDInput
                                                id="demo-simple-select"
                                                value={formData.Tipo}
                                                label="Tipo"
                                                InputProps={{
                                                    classes: {root: "select-input-styles"},
                                                }}
                                                select
                                                fullWidth

                                            >
                                                <MenuItem value={'0'} onClick={onClickTipo}>Seleccion...</MenuItem>
                                                <MenuItem value={'HERRAMIENTA'}
                                                          onClick={onClickTipo}>HERRAMIENTA</MenuItem>
                                                <MenuItem value={'IMPORTACION'}
                                                          onClick={onClickTipo}>IMPORTACION</MenuItem>
                                                <MenuItem value={'EMPLEADO'} onClick={onClickTipo}>EMPLEADO</MenuItem>
                                                <MenuItem value={'VARIOS'} onClick={onClickTipo}>VARIOS</MenuItem>
                                            </MDInput>
                                        </Grid>


                                        <Grid item lg={3} xs={12}>
                                            <MDInput label="Total..." fullWidth value={formData.Total}
                                                     type={'Number'}
                                                     onChange={onChange} id='Total'/>
                                        </Grid>

                                        <Grid item lg={3} xs={12}>
                                            <MDInput label="Descripcion..." fullWidth value={formData.Descripcion}
                                                     onChange={onChange} id='Descripcion'/>
                                        </Grid>

                                        <Grid item lg={1} xs={12}>
                                            <MDButton variant="gradient" color="success"
                                                      startIcon={<Icon fontSize="small">send</Icon>}
                                                      onClick={onSubmit}
                                                      fullWidth>
                                                Registrar
                                            </MDButton>
                                        </Grid>


                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>


                        <Grid container padding={2} spacing={2} justifyContent="center">
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item lg={2} xs={12}>
                                            <MDInput type="date" label="Inicio" value={formData2.Inicio} id="Inicio" onChange={onChangeFecha} fullWidth/>
                                        </Grid>
                                        <Grid item lg={2} xs={12}>
                                            <MDInput type="date" label="Fin" value={formData2.Fin} id="Fin" onChange={onChangeFecha} fullWidth/>
                                        </Grid>

                                        <Grid item lg={2} xs={12}>
                                            <MDButton variant="gradient" color="success"
                                                      startIcon={<Icon fontSize="small">search</Icon>}
                                                      onClick={onSubmit2}
                                                      fullWidth>
                                                Mostrar
                                            </MDButton>
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                        </Grid>
                                        <Grid item lg={2} xs={12}>
                                            <MDInput label="Total de Gastos..." value={gasto} fullWidth disabled={true}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item lg={12} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                                                {!load?(<></>):(<Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>)}
                                                <Grid item lg={12} xs={12}>
                                                    <DataTable
                                                        table={{
                                                            columns: [
                                                                {Header: "ID", accessor: "idGasto", width: "10%"},
                                                                {Header: "Tipo Gasto", accessor: "tipo", width: "20%"},
                                                                {Header: "Descripcion", accessor: "descripcion", width: "50%"},
                                                                {Header: "Costo", accessor: "costo", width: "10%"},
                                                                {Header: "Fecha", accessor: "fecha", width: "10%"}
                                                            ], rows: gastos?gastos:[]
                                                        }}
                                                        canSearch={true}
                                                        noEndBorder={true}
                                                        onRowClick={onClickPop}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={execute}/>
            </MDBox>
        </DashboardLayout>
    );
}

export default Gastos;
