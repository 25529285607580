import React, {useEffect, useState} from "react";


import MDBox from "../../../../../../components/MDBox";
import MDCard from "../../../../../../components/MDCard";
import MDModal from "../../../../../../components/MDModal";
import Grid from "@mui/material/Grid";
import DataTable from "../../../../../../examples/Tables/DataTable";
import {mostrarVehiculos} from "../../../../Actions/Request";
import MDAvatar from "../../../../../../components/MDAvatar";


function VerAutos(props) {

    const [vehiculos, setVehiculos] = useState(null)

    const cargarVehiculosf = async () => {
        const res = await mostrarVehiculos();
        if (res) {
            setVehiculos(res);
        }
    }


    useEffect(() => {

        if (vehiculos === null) {
            cargarVehiculosf()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                {vehiculos?(<></>):(<Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>)}
                                <Grid item lg={12} xs={12}>

                                    <DataTable
                                        table={{
                                            columns: [
                                                {Header: "Linea", accessor: "linea", width:"30%"},
                                                {Header: "Modelo", accessor: "modelo", width:"20%"},
                                                {Header: "Costo", accessor: "costo", width:"10%"},
                                                {Header: "Fecha", accessor: "fecha", width:"10%"},
                                                {Header: "Marcas", accessor: "marcas", width:"20%"},
                                                {Header: "Color", accessor: "color", width:"10%"},
                                            ],
                                            rows: vehiculos ? vehiculos : [],
                                        }}
                                        canSearch={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDCard>
            </MDBox>
        </MDModal>


    )
}

export default VerAutos;