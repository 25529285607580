import {MyToastError, MyToastRequest} from '../../../helpers/MyToast'

export const agregarGasto = async (data) => {

    const toastInfo = {
        endpoint: 'gastos/registrarGastos',
        data:data,
        method: 'POST',
        pendingMessage: 'Registrando Gasto',
        successMessage: 'Gasto Registrado!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const mostrarGastos = async (data) => {

    const toastInfo = {
        endpoint: 'gastos/mostrarGastos',
        data:data,
        method: 'GET'

    }
    const resp = await MyToastRequest(toastInfo)
    if(resp.result.length>0){
        return resp.result
    }else{
        MyToastError('No se encontro informacion')
        return []
    }


}

export const eliminarGasto = async (data) => {

    const toastInfo = {
        endpoint: 'gastos/eliminarGastos',
        data:data,
        method: 'POST',
        pendingMessage: 'Eliminando Gasto',
        successMessage: 'Gasto Eliminado!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}