
import MDBox from "../../components/MDBox";
import MDCard from "../../components/MDCard";
import MDModal from "../../components/MDModal";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import DataTable from "../../examples/Tables/DataTable";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
    actualizarAdmin,
    actualizarPass,
    agregarUsuario,
    borrarUsuario
} from "../../examples/Navbars/DashboardNavbar/actions/Request";
import Modal from "./components/Modal";
import {MyToastError} from "../../helpers/MyToast";






function Users(props) {
    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const [type, setType]=useState('')

    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };


    const execution = () => {
        switch (type){
            case 'res':
                handleClickRestaurar()
                break
            case 'eli':
                handleClickEliminar()
                break
            case 'mod':
                handleClickActualizar()
                break
            default:
        }

    };



    const handleChangeNombre = (e) => {
        let username = e.target.value.length>=3 && props.formData.apellido.length>=3 ? e.target.value.substring(0,1) + props.formData.apellido:('')
        let match = props.users.filter(record=>record.user_name.toLowerCase() === username.toLowerCase()).length
        let index = 2

        while (match !== 0){
            //eslint-disable-next-line
            username = e.target.value.length>=3 && props.formData.apellido.length>=3 ? e.target.value.substring(0,index) + props.formData.apellido:('')
            //eslint-disable-next-line
            match = props.users.filter(record=>record.user_name.toLowerCase() === username.toLowerCase()).length
            index++
        }
        let ob = {...props.formData}
        ob[e.target.id] = e.target.value
        ob['usuario'] = username
        props.setFormData(ob)
    }

    const handleChangeApellido = (e) => {
        let username = props.formData.nombre.length>=3 && e.target.value.length>=3 ? props.formData.nombre.substring(0,1) + e.target.value:('')
        let match = props.users.filter(record=>record.user_name.toLowerCase() === username.toLowerCase()).length
        let index = 2
        while (match !== 0){
            //eslint-disable-next-line
            username = props.formData.nombre.length>=3 && e.target.value.length>=3 ? props.formData.nombre.substring(0,index) + e.target.value:('')
            //eslint-disable-next-line
            match = props.users.filter(record=>record.user_name.toLowerCase() === username.toLowerCase()).length
            index++
        }
        let ob = {...props.formData}
        ob[e.target.id] = e.target.value
        ob['usuario'] = username
        props.setFormData(ob)
    }


    const handleRowClick = (e) => {
        props.setAdminCheck(e.rol_id === 1)
        props.setSeleccion(true)
        const ob = {...props.formData};
        ob['nombre'] = (e.first_name);
        ob['apellido'] = (e.last_name);
        ob['usuario'] = (e.user_name);
        ob['id'] = (e.id);
        props.setFormData(ob)
    };

    const handleClickCancelar = () => {
        props.setAdminCheck(false)
        props.setSeleccion(false)
        const ob = {...props.formData}
        ob['nombre'] = '';
        ob['apellido'] = '';
        ob['usuario'] = '';
        ob['id'] = null;
        props.setFormData(ob);
    };


    const borrarUsuariof = async () => {
        await borrarUsuario({id:props.formData.id,adminCheck:props.adminCheck});
    }
    const agregarUsuariof = async () => {
        await agregarUsuario({...props.formData,adminCheck:props.adminCheck});
    }

    const restaurarUsuariof = async () => {
        await actualizarPass({...props.formData,adminCheck:props.adminCheck});
    }

    const actualizarAdminf = async () => {
        await actualizarAdmin({...props.formData,adminCheck:props.adminCheck});
    }

    const handleClickEliminar = () => {
        borrarUsuariof().then(()=>{
            props.cargarUsers()
            handleClickCancelar()
        })
    }

    const handleClickAgregar = () => {
        if (props.formData.nombre === '') {
            MyToastError('Ingrese Nombre')
            return
        }
        if (props.formData.apellido === '') {
            MyToastError('Ingrese Apellido')
            return
        }
        agregarUsuariof().then(()=>{
            props.cargarUsers()
            handleClickCancelar()
        })
    }

    const handleClickRestaurar = () => {
        restaurarUsuariof().then(()=>{
            props.cargarUsers()
            handleClickCancelar()
        })
    }

    const handleClickActualizar = () => {
        actualizarAdminf().then(()=>{
            props.cargarUsers()
            handleClickCancelar()
        })
    }

    const handleCheck = (e) => {
        props.setAdminCheck(e.target.checked)
    }


    return (
        <MDModal open={props.open} close={props.close}>
            <MDBox>
                <MDCard title={props.title}>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item lg={4} xs={6}>
                                        <MDInput label="Nombre..." fullWidth name={'nombre'} value={props.formData.nombre} disabled={props.seleccion}
                                                 id={'nombre'}
                                                 onChange={handleChangeNombre}
                                        />

                                    </Grid>

                                    <Grid item lg={4} xs={6}>
                                        <MDInput label="Apellido..." fullWidth name={'apellido'} value={props.formData.apellido} disabled={props.seleccion}
                                                 id={'apellido'}
                                                 onChange={handleChangeApellido}
                                        />
                                    </Grid>

                                    <Grid item lg={4} xs={6}>
                                        <MDInput label="Usuario..." fullWidth name={'usuario'} value={props.formData.usuario} disabled={true}
                                                 id={'usuario'}
                                            //onChange={handleFilter}
                                        />
                                    </Grid>

                                </Grid>

                                    {!props.seleccion ? (
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={4} xs={6}>
                                                    <MDButton variant="gradient" color="success"
                                                              startIcon={<Icon fontSize="small">send</Icon>}
                                                        onClick={handleClickAgregar}
                                                              fullWidth>
                                                        AGREGAR
                                                    </MDButton>
                                                </Grid>
                                                < Grid item lg={4} xs={6}>
                                                    <FormControlLabel control={<Checkbox checked={props.adminCheck} onClick={handleCheck}/>}
                                                                      label="Administrador"
                                                                      value={1}

                                                    />
                                                </Grid>
                                            </Grid>
                                    ):(
                                        <Grid container padding={2} spacing={2} justifyContent="center">
                                            <Grid item lg={3} xs={6}>
                                                <FormControlLabel control={<Checkbox checked={props.adminCheck} onClick={handleCheck}/>}
                                                                  label="Administrador"
                                                                  value={1}
                                                />
                                            </Grid>
                                            <Grid item lg={3} xs={6}>
                                                <MDButton variant="gradient" color="info"
                                                          startIcon={<Icon fontSize="small">edit_note</Icon>}
                                                          onClick={()=>{
                                                              setOpen(true)
                                                              setCategory('Popup')
                                                              setSection('confirm')
                                                              setType('mod')
                                                              setText(`ESTAS A PUNTO DE ASIGNAR COMO <b>${props.adminCheck?'ADMINISTRADOR':'USUARIO'}</b> A EL USUARIO: <b>${props.formData.usuario}</b>`  )

                                                          }}
                                                          fullWidth>
                                                    ACTUALIZAR
                                                </MDButton>
                                            </Grid>
                                            <Grid item lg={3} xs={6}>
                                                <MDButton variant="gradient" color="info"
                                                          startIcon={<Icon fontSize="small">edit_note</Icon>}
                                                          onClick={()=>{
                                                              setOpen(true)
                                                              setCategory('Popup')
                                                              setSection('confirm')
                                                              setType('res')
                                                              setText(`ESTAS A PUNTO DE <b>RESTAURAR LA CONTRASEÑA</b> DEL USUARIO: <b>${props.formData.usuario}</b>`)
                                                              //setText(`ESTAS A PUNTO DE ASIGNAR COMO ${props.adminCheck?'ADMINISTRADO':'USUARIO'} A EL USUARIO: ${props.formData.usuario}`  )

                                                          }}
                                                          fullWidth>
                                                    RESTAURAR
                                                </MDButton>
                                            </Grid>
                                            <Grid item lg={3} xs={6}>
                                                <MDButton variant="gradient" color="error"
                                                          startIcon={<Icon fontSize="small">delete</Icon>}
                                                    onClick={()=>{

                                                        setOpen(true)
                                                        setCategory('Popup')
                                                        setSection('confirm')
                                                        setType('eli')
                                                        setText(`ESTAS A PUNTO DE <b>ELIMINAR</b> AL USUARIO: <b>${props.formData.usuario}</b>`)

                                                    }}
                                                          fullWidth>
                                                    ELIMINAR
                                                </MDButton>
                                            </Grid>
                                            <Grid item lg={4} xs={12}>
                                                <MDButton variant="gradient" color="warning"
                                                          startIcon={<Icon fontSize="small">close</Icon>}
                                                    onClick={handleClickCancelar}
                                                          fullWidth>
                                                    CANCELAR
                                                </MDButton>
                                            </Grid>
                                        </Grid>
                                    )}



                                <Grid item lg={12} xs={12}>
                                    <DataTable
                                        table={{
                                            columns: [
                                                {Header: "ID", accessor: "id", width: "10%"},
                                                {Header: "Nombre", accessor: "first_name", width: "30%"},
                                                {Header: "Apellido", accessor: "last_name", width: "30%"},
                                                {Header: "Usuario", accessor: "user_name", width: "20%"},
                                                {Header: "ROL", accessor: "rol_id", width: "20%"}
                                            ],
                                            rows: props.users ? props.users : [],
                                        }}
                                        canSearch={true}
                                        onRowClick={handleRowClick}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Modal open={open} close={handleClose} section={section} category={category} data={null}
                           text={text} execution={execution}/>
                </MDCard>

            </MDBox>
        </MDModal>


    )
}

export default Users;