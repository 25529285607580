import React, {useState} from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import {getCompararVentaProducto, getTotalGastosAutos, getTotalGastosVarios, getVentasRango} from "./Actions/Reques";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import MDAvatar from "../../components/MDAvatar";


function Comparativas() {

    function getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const [formData, setFormData] = useState({
        Inicio: getCurrentDate(),
        Fin: getCurrentDate()
    });
    const [load, setLoad] = useState(false)
    const [totalVentas, setTotalVentas] = useState('')
    const [totalVimportancion, setTotalVimportancion] = useState('')
    const [totalGastos, setTotalGastos] = useState('')
    const [totalGeneral, setTotalGeneral] = useState('')
    const [totalEmpleado, setTotalEmpleado] = useState('')
    const [pTotalEmpleado, setPTotalEmpleado] = useState('')

    const [totalVarios, setTotalVarios] = useState('')
    const [pTotalVarios, setPTotalVarios] = useState('')

    const [totalHerrameinta, setTotalHerramienta] = useState('')
    const [pTotalHerrameinta, setPTotalHerramienta] = useState('')


    const [totalImportacion, setTotalImportacion] = useState('')
    const [pTotalImportacion, setPTotalImportacion] = useState('')

    const [totalAutos, setTotalAutos] = useState('')
    const [pTotalAutos, setPTotalAutos] = useState('')

    const onChangeFecha = (e) => {
        const ob = {...formData}
        ob[e.target.id] = e.target.value
        setFormData(ob);
    }

    const getVentasRangof = async () => {
        const res = await getVentasRango(formData);
        if (res) {
            setTotalVentas(res);
        }

        const res2 = await getCompararVentaProducto(formData);
        if (res2) {
            setTotalVimportancion(res2);
        }

        const res3 = await getTotalGastosVarios({...formData, Tipo: 'EMPLEADO'});
        if (res3) {
            setTotalEmpleado(res3);
        }

        const res4 = await getTotalGastosVarios({...formData, Tipo: 'VARIOS'});
        if (res4) {
            setTotalVarios(res4);
        }

        const res5 = await getTotalGastosVarios({...formData, Tipo: 'HERRAMIENTA'});
        if (res5) {
            setTotalHerramienta(res5);
        }

        const res6 = await getTotalGastosVarios({...formData, Tipo: 'IMPORTACION'});

        if (res6) {
            setTotalImportacion(res6);
        }

        const res7 = await getTotalGastosAutos(formData);
        if (res7) {
            setTotalAutos(res7);
        }




        let suma = (parseFloat(res7) + parseFloat(res3) + parseFloat(res4) + parseFloat(res5) + parseFloat(res6)).toFixed(2)
        let resta= ((parseFloat(res) + parseFloat(res2)) - parseFloat(suma)).toFixed(2)
        setTotalGastos(suma)
        setTotalGeneral(resta)
        setPTotalAutos(((res7*100)/suma).toFixed(2))
        setPTotalEmpleado(((res3*100)/suma).toFixed(2))
        setPTotalVarios(((res4*100)/suma).toFixed(2))
        setPTotalHerramienta(((res5*100)/suma).toFixed(2))
        setPTotalImportacion(((res6*100)/suma).toFixed(2))

        setLoad(false)


    }


    const onSubmit = () => {
        setLoad(true)
        getVentasRangof()
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center">
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item lg={3} xs={6}>
                                            <MDInput type="date" label="Inicio" id="Inicio" value={formData.Inicio}
                                                     fullWidth onChange={onChangeFecha} />
                                        </Grid>
                                        <Grid item lg={3} xs={6}>
                                            <MDInput type="date" label="Fin" id="Fin" value={formData.Fin} fullWidth
                                                     onChange={onChangeFecha}/>

                                        </Grid>
                                        <Grid item lg={2} xs={6}>
                                            <MDButton variant="gradient" color="info"
                                                      startIcon={<Icon fontSize="small">compare_arrows</Icon>} fullWidth
                                                      onClick={onSubmit}>
                                                Comparar
                                            </MDButton>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center">
                            {!load?(<></>):(<Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>)}
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item lg={4} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={12} xs={12}>
                                                    <MDInput label="Total Ventas..." value={'$' + totalVentas} disabled={true}
                                                             fullWidth/>
                                                </Grid>
                                            </Grid>

                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={12} xs={12}>
                                                    <MDInput label="Total Ventas Importacion..."
                                                             value={'$' + totalVimportancion}
                                                             disabled={true}
                                                             fullWidth/>
                                                </Grid>
                                            </Grid>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={12} xs={12}>

                                                    <MDInput label="Total Gastos..." value={'$' + totalGastos} disabled={true}
                                                             fullWidth/>
                                                </Grid>
                                            </Grid>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={12} xs={12}>
                                                    <MDInput label="Total General..." value={'$' + totalGeneral} disabled={true}
                                                             fullWidth/>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid item lg={4} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={8} xs={8}>
                                                    <MDInput label="Gasto Autos..." value={'$' + totalAutos} disabled={true}
                                                             fullWidth/>
                                                </Grid>
                                                <Grid item lg={4} xs={4}>
                                                    <MDInput label="%" value={pTotalAutos} disabled={true} fullWidth/>
                                                </Grid>
                                            </Grid>

                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={8} xs={8}>
                                                    <MDInput label="Gastos Empleado..."
                                                             value={'$' + totalEmpleado}
                                                             disabled={true}
                                                             fullWidth/>
                                                </Grid>
                                                <Grid item lg={4} xs={4}>
                                                    <MDInput label="%" value={pTotalEmpleado} disabled={true} fullWidth/>
                                                </Grid>
                                            </Grid>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={8} xs={8}>
                                                    <MDInput label="Gastos Varios..." value={'$' + totalVarios} disabled={true}
                                                             fullWidth/>
                                                </Grid>
                                                <Grid item lg={4} xs={4}>
                                                    <MDInput label="%" value={pTotalVarios} disabled={true} fullWidth/>
                                                </Grid>
                                            </Grid>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={8} xs={8}>
                                                    <MDInput label="Gastos Herramientas..."
                                                             value={'$' + totalHerrameinta} disabled={true}
                                                             fullWidth/>
                                                </Grid>
                                                <Grid item lg={4} xs={4}>
                                                    <MDInput label="%" value={pTotalHerrameinta} disabled={true} fullWidth/>
                                                </Grid>
                                            </Grid>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <Grid item lg={8} xs={8}>
                                                    <MDInput label="Gastos Importacion..."
                                                             value={'$' + totalImportacion} disabled={true}
                                                             fullWidth/>
                                                </Grid>
                                                <Grid item lg={4} xs={4}>
                                                    <MDInput label="%" value={pTotalImportacion} disabled={true} fullWidth/>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                <DefaultDoughnutChart
                                                    icon={{ color: "info", component: "leaderboard" }}
                                                    title="Grafica Comparativa"
                                                    description="Gastos"
                                                    chart={{
                                                        labels: ["Autos", "Empleado", "Varios", "Herramientas", "Importacion"],
                                                        datasets: {
                                                            label: "Porcentaje",
                                                            backgroundColors: ["info", "info", "warning", "secondary", "success"],
                                                            data: [pTotalAutos, pTotalEmpleado, pTotalVarios, pTotalHerrameinta, pTotalImportacion],
                                                        },
                                                    }}
                                                />


                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </MDBox>
        </DashboardLayout>);
}

export default Comparativas;
