import { MyToastRequest} from '../../../helpers/MyToast'

export const getVentasRango = async (data) => {
    const toastInfo = {
        endpoint: 'ventas/ventasRango',
        data: data,
        method: 'GET'
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result[0].total?resp.result[0].total:'0'
}


export const getCompararVentaProducto = async (data) => {
    const toastInfo = {
        endpoint: 'ventaproducto/compararVentaProducto',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result[0].totalVimportancion?resp.result[0].totalVimportancion:'0'
}

export const getTotalGastosVarios = async (data) => {
    const toastInfo = {
        endpoint: 'gastos/totalGastosVarios',
        data: data,
        method: 'GET'
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result[0].totalGastos?resp.result[0].totalGastos:'0'
}

export const getTotalGastosAutos = async (data) => {
    const toastInfo = {
        endpoint: 'vehiculos/totalGastosAutos',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result[0].total?resp.result[0].total:'0'
}