import {MyToastRequest} from "../../../../../../../helpers/MyToast";


export const eliminarVenta = async (data) => {

    const toastInfo = {
        endpoint: 'ventaproducto/eliminarVenta',
        data: data,
        method: 'POST',
        pendingMessage: data.fullupdate?'Elimnando venta y restaurando stock...':'Eliminado Venta...',
        successMessage: data.fullupdate?'Venta eliminada y stock restaurado':'Venta Eliminada'

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}