import React, {useEffect, useState} from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import MDButton from "../../components/MDButton";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from "examples/Tables/DataTable";
import MenuItem from '@mui/material/MenuItem';
import {ToastContainer} from "react-toastify";
import {
    agregarCotizacion,
    agregarVenta,
    borrarCotizacion,
    getMarcas,
    getSubMarcas,
    getVehiculosBySubmarca, updateCotizacion
} from "./Actions/Request";
import {getMostrarVentasCotizaciones} from "./Actions/Request";
import Icon from "@mui/material/Icon";
import Modal from "../gastos/components/Modal";
import {MyToastError} from "../../helpers/MyToast";
import MDAvatar from "../../components/MDAvatar";


function Template() {
    const [subMarcas, setSubMarcas] = useState(null)
    const [marcas, setMarcas] = useState(null)
    const [vehiculos, setVehiculos] = useState(null)
    const [VeCo, setVeCo] = useState(null)
    const [load, setLoad] = useState(false)
    const [seleccion, setSeleccion] = useState(false)
    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const [idxrow, setIdxrow] = useState(false)


    const [subMarca, setSubMarca] = useState(null)
    const [marca, setMarca] = useState(null)
    const [vehiculo, setVehiculo] = useState(null)
    const [opcion, setOpcion] = useState(null)


    const [formData, setFormData] = useState({
        idVeh: null,
        Pieza: '',
        Precio: '',
        Select: 'Ventas',
        Check: false,
        idSubMarca: null,
        Descripcion: '',
        indexM:'0',
        indexSM:'0',
        indexV:'0',
        Tipo:'',
        id:null
    });

    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };



    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }

    const cargarSubMarcas = async (data) => {
        const res = await getSubMarcas(data);
        if (res) {
            setSubMarcas(res);
        }
    }
    const onChangeMarca = (e) => {
        const ob = {...formData}
        ob['indexM']=e.target.dataset.value
        ob['indexSM']='0'
        ob['indexV']='0'
        ob['idVeh'] = null
        ob['id'] = null
        setFormData(ob);
        setSubMarcas(null)
        setVehiculos(null)
        setMarca(e.target.innerText)
        cargarSubMarcas({idMarca: e.target.dataset.value})
    }


    const cargarVehiculos = async (data) => {
        const res = await getVehiculosBySubmarca(data);
        if (res) {
            setVehiculos(res);
        }
    }
    const onChangeSubMarca = (e) => {
        setSubMarca(e.target.innerText)

        const ob = {...formData}
        ob['indexSM']=e.target.dataset.value
        ob['indexV']='0'
        ob['idVeh'] = null
        ob['id'] = null
        ob['idSubMarca'] = e.target.dataset.value
        setFormData(ob);
        cargarVehiculos({idSubMarca: e.target.dataset.value})
    }


    const onChangeVeh = (e) => {
        setVehiculo(e.target.innerText)

        const ob = {...formData}
        ob['indexV']=e.target.dataset.value
        ob['idVeh'] = e.target.dataset.value
        setFormData(ob);

    }


    const cargarVeCo = async (data) => {
        const res = await getMostrarVentasCotizaciones(data);
        if (res) {
            setVeCo(res)
            setLoad(false)
        }
    }

    const handleChangeDesc = (e) => {
        setLoad(true)
        const ob = {...formData}
        ob['Select'] = formData.Check ? 'Todo' : formData.Select
        ob['Descripcion'] = e.target.value

        setFormData(ob);

        if (!seleccion) {
            cargarVeCo({...ob, Descripcion: e.target.value});
        }

    }

    const handleChange = (pieza, precio, idVeh, tipo, id) => {

        let x=formData.Select
        const ob = {...formData}
        ob['Descripcion'] = pieza
        ob['Precio'] = precio
        ob['idVeh'] = idVeh
        ob['id'] = id
        ob['Tipo'] = tipo
        setFormData(ob);
        ob['Select'] = formData.Check ? 'Todo' : formData.Select

        cargarVeCo({...ob, Descripcion: pieza}).then(()=>{
            ob['Select'] = x

        });


    }

    const onSelect = (e) => {
        const ob = {...formData}
        ob['Select'] = e.target.labels[0].id
        setFormData(ob);


    }
    const onCheck = (e) => {
        const ob = {...formData}
        ob['Check'] = e.target.checked
        setFormData(ob);


        if (!seleccion) {
            cargarVeCo(ob);
        }

    }


    const handleChangePrecio = (e) => {
        const ob = {...formData}
        ob['Precio'] = e.target.value
        setFormData(ob);
    }


    const handleRowClick = (e) => {



        let row=(VeCo.find(record=>record.ID===parseInt(e.ID)))
        if (row.Tipo==='c'){
            setIdxrow(0)
            handleChange(row.Pieza, row.Precio, row.idVeh, row.Tipo, row.ID)
            setSeleccion(true)
        }else {

            handleChange(row.Pieza, row.Precio, row.idVeh, row.Tipo, null)
        }



    };

    const handleClickCancelar = () => {
        setIdxrow(-1)
        setSeleccion(false)
        const ob = {...formData}
        ob['id'] = null
        setFormData(ob);
    };




    const handleClickEliminar =()=>{

        if(formData.Tipo==='c'){

            setOpcion('E')

            let Info=`<b>COSTO: </b>${formData.Precio} <br/><b>AUTO: </b>${vehiculo} <br/><b>MARCA: </b>${marca} <br/><b>SUBMARCA: </b>${subMarca} <br/><b>PIEZA: </b>${formData.Descripcion}`
            setOpen(true)
            setCategory('Popup')
            setSection('confirm')
            setText(`ESTAS A PUNTO DE ELIMINAR UNA <b> ${formData.Select==='Ventas'?'VENTA':'COTIZACION'}</b> CON LA SIGUIENTE INFORMACION: <br/>`+Info)
        }else {
            MyToastError('Ventas no pueden ser eliminadas')
        }



    }


    const handleClickAgregar =()=>{

        if (formData.Precio <= 0 || formData.Precio === '' ) {
            MyToastError('Ingrese Precio')
            return
        }
        if (formData.idVeh===null ) {
            MyToastError('Seleccione Auto')
            return
        }

        if (formData.Descripcion==='' ) {
            MyToastError('Ingrese Pieza')
            return
        }




        setOpcion('A')

        let Info=`<b>COSTO: </b>${formData.Precio} <br/><b>AUTO: </b>${vehiculo} <br/><b>MARCA: </b>${marca} <br/><b>SUBMARCA: </b>${subMarca} <br/><b>PIEZA: </b>${formData.Descripcion}`
        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE AGREGAR UNA <b> ${formData.Select==='Ventas'?'VENTA':'COTIZACION'}</b> CON LA SIGUIENTE INFORMACION: <br/>`+Info)

    }




    const handleClickEditar =()=>{

        setOpcion('U')

            if(formData.Tipo==='c'){
                let Info=`<b>COSTO: </b>${formData.Precio} <br/><b>AUTO: </b>${vehiculo} <br/><b>MARCA: </b>${marca} <br/><b>SUBMARCA: </b>${subMarca} <br/><b>PIEZA: </b>${formData.Descripcion}`
                setOpen(true)
                setCategory('Popup')
                setSection('confirm')
                setText(`ESTAS A PUNTO DE EDITAR UNA <b> ${formData.Select==='Ventas'?'VENTA':'COTIZACION'}</b> QUE SERA ACTUALIZADA CON LA SIGUIENTE INFORMACION: <br/>`+Info)

            }else {
                MyToastError('Ventas no pueden ser editadas')
            }
    }

    const agregarVentaf = async () => {

        const ob = {...formData}
        ob['Pieza'] = formData.Descripcion



        if(formData.Select==='Ventas'){
            await agregarVenta(ob).then(() => {

                 setFormData({
                     idVeh: null,
                     Pieza: '',
                     Precio: '',
                     Select: 'Ventas',
                     Check: false,
                     idSubMarca: null,
                     Descripcion: '',
                     indexM:'0',
                     indexSM:'0',
                     indexV:'0',
                     id: null,
                 });


                setSubMarcas(null)
                setVehiculos(null)
                setOpcion('')

            })
        }else {


            await agregarCotizacion(ob).then(() => {



                setFormData({
                    idVeh: null,
                    Pieza: '',
                    Precio: '',
                    Select: 'Cotizaciones',
                    Check: false,
                    idSubMarca: null,
                    Descripcion: '',
                    indexM:'0',
                    indexSM:'0',
                    indexV:'0',
                    id: null,
                });


                setSubMarcas(null)
                setVehiculos(null)
                setOpcion('')

            })

        }



    }

    const elimimarVentaf = async () => {

        if(formData.Tipo==='c'){

            await borrarCotizacion(formData).then(()=>{
                setSeleccion(false)

                setFormData({
                    idVeh: null,
                    Pieza: '',
                    Precio: '',
                    Select: 'Cotizaciones',
                    Check: false,
                    idSubMarca: null,
                    Descripcion: '',
                    indexM:'0',
                    indexSM:'0',
                    indexV:'0',
                    id: null,
                });
                setSubMarcas(null)
                setVehiculos(null)
                setOpcion('')
                setVeCo(null)

            })
        }else {
            MyToastError('Ventas no pueden ser eliminadas')
        }

    }

    const editarVentaf = async () => {
        const ob = {...formData}
        ob['Pieza'] = formData.Descripcion

        if(formData.Tipo==='c'){

            await updateCotizacion(ob).then(()=>{
                setSeleccion(false)

            })
        }else {
            MyToastError('Ventas no pueden ser eliminadas')
        }

    }

    const exec = async()=>{
        if(opcion==='A'){
            agregarVentaf()
        }
        if(opcion==='E'){
            elimimarVentaf()
        }
        if(opcion==='U'){
            editarVentaf()
        }

    }


    useEffect(() => {
        if (marcas === null) {
            cargarMarcas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item xl={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xl={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item xl={3} xs={12}>

                                            <MDInput label="Precio..." fullWidth value={formData.Precio} type={'number'}
                                                     onChange={handleChangePrecio}/>
                                        </Grid>
                                        <Grid item xl={3} xs={12}>
                                            <MDInput
                                                id="demo-simple-select"
                                                value={formData.indexM}
                                                label="Marca"
                                                InputProps={{
                                                    classes: {root: "select-input-styles"},
                                                }}
                                                select

                                                fullWidth
                                            >

                                                <MenuItem value={'0'} onClick={onChangeMarca}>Seleccion...</MenuItem>
                                                {
                                                    marcas ? (marcas.map((itm, index) =>
                                                            <MenuItem value={itm.idMarcas}
                                                                      key={index + 'marcas'}
                                                                      id={index + 'marcas'}
                                                                      onClick={onChangeMarca}>{itm.marcas}</MenuItem>))
                                                        :
                                                        (<MenuItem></MenuItem>)
                                                }

                                            </MDInput>
                                        </Grid>
                                        <Grid item xl={3} xs={12}>
                                            <MDInput
                                                id="demo-simple-select"
                                                value={formData.indexSM}
                                                label="SubMarca"
                                                InputProps={{
                                                    classes: {root: "select-input-styles"},
                                                }}
                                                select

                                                fullWidth
                                            >
                                                <MenuItem value={'0'} onClick={onChangeSubMarca}>Seleccion...</MenuItem>
                                                {
                                                    subMarcas ? (subMarcas.map((itm, index) =>
                                                            <MenuItem value={itm.idSubMarca}
                                                                      key = {index + 'submarcas'}
                                                                      id={index + 'submarcas'}
                                                                      onClick={onChangeSubMarca}>{itm.SubMarca}</MenuItem>))
                                                        :
                                                        (<MenuItem></MenuItem>)
                                                }
                                            </MDInput>
                                        </Grid>
                                        <Grid item xl={3} xs={12}>

                                            <MDInput
                                                id="demo-simple-select"
                                                label="Auto"
                                                value={formData.indexV}
                                                InputProps={{
                                                    classes: {root: "select-input-styles"},
                                                }}
                                                select
                                                fullWidth
                                            >
                                                <MenuItem value={'0'} onClick={onChangeVeh}>Seleccion...</MenuItem>
                                                {
                                                    vehiculos ? (vehiculos.map((itm, index) =>
                                                            <MenuItem value={itm.idVeh}
                                                                      key = {index + 'veh'}
                                                                      id={index + 'veh'}
                                                                      onClick={onChangeVeh}>{itm.Linea + ' | ' + itm.Modelo + ' | ' + itm.color}</MenuItem>))
                                                        :
                                                        (<MenuItem></MenuItem>)
                                                }
                                            </MDInput>


                                        </Grid>
                                    </Grid>

                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item lg={12} xs={12}>
                                            <Grid container justifyContent="center">
                                                <Grid item xl={8} xs={12} paddingTop={4}>

                                                    <MDInput label="Click para escribir..." multiline rows={5}
                                                             value={formData.Descripcion}
                                                             onChange={handleChangeDesc}
                                                             fullWidth/>
                                                </Grid>

                                                <Grid item xl={4} xs={12}>
                                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                                        <Grid item lg={12} xs={12}>
                                                            <Grid container justifyContent="center">
                                                                < Grid item lg={4}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox/>}
                                                                        label="Todos"
                                                                        onClick={onCheck}
                                                                    />
                                                                </Grid>
                                                                < Grid item lg={8} xs={12}>
                                                                    <RadioGroup row
                                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                name="row-radio-buttons-group"
                                                                                defaultValue='Ventas'
                                                                                onChange={onSelect}>
                                                                        <FormControlLabel value="Ventas" id="Ventas"
                                                                                          control={<Radio/>}
                                                                                          label="Ventas"/>
                                                                        <FormControlLabel value="Cotizaciones"
                                                                                          id="Cotizaciones"
                                                                                          control={<Radio/>}
                                                                                          label="Cotizaciones"/>
                                                                    </RadioGroup>
                                                                </Grid>

                                                                <Grid container spacing={2} padding={1}
                                                                      justifyContent="center">
                                                                    <Grid item lg={6} xs={6}>
                                                                        {seleccion ? ('') : (
                                                                            <MDButton variant="gradient" color="success"
                                                                                      startIcon={<CheckIcon/>}
                                                                                      onClick={handleClickAgregar}
                                                                                      fullWidth>
                                                                                Agregar
                                                                            </MDButton>)}

                                                                    </Grid>
                                                                    <Grid item lg={6} xs={6}>
                                                                        {seleccion?(
                                                                            <MDButton variant="gradient" color="warning"
                                                                                      startIcon={<CloseIcon/>}
                                                                                      onClick={handleClickCancelar}
                                                                                      fullWidth>
                                                                                Cancelar
                                                                            </MDButton>
                                                                        ):('')}

                                                                    </Grid>
                                                                </Grid>

                                                                {seleccion ? (
                                                                    <Grid container spacing={2} padding={1}
                                                                          justifyContent="center">
                                                                        <Grid item lg={6} xs={6}>
                                                                            <MDButton variant="gradient" color="info"
                                                                                      startIcon={<Icon
                                                                                          fontSize="small">edit_note</Icon>}
                                                                                      fullWidth
                                                                                      onClick={handleClickEditar}>

                                                                                Editar
                                                                            </MDButton>
                                                                        </Grid>

                                                                        <Grid item lg={6} xs={6}>
                                                                            <MDButton variant="gradient" color="error"
                                                                                      startIcon={<Icon
                                                                                          fontSize="small">delete</Icon>}
                                                                                      onClick={handleClickEliminar}
                                                                                      fullWidth>
                                                                                Eliminar
                                                                            </MDButton>
                                                                        </Grid>
                                                                    </Grid>) : ('')}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xl={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xl={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item lg={12} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                {!load? ('') : (
                                                    <Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>


                                                )}
                                                <Grid item lg={12} xs={12}>
                                                    <DataTable
                                                        table={{
                                                            columns: [
                                                                {Header: "ID", accessor: "ID", width: "10%"},
                                                                {Header: "Pieza", accessor: "Pieza", width: "25%"},
                                                                {Header: "Precio", accessor: "Precio", width: "10%"},
                                                                {
                                                                    Header: "Costo MercadoLibre",
                                                                    accessor: "Costo_MercadoLibre",
                                                                    width: "10%"
                                                                },
                                                                {Header: "Linea", accessor: "Linea", width: "10%"},
                                                                {Header: "Color", accessor: "Color", width: "10%"},
                                                                {Header: "Modelo", accessor: "Modelo", width: "5%"},
                                                                {Header: "Fecha", accessor: "Fecha", width: "10"},
                                                                {Header: "Tipo", accessor: "Tipo", width: "10"},

                                                            ],
                                                            rows: VeCo ? seleccion?[VeCo.find(record=>record.ID===parseInt(formData.id))]:VeCo : []
                                                        }}
                                                        canSearch={true}
                                                        onRowClick={handleRowClick}
                                                        seleccion={[idxrow]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={exec}/>
            </MDBox>
            <ToastContainer autoClose={2000}/>
        </DashboardLayout>);
}

export default Template;
