import React, {useEffect, useState} from "react";

import MDBox from "../../../../../../components/MDBox";
import MDModal from "../../../../../../components/MDModal";
import MDCard from "../../../../../../components/MDCard";
import MDInput from "../../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import {
    buscarVehiculo,
    getMarcas,
    getSubMarcas,
    modificarVehiculo,
    mostrarColores
} from "../../../../Actions/Request";
import {MyToastError} from "../../../../../../helpers/MyToast";
import Modal from "../../../Modal_Confirm";


function ModificarAuto(props) {


    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };
    const onClickPop= () => {
        if (formData.idMarca === null || formData.idMarca ==='0') {
            MyToastError('Ingrese Marca')
            return
        }
        if (formData.idSubMarca === null || formData.idSubMarca ==='0') {
            MyToastError('Ingrese SubMarca')
            return
        }
        if (formData.fecha === '') {
            MyToastError('Ingrese Fecha')
            return
        }
        if (formData.linea === '') {
            MyToastError('Ingrese Linea')
            return
        }
        if (formData.modelo === null || formData.modelo ==='') {
            MyToastError('Ingrese Modelo')
            return
        }
        if (formData.idColor === null || formData.idColor ==='0') {
            MyToastError('Ingrese Color')
            return
        }
        if (formData.precio === null || formData.precio === '') {
            MyToastError('Ingrese Precio')
            return
        }


        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE <b>ACTUALIZAR</b> UN AUTO CON LA SIGUIENTE INFORMACION:<br>
                                                        <b>MARCA</b>:${formData.marca}<br>
                                                        <b>SUBMARCA</b>:${formData.submarca}<br>
                                                        <b>LINEA</b>:${formData.linea}<br>
                                                        <b>MODELO</b>:${formData.modelo}<br>
                                                        <b>COLOR</b>:${formData.color}<br>
                                                        <b>PRECIO</b>:${formData.precio}`)

    }








    function getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }


    const [formData, setFormData] = useState({
        idVehiculo: '',
        idMarca: 0,
        marca:'',
        idSubMarca: 0,
        submarca:'',
        fecha:getCurrentDate(),
        linea:'',
        modelo:'',
        idColor:0,
        color:'',
        precio:0
    });

    const [subMarcas, setSubMarcas] = useState(null)
    const [marcas, setMarcas] = useState(null)
    const [colores, setColores] = useState(null)



    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }
    const cargarColores = async () => {
        const res = await mostrarColores();
        if (res) {
            setColores(res);
        }
    }




    const cargarSubMarcas = async (data, idSubMarca) => {
        const res = await getSubMarcas(data);
        if (res) {
            if(idSubMarca!==0){
                const ob = {...formData}
                ob['idSubMarca'] = res.find(record => record.idSubMarca === idSubMarca).SubMarca
                setFormData(ob)
            }
            setSubMarcas(res);
        }

    }


    const onChangeIdVehiculo= (e) => {
        const ob = {...formData}
        ob['idVehiculo'] = e.target.value
        setFormData(ob);
    }

    const onChangeMarca = (e) => {
        const ob = {...formData}
        ob['idMarca'] = e.target.dataset.value
        ob['marca']=e.target.innerText
        setFormData(ob);
    }
    const onChangeSubMarca = (e) => {

        const ob = {...formData}
        ob['idSubMarca'] = e.target.dataset.value
        ob['submarca']=e.target.innerText
        setFormData(ob);
    }


    const onChangeFecha = (e) => {
        const ob = {...formData}
        ob['fecha'] = e.target.value
        setFormData(ob);
    }

    const onChangeLinea= (e) => {
        const ob = {...formData}
        ob['linea'] = e.target.value
        setFormData(ob);
    }
    const onChangeModelo = (e) => {
        const ob = {...formData}
        ob['modelo'] = e.target.value
        setFormData(ob);
    }
    const onChangeColor = (e) => {
        const ob = {...formData}
        ob['idColor'] = e.target.dataset.value
        ob['color']=e.target.innerText
        setFormData(ob);
    }
    const onChangePrecio = (e) => {
        const ob = {...formData}
        ob['precio'] = e.target.value
        setFormData(ob);
    }



    const buscarVehiculof = async (data) => {
        const res = await buscarVehiculo(data);




        if (res) {

             await cargarSubMarcas({idMarca:res[0].idMarca},res[0].idSubMarca)
            const ob = {...formData}
            ob['marca'] = marcas.find(record => record.idMarcas === res[0].idMarca).marcas
            ob['color'] = colores.find(record => record.idColor === res[0].idColor).color
            ob['idMarca'] = res[0].idMarca
            ob['idSubMarca'] = res[0].idSubMarca
            ob['idColor'] = res[0].idColor
            ob['linea'] = res[0].linea
            ob['modelo'] = res[0].modelo
            ob['precio'] = res[0].costo
            ob['fecha'] = res[0].fecha
            setFormData(ob)

        }
    }

    const onClickBuscar = () => {



        if (formData.idVehiculo === null) {
            MyToastError('Ingrese ID del Vehiculo')
            return
        }


        buscarVehiculof({idVehiculo:formData.idVehiculo}).then(()=>{
        })
    }
    useEffect(() => {
        const ob = {...formData}
        //ob['submarca'] = subMarca
        setFormData(ob)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subMarcas]);
    const modificarVehiculof = async (data) => {
         await modificarVehiculo(data);

    }

    const onClickSubmit = () => {
        modificarVehiculof(formData).then(()=>{
            props.close()
        })
    }




    useEffect(() => {

        if (marcas === null) {
            cargarMarcas()
        }
        if (colores === null) {
            cargarColores()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (

        <MDModal open={props.open} close={props.close}>
            <MDBox>

                <MDCard title={props.title}>
                    <Grid>
                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={3} xs={6}>
                                    <MDInput label="Id Vehiculo..." type="number" value={formData.idVehiculo} onChange={onChangeIdVehiculo}/>

                                </Grid>
                                <Grid item xl={3} xs={6}>
                                    <MDButton variant="gradient" color="light"
                                              startIcon={<Icon fontSize="small">search</Icon>} fullWidth
                                    onClick={onClickBuscar}
                                    >
                                        Buscar
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDInput label="Linea..." fullWidth
                                             value={formData.linea}
                                             onChange={onChangeLinea}
                                    />
                                </Grid>
                                <Grid item xl={3} xs={6}>
                                    <MDInput label="Modelo..." fullWidth
                                             value={formData.modelo}
                                             onChange={onChangeModelo}
                                    />
                                </Grid>
                                <Grid item xl={3} xs={6}>
                                    <MDInput
                                             id="demo-simple-select"
                                             value={formData.idColor}
                                             label="Color"
                                             InputProps={{
                                                 classes: {root: "select-input-styles"},
                                             }}
                                             select
                                             fullWidth
                                    >

                                        <MenuItem value="0" onClick={onChangeColor}>Seleccione...</MenuItem>
                                        {
                                            colores ? (colores.map((itm, index) =>

                                                    <MenuItem value={itm.idColor}
                                                              key={index+'color'}
                                                              onClick={onChangeColor}>{itm.color}</MenuItem>))
                                                :
                                                (<MenuItem></MenuItem>)
                                        }
                                    </MDInput>
                                </Grid>

                                <Grid item xl={2} xs={6}>
                                    <MDInput label="Precio..." fullWidth

                                             type="number"

                                             value={formData.precio}
                                             onChange={onChangePrecio}
                                    />
                                </Grid>
                                <Grid item xl={2} xs={6}>
                                    <MDInput type="date" label="Date" fullWidth
                                             value={formData.fecha}
                                             onChange={onChangeFecha}
                                    />
                                </Grid>
                                <Grid item xl={3} xs={6}>
                                    <MDInput
                                             id="demo-simple-select"
                                             value={formData.idMarca}
                                             label="Marca"
                                             InputProps={{
                                                 classes: {root: "select-input-styles"},
                                             }}
                                             select
                                             fullWidth
                                    >
                                        <MenuItem value="0" onClick={onChangeMarca}>Seleccione...</MenuItem>
                                        {
                                            marcas ? (marcas.map((itm, index) =>
                                                    <MenuItem value={itm.idMarcas}
                                                              key={index+'marcas'}
                                                              onClick={onChangeMarca}>{itm.marcas}</MenuItem>))
                                                :
                                                (<MenuItem></MenuItem>)
                                        }
                                    </MDInput>
                                </Grid>
                                <Grid item xl={5} xs={6}>
                                    <MDInput
                                             id="demo-simple-select"
                                             value={formData.idSubMarca}
                                             label="SubMarca"
                                             InputProps={{
                                                 classes: {root: "select-input-styles"},
                                             }}
                                             select
                                             fullWidth
                                    >
                                        <MenuItem value="0" onClick={onChangeSubMarca}>Seleccione...</MenuItem>

                                        {
                                            subMarcas ? (subMarcas.map((itm, index) =>
                                                    <MenuItem value={itm.idSubMarca}
                                                              key={index+'submarca'}
                                                              onClick={onChangeSubMarca}>{itm.SubMarca}</MenuItem>))
                                                :
                                                (<MenuItem></MenuItem>)
                                        }
                                    </MDInput>
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item xl={12} xs={12}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="success"
                                              startIcon={<Icon fontSize="small">send</Icon>}
                                              onClick={onClickPop}
                                    >
                                        Modificar
                                    </MDButton>
                                </Grid>
                                <Grid item xl={6} xs={12}>
                                    <MDButton variant="gradient" color="error"
                                              startIcon={<Icon fontSize="small">close</Icon>} onClick={props.close}>
                                        Cancelar
                                    </MDButton>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </MDCard>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={onClickSubmit}/>
            </MDBox>
        </MDModal>


    )
}

export default ModificarAuto;