/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// react-router components
import { useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";


// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  //setOpenConfigurator,
} from "context";
import MDAvatar from "../../../components/MDAvatar";
import Grid from "@mui/material/Grid";
import {useMediaQuery} from "@mui/material";
import Modal from "../../../layouts/users/index";
import {desactivarStockNotif, mostrarStockNotif, mostrarUsers} from "./actions/Request";
import ModalPassword from "../../../layouts/users/components/modalPassword";
import ModalLogOut from "./components/Modal";
import ModalNotificacion from "./components/Modal_Confirm";
import MDBadge from "../../../components/MDBadge";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, /*openConfigurator,*/ darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openPass, setOpenPass] = useState(false);
  const [seleccion, setSeleccion] = useState(false)
  const [adminCheck, setAdminCheck] = useState(false)
  const [section, setSection] = useState('')
  const [section3, setSection3] = useState('')
  const [category, setCategory] = useState('')
  const [category3, setCategory3] = useState('')
  const [text3, setText3] = useState('')
  const [infoProducto, setInfoProducto] = useState(null)
  const [formData, setFormData] = useState({
    id: null,
    nombre: '',
    apellido: '',
    usuario: ''
  })
  const [formDataPass, setFormDataPass] = useState({
    id: null,
    password: ''
  })



  const mostrarStockNotifFunction = async()=>{

    const res = await mostrarStockNotif();
    if (res) {
      setData(res);
    }
  }







  useEffect(() => {
    // Función para obtener los datos del endpoint

    const fetchData = async () => {
      await mostrarStockNotifFunction()
    };

    // Llama a fetchData inmediatamente
    fetchData();

    // Establece un intervalo para llamar a fetchData cada X milisegundos
    const intervalId = setInterval(fetchData, 5000); // 5000ms = 5s

    // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Dependencia de 'data' para detectar cambios y comparar




















  useEffect(() => {
    // Setting the navbar type
    if(localStorage.getItem('first_login')==='true'){
      handleOpenPass()
    }

    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
    // eslint-disable-next-line
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  //const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleOpen = () => {
      cargarUsers()
    setFormData({
      id: null,
      nombre: '',
      apellido: '',
      usuario: ''
    })
    setSeleccion(false)
    setAdminCheck(false)
    setOpen(true)
  };




  const handleOpenPass = () => {
    setFormDataPass({
      id: localStorage.getItem('user_id'),
      password: ''
    })
    setOpenPass(true)
  };

  const handleClose = () => {
    setOpen(false)
  };

  const handleClose2 = () => {
    setSection('')
    setCategory('')
    setOpen2(false)
  };

  const execute3 = (tipo) => {
    desactivarStockNotifF({...infoProducto,tipoNotif:tipo}).then(() => {
      mostrarStockNotifFunction().then(() => {})
    })
  };
  const desactivarStockNotifF = async (data) => {
    await desactivarStockNotif(data);
  }


  const handleOpen3 = (info) => {
    setInfoProducto(info)
    setSection3('confirm')
    setCategory3('Popup')
    setText3('<b> ID: </b>' + info.idProducto + '</br> <b> PRODUCTO: </b>' + info.Producto + '</br>')
    setOpen3(true)
  };

  const handleClose3 = () => {
    setSection3('')
    setCategory3('')
    setOpen3(false)
  };

  const handleClosePass = () => {
    setOpenPass(false)
  };



  const [users, setUsers] = useState(null)




  const cargarUsers = async () => {
    const res = await mostrarUsers();
    if (res) {
      setUsers(res);
    }
  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2}}
    >
      {data?data.map((item, idx) => (
          <NotificationItem
              key={'notificacion' + idx}
              icon={<>{item.fis===1?<><MDBadge badgeContent="Fisico" color={'warning'} size="sm" container/> </>:<></>}{item.ful===1?<MDBadge badgeContent="Full" color={'success'} size="sm" container/> :<></>}</>}
              title = {"<b>ID: </b>" + item.idProducto + "<b> - PRODUCTO: </b>" + item.Producto + (item.tipo=== 2?"<b> - SIN STOCK...</b>" :"<b> - STOCK MENOR A: </b>" + item.cantidad_alerta)}
              onClick={()=>handleOpen3(item)}
              backgroundColor={item.tipo===2?"#ff4545":"#fffa63"}
          />
      )):[]}
    </Menu>
  );
const cerrarSesion =()=>{
  localStorage.clear();
  window.location.href = '/'
}
  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const isXsScreen = useMediaQuery('(max-width:600px)');

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        <MDBox>
          <Grid container direction="row" justifyContent="center" spacing={5}>
            {isXsScreen?'':<>
              <Grid item xs="auto">
                <MDAvatar src={'images/wols.png'} shadow="sm"/>
              </Grid>
              <Grid item xs="auto">
                <MDAvatar src={'images/ford.png'} shadow="sm"/>
              </Grid>
              <Grid item xs="auto">
                <MDAvatar src={'images/chevrolet.png'} shadow="sm"/>
              </Grid>
              <Grid item xs="auto">
              <MDAvatar src={'images/toyota.png'} shadow="sm"/>
              </Grid>
              <Grid item xs="auto">
                <MDAvatar src={'images/mazda.png'} shadow="sm"/>
              </Grid>
              <Grid item xs="auto">
                <MDAvatar src={'images/nissan.png'} shadow="sm"/>
              </Grid>
              <Grid item xs="auto">
                <MDAvatar src={'images/audi.png'} shadow="sm"/>
              </Grid>
              <Grid item xs="auto">
                <MDAvatar src={'images/bmw.png'} shadow="sm"/>
              </Grid>
              <Grid item xs="auto">
                <MDAvatar src={'images/dodge.png'} shadow="sm"/>
              </Grid>
            </>}
          </Grid>
        </MDBox>

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item lg={10} xs={10}>
                <MDBox color={light ? "white" : "inherit"}>
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item lg={3} xs={3}>
                      <IconButton
                          size="small"
                          disableRipple
                          color="inherit"
                          sx={navbarMobileMenu}
                          onClick={handleMiniSidenav}
                      >
                        <Icon sx={iconsStyle} fontSize="medium">
                          {miniSidenav ? "menu_open" : "menu"}
                        </Icon>
                      </IconButton>
                    </Grid>
                    {localStorage.getItem('rol_id') === '1' ?(
                        <Grid item lg={3} xs={3}>
                          <IconButton
                              sx={navbarIconButton}
                              size="small"
                              disableRipple
                              onClick={handleOpen}>
                            <Icon sx={iconsStyle}>account_circle</Icon>
                          </IconButton>
                        </Grid>
                    ):('')}

                    <Grid item lg={3} xs={3}>
                      <MDBadge
                          badgeContent={data?data.length:null}
                          color="error"
                          size="xs"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                      >
                        <IconButton
                            size="small"
                            disableRipple
                            color="inherit"
                            sx={navbarIconButton}
                            aria-controls="notification-menu"
                            aria-haspopup="true"
                            variant="contained"
                            onClick={handleOpenMenu}
                        >
                          <Icon sx={iconsStyle}>notifications</Icon>
                        </IconButton>
                      </MDBadge>
                      {renderMenu()}
                    </Grid>


                    <Grid item lg={3} xs={3}>
                      <IconButton
                          size="small"
                          disableRipple
                          color="inherit"
                          sx={navbarIconButton}
                          aria-controls="notification-menu"
                          aria-haspopup="true"
                          onClick={()=>{
                            setOpen2(true)
                            setCategory('Popup')
                            setSection('confirm')}}
                      >
                        <Icon sx={iconsStyle}>logout</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                  {renderMenu()}
                </MDBox>
              </Grid>
            </Grid>
            <Modal open={open} close={handleClose} title={'Usuarios'} setFormData={setFormData} formData={formData} cargarUsers={cargarUsers} users={users} setSeleccion={setSeleccion} seleccion={seleccion} setAdminCheck={setAdminCheck} adminCheck={adminCheck}/>
            <ModalPassword openPass={openPass} close={handleClosePass}  title={'Nueva Contraseña'} setFormDataPass={setFormDataPass} formDataPass={formDataPass}/>


            <ModalLogOut open={open2} close={handleClose2} section={section} category={category} data={null}
                   text={'¿Cerrar Sesion?'} execution={cerrarSesion}/>
            <ModalNotificacion open={open3} close={handleClose3} section={section3} category={category3} data={null}
                   text={text3} execution={execute3}/>

          </MDBox>
        )}

      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;