import { MyToastRequest} from '../../../helpers/MyToast'

export const getMarcas = async () => {

    const toastInfo = {
        endpoint: 'marcas/mostrarMarcas',
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const getSubMarcas = async (data) => {


    const toastInfo = {
        endpoint: 'submarcas/mostrarSubmarcas',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const getVehiculosBySubmarca = async (data) => {
    const toastInfo = {
        endpoint: 'vehiculos/mostrarVehiculoBySubmarca',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}


export const getMostrarVentasCotizaciones = async (data) => {



    const toastInfo = {
        endpoint: 'ventas/mostrarVentasCotizaciones',
        data: data,
        method: 'GET',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const agregarCotizacion = async (data) => {
    const toastInfo = {
        endpoint: 'cotizaciones/agregarCotizacion',
        data: data,
        method: 'Post',
        pendingMessage: 'Agregando Cotizacion',
        successMessage: 'Cotizacion Agregada!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const agregarVenta = async (data) => {
    const toastInfo = {
        endpoint: 'ventas/agregarVenta',
        data: data,
        method: 'Post',
        pendingMessage: 'Agregando Venta',
        successMessage: 'Venta Agregada!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const borrarCotizacion = async (data) => {

    const toastInfo = {
        endpoint: 'cotizaciones/borrarCotizacion',
        data: data,
        method: 'Post',
        pendingMessage: 'Eliminando Cotizacion',
        successMessage: 'Cotizacion Eliminada!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}


export const updateCotizacion  = async (data) => {

    const toastInfo = {
        endpoint: 'cotizaciones/updateCotizacion',
        data: data,
        method: 'Post',
        pendingMessage: 'Actualizando Cotizacion',
        successMessage: 'Cotizacion Actualizada!',
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}