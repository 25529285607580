import {MyToastRequest} from '../../../helpers/MyToast'

export const mostrarVentaxAuto = async () => {

    const toastInfo = {
        endpoint: 'ventas/mostrarVentaxAuto',
        method: 'GET',
    }
    const resp =await MyToastRequest(toastInfo)
    return resp.result;
}


export const registrarVenta = async (data, type) => {

    data.idVenta = type
    const toastInfo = {
        endpoint: 'ventaproducto/registrarVenta',
        data: data,
        method: 'POST',
        pendingMessage: data.idVenta===1?'Guardando Venta Fisica':'Guardando Venta Full',
        successMessage: data.idVenta===1?'Venta Fisica Guardada!':'Venta Full Guardada!',
    }

    await MyToastRequest(toastInfo)
}


export const editarProducto= async (data) => {
    const toastInfo = {
        endpoint: 'producto/editarProducto',
        data: data,
        method: 'POST',
        pendingMessage: 'Actualizando Producto',
        successMessage: 'Producto Actualizado!',
    }
    await MyToastRequest(toastInfo)
}


export const getMarcas = async () => {

    const toastInfo = {
        endpoint: 'marcas/mostrarMarcas',
        method: 'GET'
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const getSubMarcas = async (data) => {


    const toastInfo = {
        endpoint: 'submarcas/mostrarSubmarcas',
        data: data,
        method: 'GET'
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;

}

export const getVehiculosBySubmarca = async (data) => {
    const toastInfo = {
        endpoint: 'vehiculos/mostrarVehiculoBySubmarca',
        data: data,
        method: 'GET'
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const getPiezasVendidas = async (data) => {
    const toastInfo = {
        endpoint: 'ventas/piezasVendidas',
        data: data,
        method: 'GET'
    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const ventaxRango= async (data) => {
    const toastInfo = {
        endpoint: 'ventas/mostrarVentaRango',
        data: data,
        method: 'GET',

    }

    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}

export const ventaxRangoImportacion= async (data) => {
    const toastInfo = {
        endpoint: 'ventas/mostrarVentaRangoImportacion',
        data: data,
        method: 'GET',

    }
    const resp = await MyToastRequest(toastInfo)
    return resp.result;
}
