import React, {useEffect, useState} from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDCard from "../../components/MDCard";
import MDButton from "../../components/MDButton";
import DataTable from "../../examples/Tables/DataTable";
import MDInput from "../../components/MDInput";
import MenuItem from "@mui/material/MenuItem";
import {
    getMarcas,
    getSubMarcas,
    getVehiculosBySubmarca,
    getPiezasVendidas, ventaxRango, ventaxRangoImportacion
} from "./actions/Request";
import Card from "@mui/material/Card";
import MDAvatar from "../../components/MDAvatar";


function MostrarVentas() {
    function getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const [load, setLoad] = useState(false)
    const [subMarcas, setSubMarcas] = useState(null)
    const [mostrarVentaFYonke, setMostrarVentaFYonke] = useState(false)
    const [mostrarVentaRYonke, setMostrarVentaRYonke] = useState(false)
    const [subMarca, setSubMarca] = useState(0)
    const [marcas, setMarcas] = useState(null)
    const [marca, setMarca] = useState(0)
    const [vehiculos, setVehiculos] = useState(null)
    const [vehiculo, setVehiculo] = useState(0)
    const [tipoventa, setTipoventa] = useState('mostrarVentaxFecha')
    const [filtro, setFiltro] = useState(null)
    const [ventasAuto, setVentasAuto] = useState(null)
    const [ventaRango, setVentaRango] = useState(null)
    const [ventaRangof, setVentaRangof] = useState(null)
    const [ventaRangoImportacion, setVentaRangoImportacion] = useState(null)
    const [ventaRangoImportacionf, setVentaRangoImportacionf] = useState(null)
    const [gananciaAuto, setGananciaAuto] = useState(null)
    const [costoAuto, setCostoAuto] = useState(null)
    const [fecha, setFecha] = useState('0001-01-01')
    const [diasTrans, setDiasTrans] = useState(null)
    const [totalVY, setTotalVY] = useState('')
    const [totalVYf, setTotalVYf] = useState('')
    const [totalVYI, setTotalVYI] = useState('')
    const [totalVYIf, setTotalVYIf] = useState('')
    const [totalFinal, setTotalFinal] = useState('')
    const [totalFinalf, setTotalFinalf] = useState('')
    const [formData, setFormData] = useState({
        fechainicio: getCurrentDate(),
        fechafin: getCurrentDate(),
        fechaf: getCurrentDate(),
    });


    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }
    const cargarSubMarcas = async (data) => {
        const res = await getSubMarcas(data);
        if (res) {
            setSubMarcas(res);
        }
    }
    const cargarVehiculos = async (data) => {
        const res = await getVehiculosBySubmarca(data);
        if (res) {
            setVehiculos(res);
        }
    }


    const handleChangeMarca = (e) => {
        cargarSubMarcas({idMarca: e.target.dataset.value})
        setMarca(e.target.dataset.value)
        setVehiculos(null)
        setFiltro(null)
        setVentasAuto('')
        setGananciaAuto('')
        setCostoAuto('')
        setFecha('')
        setDiasTrans('')
    }


    const handleChangeSubMarca = (e) => {
        cargarVehiculos({idSubMarca: e.target.dataset.value})
        setSubMarca(e.target.dataset.value)
    }

    const handleChangeFiltrar = (e) => {
        setLoad(true)
        getData({idVeh: e.target.dataset.value})
        setVehiculo(e.target.dataset.value)

    }

    const handleChangeFecha = (e) => {
        const ob = {...formData}
        ob[e.target.id] = e.target.value
        setFormData(ob);
    }


    const handleChangeFechaf = (e) => {
        setLoad(true)
        const ob = {...formData}
        ob[e.target.id] = e.target.value
        setFormData(ob);
        getData(e.target.value)
    }


    const handleFiltrarVentasRango = () => {
        setLoad(true)
        getData()
    }

    const handleChangeMostrarVentaFYonke = () => {
        setMostrarVentaFYonke(!mostrarVentaFYonke)
    }
    const handleChangeMostrarVentaRYonke = () => {
        setMostrarVentaRYonke(!mostrarVentaRYonke)
    }


    const getData = async (data) => {
        if (tipoventa === 'mostrarVentaxAuto') {
            const res = await getPiezasVendidas(data);

            let costo = vehiculos.find(record => record.idVeh === parseInt(data.idVeh)).costo
            let fechav = vehiculos.find(record => record.idVeh === parseInt(data.idVeh)).fecha
            if (res) {
                let suma = res.reduce((accumulator, currentValue) => {
                    return accumulator + parseFloat(currentValue.precio);
                }, 0).toFixed(2)
                setFiltro(res);
                setVentasAuto(suma)
                setGananciaAuto((suma - parseFloat(costo)).toFixed(2))
                setCostoAuto(costo)
                setFecha(fechav)
                let date1 = new Date(getCurrentDate())
                let date2 = new Date(fechav)
                let difference = ((((date1 - date2) / 1000) / 60) / 60) / 24
                setDiasTrans(difference)
            }


        }
        if (tipoventa === 'mostrarVentaxRango') {
            const res = await ventaxRango(formData);
            const res2 = await ventaxRangoImportacion(formData);
            let tvy = 0.00
            let tvyi = 0.00
            if (res) {
                tvy = res.reduce((accumulator, currentValue) => {
                    return accumulator + parseFloat(currentValue.Precio) * parseFloat(currentValue.cantidad);
                }, 0).toFixed(2)

                setVentaRango(res);
                setTotalVY(tvy)
            }
            if (res2) {
                setVentaRangoImportacion(res2);
                tvyi = res2.reduce((accumulator, currentValue) => {
                    return accumulator + parseFloat(currentValue.precio) * parseFloat(currentValue.cantidad);
                }, 0).toFixed(2)
                setTotalVYI(tvyi)
            }
            setTotalFinal((parseFloat(tvy) + parseFloat(tvyi)).toFixed(2))

        }


        if (tipoventa === 'mostrarVentaxFecha') {


            const res = await ventaxRango({fechainicio: data});
            const res2 = await ventaxRangoImportacion({fechainicio: data});
            let tvy = 0.00
            let tvyi = 0.00
            if (res) {
                tvy = res.reduce((accumulator, currentValue) => {
                    return accumulator + parseFloat(currentValue.Precio) * parseFloat(currentValue.cantidad);
                }, 0).toFixed(2)

                setVentaRangof(res);
                setTotalVYf(tvy)
            }
            if (res2) {
                setVentaRangoImportacionf(res2);
                tvyi = res2.reduce((accumulator, currentValue) => {
                    return accumulator + parseFloat(currentValue.precio) * parseFloat(currentValue.cantidad);
                }, 0).toFixed(2)
                setTotalVYIf(tvyi)
            }
            setTotalFinalf((parseFloat(tvy) + parseFloat(tvyi)).toFixed(2))


        }
        setLoad(false)

    }

    useEffect(() => {
        if (subMarcas === null) {
            cargarMarcas()
        }
        if (ventaRangoImportacionf === null||ventaRangof === null) {
            getData(formData.fechaf)
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeTipoventa = (e) => {
        switch (e.target.value) {
            case 'mostrarVentaxAuto':
                setTipoventa(e.target.value)
                break;
            case 'mostrarVentaxRango':
                setTipoventa(e.target.value)
                setMostrarVentaRYonke(false)
                break;
            case 'mostrarVentaxFecha':
                setTipoventa(e.target.value)
                setMostrarVentaFYonke(false)
                break;

            default:
                break
        }
    }




    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xl={12} xs={12}>
                                <MDCard
                                    title={tipoventa === 'mostrarVentaxAuto' ? 'Ventas por Auto' : tipoventa === 'mostrarVentaxRango' ? 'Ventas por Rango' : tipoventa === 'mostrarVentaxFecha' ? 'Ventas por Fecha' : 'Selecciona tipo de Venta'}>
                                    <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xl={12} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center"
                                                  alignItems="center">
                                                <Grid item lg={4} xs={12}>
                                                    <MDButton variant="gradient" color="info"
                                                              startIcon={<Icon fontSize="small">search</Icon>}
                                                              value={'mostrarVentaxFecha'}
                                                              fullWidth
                                                              onClick={handleChangeTipoventa}
                                                    >
                                                        Ventas por Fecha
                                                    </MDButton>
                                                </Grid>
                                                <Grid item lg={4} xs={12}>
                                                    <MDButton variant="gradient" color="info"
                                                              startIcon={<Icon fontSize="small">search</Icon>}
                                                              value={'mostrarVentaxRango'}
                                                              fullWidth
                                                              onClick={handleChangeTipoventa}
                                                    >
                                                        Ventas por Rango
                                                    </MDButton>
                                                </Grid>
                                                <Grid item lg={4} xs={12}>
                                                    <MDButton variant="gradient" color="info"
                                                              startIcon={<Icon fontSize="small">search</Icon>}
                                                              value={'mostrarVentaxAuto'}
                                                              fullWidth
                                                              onClick={handleChangeTipoventa}
                                                    >
                                                        Ventas por Auto
                                                    </MDButton>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </MDCard>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">

                            <Grid item xl={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        {!load ? (<></>) : (<Grid item xs="auto"><MDAvatar src={'images/loading.gif'}
                                                                                           shadow="sm"/></Grid>)}
                                        {tipoventa === 'mostrarVentaxAuto' ?
                                            (
                                                <>

                                                    <Grid item xl={4} xs={12}>
                                                        <MDInput
                                                            size="large"
                                                            select
                                                            labelid="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={marca}
                                                            label="Marca"
                                                            InputProps={{
                                                                classes: {root: "select-input-styles"},
                                                            }}
                                                            fullWidth
                                                        >
                                                            <MenuItem value={'0'} onClick={handleChangeMarca}>Seleccion...</MenuItem>
                                                            {marcas ? (marcas.map((itm, index) =>
                                                                <MenuItem value={itm.idMarcas}
                                                                          key = {index + 'marcas'}
                                                                          onClick={handleChangeMarca}>{itm.marcas}</MenuItem>
                                                            )) : (<MenuItem></MenuItem>)}
                                                        </MDInput>
                                                    </Grid>
                                                    <Grid item xl={4} xs={12}>
                                                        <MDInput
                                                            id="demo-simple-select"
                                                            value={subMarca}
                                                            label="SubMarca"
                                                            InputProps={{
                                                                classes: {root: "select-input-styles"},
                                                            }}
                                                            select

                                                            fullWidth
                                                        >
                                                            <MenuItem value={'0'} onClick={handleChangeSubMarca}>Seleccion...</MenuItem>
                                                            {subMarcas ? (subMarcas.map((itm, index) =>
                                                                <MenuItem value={itm.idSubMarca}
                                                                          key = {index + 'submarca'}
                                                                          onClick={handleChangeSubMarca}>{itm.SubMarca}</MenuItem>
                                                            )) : (<MenuItem></MenuItem>)}
                                                        </MDInput>
                                                    </Grid>
                                                    <Grid item xl={4} xs={12}>
                                                        <MDInput
                                                            id="demo-simple-select"
                                                            value={vehiculo}
                                                            label="Vehiculo"
                                                            InputProps={{
                                                                classes: {root: "select-input-styles"},
                                                            }}
                                                            select

                                                            fullWidth
                                                        >
                                                            <MenuItem value={'0'} onClick={handleChangeFiltrar}>Seleccion...</MenuItem>
                                                            {vehiculos ? (vehiculos.map((itm, index) =>
                                                                <MenuItem value={itm.idVeh}
                                                                          key = {index + 'vehiculo'}
                                                                          onClick={handleChangeFiltrar}>{itm.Linea + ' | ' + itm.Modelo + ' | ' + itm.color}</MenuItem>
                                                            )) : (<MenuItem></MenuItem>)}
                                                        </MDInput>
                                                    </Grid>
                                                    <Grid item xl={12} xs={12}>
                                                        <DataTable
                                                            table={{
                                                                columns: [
                                                                    {Header: "Precio", accessor: "pieza", width: "10%"},
                                                                    {Header: "Pieza", accessor: "precio", width: "30%"},
                                                                    {Header: "Marcas", accessor: "fecha", width: "30%"},
                                                                    {Header: "Creado Por", accessor: "creadoPor", width: "30%"}],
                                                                rows: filtro ? filtro : []
                                                            }}
                                                            canSearch={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xl={2} xs={6}>
                                                        <MDInput label="Ventas" fullWidth
                                                                 value={ventasAuto ? ventasAuto : ''} disabled={true}/>
                                                    </Grid>
                                                    <Grid item xl={2} xs={6}>
                                                        <MDInput label="Ganancia" fullWidth
                                                                 value={gananciaAuto ? gananciaAuto : ''} disabled={true}/>
                                                    </Grid>
                                                    <Grid item xl={3} xs={6}>
                                                        <MDInput label="Costo del Auto" fullWidth
                                                                 value={costoAuto ? costoAuto : ''} disabled={true}/>
                                                    </Grid>
                                                    <Grid item xl={3} xs={6}>
                                                        <MDInput type="date" label="Fecha de Compra" fullWidth
                                                                 value={fecha ? fecha : ''} disabled={true}/>
                                                    </Grid>
                                                    <Grid item xl={2} xs={6}>
                                                        <MDInput label="Dias Transcurridos" fullWidth
                                                                 value={diasTrans ? diasTrans : ''} disabled={true}/>
                                                    </Grid>

                                                </>

                                            ) : (tipoventa === 'mostrarVentaxRango' ?
                                                (
                                                    <>
                                                        <Grid item lg={12} xs={12}>
                                                            <Grid container padding={2} spacing={2}
                                                                  justifyContent="center"
                                                                  alignItems="center">
                                                                <Grid item lg={12} xs={12}>
                                                                    <Grid container spacing={2}
                                                                          justifyContent="center"
                                                                          alignItems="center">
                                                                        <Grid item lg={2} xs={12}>
                                                                            <MDInput type="date" label="De"
                                                                                     id="fechainicio"
                                                                                     fullWidth
                                                                                     value={formData.fechainicio}
                                                                                     onChange={handleChangeFecha}/>
                                                                        </Grid>
                                                                        <Grid item lg={2} xs={12}>
                                                                            <MDInput type="date"
                                                                                     label="Hasta"
                                                                                     id="fechafin" fullWidth
                                                                                     value={formData.fechafin}
                                                                                     onChange={handleChangeFecha}/>
                                                                        </Grid>
                                                                        <Grid item lg={2} xs={12}>
                                                                            <MDButton variant="gradient"
                                                                                      color="info"
                                                                                      startIcon={<Icon
                                                                                          fontSize="small">search</Icon>}
                                                                                      fullWidth
                                                                                      onClick={handleFiltrarVentasRango}>
                                                                                Filtrar
                                                                            </MDButton>
                                                                        </Grid>
                                                                        <Grid item lg={3} xs={12}>
                                                                            <MDButton variant="gradient"
                                                                                      color="info"
                                                                                      startIcon={<Icon
                                                                                          fontSize="small">search</Icon>}
                                                                                      fullWidth
                                                                                      onClick={handleChangeMostrarVentaRYonke}
                                                                            >
                                                                                {mostrarVentaRYonke? 'Ventas Importación':'Ventas Yonke'}
                                                                            </MDButton>
                                                                        </Grid>
                                                                        <Grid item lg={3} xs={12}>
                                                                            <MDInput label="Total"
                                                                                     value={totalFinal}
                                                                                     disabled={true}
                                                                                     fullWidth/>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {mostrarVentaRYonke ?
                                                            <Grid item lg={12} xs={12}>
                                                                <Grid container padding={2} spacing={2}
                                                                      justifyContent="center"
                                                                      alignItems="center">
                                                                    <Grid item lg={12} xs={12}>
                                                                        <DataTable
                                                                            table={{

                                                                                columns: [
                                                                                    {Header: "Pieza", accessor: "Pieza", width: "10%"},
                                                                                    {Header: "Precio", accessor: "Precio", width: "10%"},
                                                                                    {Header: "Marcas", accessor: "marcas", width: "10%"},
                                                                                    {Header: "Linea", accessor: "linea", width: "10%"},
                                                                                    {Header: "Color", accessor: "color", width: "10%"},
                                                                                    {Header: "Modelo", accessor: "modelo", width: "10%"},
                                                                                    {Header: "Fecha", accessor: "fecha", width: "10%"},
                                                                                    {Header: "Hora", accessor: "hora", width: "10%"},
                                                                                    {Header: "Creado Por", accessor: "creadoPor", width: "10%"}
                                                                                ],
                                                                                rows: ventaRango ? ventaRango : [],
                                                                            }}
                                                                            canSearch={true}
                                                                            entriesPerPage={{ defaultValue: 50, entries: [5, 10, 20, 30, 40, 50] }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={12} xs={12}>
                                                                        <MDInput label="Total de Ventas Yonke"
                                                                                 value={totalVY}
                                                                                 disabled={true}
                                                                                 fullWidth/>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid> :
                                                            <Grid item lg={12} xs={12}>
                                                                <Grid container padding={2} spacing={2}
                                                                      justifyContent="center"
                                                                      alignItems="center">
                                                                    <Grid item lg={12} xs={12}>
                                                                        <Grid container spacing={2}
                                                                              justifyContent="center"
                                                                              alignItems="center">
                                                                            <Grid item lg={12} xs={12}>
                                                                                <DataTable
                                                                                    //--------------
                                                                                    table={{
                                                                                        columns: [
                                                                                            {Header: "idProducto", accessor: "idProducto", width: "10%"},
                                                                                            {Header: "Producto", accessor: "Producto", width: "10%"},
                                                                                            {Header: "Cantidad", accessor: "cantidad", width: "10%"},
                                                                                            {Header: "Precio", accessor: "precio", width: "10%"},
                                                                                            {Header: "Total", accessor: "Total", width: "10%"},
                                                                                            {Header: "Fecha", accessor: "fecha", width: "10%"},
                                                                                            {Header: "Hora", accessor: "hora", width: "10%"},
                                                                                            {Header: "Tipo Venta", accessor: "Tipo_Venta", width: "10%"},
                                                                                            {Header: "Creado Por", accessor: "creadoPor", width: "10%"},
                                                                                            {Header: "ID Venta ML", accessor: "idVentaML", width: "10%"}
                                                                                        ],
                                                                                        rows: ventaRangoImportacion ? ventaRangoImportacion : []
                                                                                    }}
                                                                                    canSearch={true}
                                                                                    entriesPerPage={{ defaultValue: 50, entries: [5, 10, 20, 30, 40, 50] }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item lg={12} xs={12}>
                                                                                <MDInput
                                                                                    label="Total de Ventas Importacion"
                                                                                    value={totalVYI}
                                                                                    disabled={true}
                                                                                    fullWidth/>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }



                                                    </>


                                                ) : (tipoventa === 'mostrarVentaxFecha' ?
                                                    (
                                                        <>
                                                            <Grid item lg={12} xs={12}>
                                                                <Grid container padding={2} spacing={2}
                                                                      justifyContent="center"
                                                                      alignItems="center">
                                                                    <Grid item lg={12} xs={12}>
                                                                        <Grid container spacing={2}
                                                                              justifyContent="center"
                                                                              alignItems="center">
                                                                            <Grid item lg={4} xs={12}>
                                                                                <MDInput type="date"
                                                                                         label="Fecha"
                                                                                         id="fechaf"
                                                                                         onChange={handleChangeFechaf}
                                                                                         value={formData.fechaf}
                                                                                         fullWidth/>
                                                                            </Grid>
                                                                            <Grid item lg={4} xs={12}>
                                                                                <MDButton variant="gradient"
                                                                                          color="info"
                                                                                          startIcon={<Icon
                                                                                              fontSize="small">search</Icon>}
                                                                                          fullWidth
                                                                                    onClick={handleChangeMostrarVentaFYonke}
                                                                                >
                                                                                    {mostrarVentaFYonke? 'Ventas Importación':'Ventas Yonke'}
                                                                                </MDButton>
                                                                            </Grid>
                                                                            <Grid item lg={4} xs={12}>
                                                                                <MDInput label="Total"
                                                                                         value={totalFinalf}
                                                                                         disabled={true}
                                                                                         fullWidth/>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {mostrarVentaFYonke ?
                                                                <Grid item lg={12} xs={12}>
                                                                    <Grid container padding={2} spacing={2}
                                                                          justifyContent="center"
                                                                          alignItems="center">
                                                                        <Grid item lg={12} xs={12}>
                                                                            <DataTable
                                                                                table={{
                                                                                    columns: [
                                                                                        {Header: "Pieza", accessor: "Pieza", width: "20%"},
                                                                                        {Header: "Precio", accessor: "Precio", width: "10%"},
                                                                                        {Header: "Marcas", accessor: "marcas", width: "20%"},
                                                                                        {Header: "Linea", accessor: "linea", width: "10%"},
                                                                                        {Header: "Color", accessor: "color", width: "10%"},
                                                                                        {Header: "Modelo", accessor: "modelo", width: "10%"},
                                                                                        {Header: "Hora", accessor: "hora", width: "10%"},
                                                                                        {Header: "Creado Por", accessor: "creadoPor", width: "10%"}
                                                                                    ],
                                                                                    rows: ventaRangof ? ventaRangof : []
                                                                                }}
                                                                                canSearch={true}
                                                                                entriesPerPage={{ defaultValue: 50, entries: [5, 10, 20, 30, 40, 50] }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item lg={12} xs={12}>
                                                                            <MDInput label="Total de Ventas Yonke"
                                                                                     value={totalVYf}
                                                                                     disabled={true}
                                                                                     fullWidth/>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid> :
                                                                <Grid item lg={12} xs={12}>
                                                                    <Grid container padding={2} spacing={2}
                                                                          justifyContent="center"
                                                                          alignItems="center">
                                                                        <Grid item lg={12} xs={12}>
                                                                            <Grid container spacing={2}
                                                                                  justifyContent="center"
                                                                                  alignItems="center">
                                                                                <Grid item lg={12} xs={12}>
                                                                                    <DataTable
                                                                                        table={{
                                                                                            columns: [
                                                                                                {Header: "idProducto", accessor: "idProducto", width: "10%"},
                                                                                                {Header: "Producto", accessor: "Producto", width: "20%"},
                                                                                                {Header: "Cantidad", accessor: "cantidad", width: "10%"},
                                                                                                {Header: "Precio", accessor: "precio", width: "10%"},
                                                                                                {Header: "Total", accessor: "Total", width: "10%"},
                                                                                                {Header: "Hora", accessor: "hora", width: "10%"},
                                                                                                {Header: "Tipo Venta", accessor: "Tipo_Venta", width: "10%"},
                                                                                                {Header: "Creado Por", accessor: "creadoPor", width: "10%"},
                                                                                                {Header: "ID Venta ML", accessor: "idVentaML", width: "10%"}
                                                                                            ],
                                                                                            rows: ventaRangoImportacionf ? ventaRangoImportacionf : []
                                                                                        }}
                                                                                        canSearch={true}
                                                                                        entriesPerPage={{ defaultValue: 50, entries: [5, 10, 20, 30, 40, 50] }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item lg={12} xs={12}>
                                                                                    <MDInput
                                                                                        label="Total de Ventas Importacion"
                                                                                        value={totalVYIf}
                                                                                        disabled={true}
                                                                                        fullWidth/>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </>

                                                    ) : ('')))}
                                    </Grid>
                                </Card>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default MostrarVentas;