import React, {useEffect, useState} from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import MenuItem from "@mui/material/MenuItem";
import DataTable from "../../examples/Tables/DataTable";
import MDCard from "../../components/MDCard";
import Icon from "@mui/material/Icon";
import MDButton from "../../components/MDButton";
import {
    agregarCotizacion,
    getMarcas,
    getSubMarcas,
    getVehiculosBySubmarca,
    mostrarCotizaciones
} from "./Actions/Reques";
import {MyToastError} from "../../helpers/MyToast";
import MDAvatar from "../../components/MDAvatar";
import Modal from "./components/Modal_Confirm";



function Cotizaciones() {






    const [open, setOpen] = useState(false);
    const [section, setSection] = useState('')
    const [category, setCategory] = useState('')
    const [text, setText]=useState('')
    const handleClose = () => {
        setSection('')
        setCategory('')
        setOpen(false)

    };
    const onClickPop= () => {

        if (formData.idVeh === null) {
            MyToastError('Ingrese Vehiculo')
            return
        }
        if (formData.Pieza === '') {
            MyToastError('Ingrese Pieza')
            return
        }
        if (formData.Precio === '' || formData.Precio === '0') {
            MyToastError('Ingrese Precio')
            return
        }

        setOpen(true)
        setCategory('Popup')
        setSection('confirm')
        setText(`ESTAS A PUNTO DE <b>AGREGAR</b> UNA COTIZACION<br>
                                            <b>PIEZA:</b>  ${formData.Pieza}<br>
                                             <b>PRECIO:</b>  $${formData.Precio}<br>                                                   
`)

    }










    const [subMarcas, setSubMarcas] = useState(null)
    const [subMarca, setSubMarca] = useState(0)
    const [marca, setMarca] = useState(0)
    const [vehiculo, setVehiculo] = useState(0)
    const [load, setLoad] = useState(false)
    const [marcas, setMarcas] = useState(null)
    const [vehiculos, setVehiculos] = useState(null)
    const [cotizaciones, setCotizaciones] = useState(null)
    const [formData, setFormData] = useState({
        idVeh: null,
        Pieza: '',
        Precio: ''
    });


    const cargarMarcas = async () => {
        const res = await getMarcas();
        if (res) {
            setMarcas(res);
        }
    }

    const cargarSubMarcas = async (data) => {
        const res = await getSubMarcas(data);
        if (res) {
            setSubMarcas(res);
        }
    }
    const onChangeMarca = (e) => {
        cargarSubMarcas({idMarca: e.target.dataset.value})
        setMarca(e.target.dataset.value)

        const ob = {...formData}
        ob['idVeh'] = null
        setFormData(ob);
        setSubMarcas(null)
        setVehiculos(null)
    }
    const cargarVehiculos = async (data) => {
        const res = await getVehiculosBySubmarca(data);
        if (res) {
            setVehiculos(res);
        }
    }


    const onChangeSubMarca = (e) => {
        setSubMarca(e.target.dataset.value)
        cargarVehiculos({idSubMarca: e.target.dataset.value})

        const ob = {...formData}
        ob['idVeh'] = null
        setFormData(ob);
    }


    const cargarCotizaciones = async (data) => {
        const res = await mostrarCotizaciones(data);
        if (res) {
            setCotizaciones(res);
        }
        setLoad(false)
    }

    const onChangeVeh = (e) => {
        setVehiculo(e.target.dataset.value)
        setLoad(true)
        cargarCotizaciones({idVeh: e.target.dataset.value})
        const ob = {...formData}
        ob['idVeh'] = e.target.dataset.value
        setFormData(ob);

    }

    const onChangePieza = (e) => {
        const ob = {...formData}
        ob['Pieza'] = e.target.value
        setFormData(ob);

    }

    const onChangePrecio = (e) => {
        const ob = {...formData}
        ob['Precio'] = e.target.value
        setFormData(ob);

    }


    const agregarCotizacionf = async () => {

        await agregarCotizacion(formData).then(() => {
            cargarCotizaciones({idVeh: formData.idVeh})
            const ob = {...formData}
            ob['Precio'] = ''
            ob['Pieza'] = ''
            setFormData(ob);
        })

    }
    const onClickSubmit = () => {

        agregarCotizacionf()
    }


    useEffect(() => {

        if (marcas === null) {
            cargarMarcas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={3}>
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xl={12} xs={12}>
                                <Card>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item xl={2} xs={12}>
                                            <MDInput
                                                id="demo-simple-select"
                                                label="Marca"
                                                InputProps={{
                                                    classes: {root: "select-input-styles"},
                                                }}
                                                select
                                                value={marca}

                                                fullWidth
                                            >
                                                <MenuItem value={'0'} onClick={onChangeMarca}>Seleccion...</MenuItem>
                                                {
                                                    marcas ? (marcas.map((itm, index) =>
                                                            <MenuItem value={itm.idMarcas}
                                                                      id={index+'marcas'}
                                                                      key={index+'marcas'}
                                                                      onClick={onChangeMarca}>{itm.marcas}</MenuItem>))
                                                        :
                                                        (<MenuItem></MenuItem>)
                                                }

                                            </MDInput>
                                        </Grid>
                                        <Grid item xl={2} xs={12}>
                                            <MDInput
                                                id="demo-simple-select"
                                                // value={subMarca}
                                                label="SubMarca"
                                                InputProps={{
                                                    classes: {root: "select-input-styles"},
                                                }}
                                                select
                                                value={subMarca}
                                                fullWidth
                                            >
                                                <MenuItem value={'0'} onClick={onChangeSubMarca}>Seleccion...</MenuItem>
                                                {
                                                    subMarcas ? (subMarcas.map((itm, index) =>
                                                            <MenuItem value={itm.idSubMarca}
                                                                      id={index+'submarcas'}
                                                                      key={index+'submarcas'}
                                                                      onClick={onChangeSubMarca}>{itm.SubMarca}</MenuItem>))
                                                        :
                                                        (<MenuItem></MenuItem>)
                                                }
                                            </MDInput>
                                        </Grid>

                                        <Grid item lg={2} xs={12}>
                                            <MDInput
                                                id="demo-simple-select"
                                                label="Auto"
                                                InputProps={{
                                                    classes: {root: "select-input-styles"},
                                                }}
                                                select
                                                value={vehiculo}
                                                fullWidth
                                            >
                                                <MenuItem value={'0'} onClick={onChangeVeh}>Seleccion...</MenuItem>
                                                {
                                                    vehiculos ? (vehiculos.map((itm, index) =>
                                                            <MenuItem value={itm.idVeh}
                                                                      id={index+'veh'}
                                                                      key={index+'veh'}
                                                                      onClick={onChangeVeh}>{itm.Linea + ' | ' + itm.Modelo + ' | ' + itm.color}</MenuItem>))
                                                        :
                                                        (<MenuItem></MenuItem>)
                                                }
                                            </MDInput>
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            <MDInput type="text" label="Pieza..." fullWidth
                                                     value={formData.Pieza}
                                                     onChange={onChangePieza}
                                            />
                                        </Grid>

                                        <Grid item lg={1} xs={12}>
                                            <MDInput type="number" label="Precio..." fullWidth
                                                     value={formData.Precio}
                                                     onChange={onChangePrecio}
                                            />
                                        </Grid>

                                        <Grid item lg={1} xs={12}>
                                            <MDButton variant="gradient" color="success"
                                                      startIcon={<Icon fontSize="small">add</Icon>}
                                                      onClick={onClickPop}
                                                      fullWidth>
                                                Agregar
                                            </MDButton>
                                        </Grid>


                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xl={12} xs={12}>
                                <MDCard title={'Cotizaciones por Auto'}>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item lg={12} xs={12}>
                                            <Grid container padding={2} spacing={2} justifyContent="center">
                                                {!load?(<></>):(<Grid item xs="auto"><MDAvatar src={'images/loading.gif'} shadow="sm"/></Grid>)}
                                                <Grid item lg={12} xs={12}>
                                                    <DataTable
                                                        table={{
                                                            columns: [
                                                                {Header: "Pieza", accessor: "Pieza" , width:"50%"},
                                                                {Header: "Costo", accessor: "Costo", width:"15%"},
                                                                {Header: "Costo MercadoLibre", accessor: "Costo_MercadoLibre", width:"15%"},
                                                                {Header: "Fecha", accessor: "Fecha", width:"20%"},

                                                            ],
                                                            rows: cotizaciones ? cotizaciones : [],
                                                        }}
                                                        canSearch={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </MDCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal open={open} close={handleClose} section={section} category={category} data={null}
                       text={text} execution={onClickSubmit}/>
            </MDBox>
        </DashboardLayout>);
}

export default Cotizaciones;
